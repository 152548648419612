import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Subjects";

class SubjectsRepository {
  constructor(context) {
    this.context = context;
  }

  async getDetailsOfASubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getDetailsOfASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subject;
  }

  async getSubjectDetails(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectDetails,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.subject
  }

  async updateMultipleSubjectDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.updateMultipleSubjectDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubjects(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjects,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }

  async getSubjectsOfACourseYear(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfACourseYear,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.subjects;
  }
}

export default SubjectsRepository;
