<template src="./activityTestHome.html"></template>

<script>
import auth, { sendQrDataToFireBase, sendEventQrDataToFireBase } from '../../Services/Firebase/auth';
import state from "../../Store/Modules/liveData";
import state2 from "../../Store/Modules/instituteData";
import NotificationRepository from "../../Repository/Notification";
import UserRepository from '../../Repository/User'
import PortalFooter from "../../Components/PortalFooter";
import GetInstitutesAndSemestersRepository from "../../Repository/GetInstitutesAndSemesters";
import firebase from "firebase";
// import { visitedRouteName } from '../../Router/index'
// qr code scanner
import { QrcodeStream } from 'vue-qrcode-reader'
// import SocketioService from '../../Services/Socket/socket.service';

export default {
  name: "activityTestHome",
  components: {
    PortalFooter,
    QrcodeStream
  },
  data() {
    return {
      isCorporate: false,
      isEdcInstitute: false,
      qrResultByLiveData: {},
      studentAccess: [],
      allowedAccessBySuperAdmin: [],
      sppuInstituteIdFlag: false,
      routeNameToShowIcon: ['activityChangeSemester', 'activityMySubjects', 'activityMyTimetable'], //this array is hard coded for displaying guide icon if need to show icon on route add route name in this array
      routeName: '',
      // visitedRoutes: [],
      show: false,
      state: state,
      state2: state2,
      logoutDialog: false,
      userData: null,
      selectedInstitute: "",
      selectedSemester: {},
      notifications: [],
      brandingName: "",
      brandingLogo: "",
      isFormModalOpen: false,
      formLink: "",
      showQrCodeScanner: false,
      qrCodeScannerError: "",
      careerfirstChanges: "",
      showQrAttendanceResult: false,
      attendanceResult: false,
      camera: 'auto',
      fingerprint: ''
    };
  },
  created() {
    this.notificationRepositoryInstance = new NotificationRepository(this);
    this.getInstitutesAndSemestersRepositoryInstance = new GetInstitutesAndSemestersRepository(this);
    this.userRepositoryInstance = new UserRepository(this)
    this.userData = this.$store.getters["user/get_userData"];
    // try {
    //   if (this.userData.profilePicture?.url === undefined) {
    //     this.userData.profilePicture?.url = "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1686552006854________istockphoto-1300845620-612x612.jpg?alt=media&token=02a01c50-ef68-4897-90dd-fab1c13bf294"
    //   }
    // } catch (error) {
    //   console.error('error', error);
    // }
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }
    this.isCorporate = this.$store.getters["liveData/get_isCorporateInstitute"];
    // this.isCorporate = true
    const data = this.$store.getters["instituteData"];
    console.log('data', data);
    this.studentAccess = this.$store.getters["instituteData/get_studentAccess"];
    if (this.studentAccess.studentAccess && this.studentAccess.studentAccess.length > 0) {
      this.studentAccess.studentAccess.splice(0, 1)
    }
   console.log(' this.studentAccess.studentAccess', this.studentAccess.studentAccess);
    const iconNames = ['mdi-animation-outline', 'mdi-book-open-variant', 'mdi-calendar-text', 'mdi-playlist-check', 'mdi-message-question-outline', 'mdi-comment-quote-outline']
    this.studentAccess.studentAccess.map((data, i) => {
      data.iconName = iconNames[i]
    })

    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];

    const instituteData = this.$store.getters["instituteData/selectedInstitute"];
    this.brandingName = instituteData.name
    this.brandingLogo = instituteData.logo
    if (this.selectedInstitute === 'aeb2dd8e-bb6f-436a-945e-fb1c39e1b6c3') {
      this.sppuInstituteIdFlag = true
    }
    if (process.env.VUE_APP_WHITELABEL_NAME) {
      this.careerfirstChanges = process.env.VUE_APP_WHITELABEL_NAME
    }
    this.getMachineId()
  },
  beforeUnmount() {
    this.socketioServiceInstance.disconnect();
  },
  // updated() {
  //  this.checkIsTermAccepted = this.$store.getters["liveData/get_isTermsAccepted"];
  //   },
  methods: {
    getMachineId() {
      // Check if the machine ID is already stored in localStorage
      this.fingerprint = localStorage.getItem('machineId');

      if (!this.fingerprint) {
        // If machine ID doesn't exist, generate a pseudo-unique ID
        this.fingerprint = Math.random().toString(36).substr(2, 10);
        // Store the generated machine ID in localStorage
        localStorage.setItem('machineId', this.fingerprint);
      }

    },
    activity() {
      this.routeName = this.$route.name
      this.$tours[`${this.routeName}`].start()
    },
    async fetchData() {
      // Call API to get todays notifications
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      };
      const userObj = {
        uId: this.userData.uId
      };
      try {
        this.notifications = await this.notificationRepositoryInstance.getTodaysNotifications(
          objToPush
        );
        this.institutes = await this.getInstitutesAndSemestersRepositoryInstance.getInstitutes(
          userObj
        );
      } catch (err) {
        this.notifications = [];
        console.error(err);
      }
    },
    gotoMyProfile() {
      this.$store.commit("liveData/set_selectedActivity");
      this.$router.push("/activityProfile");
    },
    gotoTimeline(i) {
      this.$store.commit("liveData/set_selectedActivity", i);
      this.$router.push("/activityTimeline");
    },
    gotoMySubjects(i) {
      this.$store.commit("liveData/set_selectedActivity", i);
      this.$router.push("/activityMySubjects");
    },
    gotoMyTimetable(i) {
      this.$store.commit("liveData/set_selectedActivity", i);
      this.$router.push("/activityMyTimetable");
    },
    gotoAttendanceAnalysis(i) {
      this.$store.commit("liveData/set_selectedActivity", i);
      this.$router.push("/activityAttendanceAnalysis");
    },
    gotoExams(i) {
      this.$store.commit("liveData/set_selectedActivity", i);
      this.$router.push("/activityExams");
    },
    gotoNotifications() {
      this.show = false;
      this.$store.dispatch("liveData/set_selectedActivity", 8);
      this.$router.push("/activityNotifications");
    },
    gotoChangeSemester() {
      this.$store.dispatch("liveData/set_selectedActivity", 7);
      this.$router.push("/activityChangeSemester");
    },
    activityCorporateDashboard(i) {
      this.$store.dispatch("liveData/set_selectedActivity", i);
      this.$router.push("/activityCorporateDashboard");
    },
    //gotoTopicFeedback() {
    //  this.$store.dispatch("liveData/set_selectedActivity", 9);
    //  this.$router.push("/activityTopicFeedback");
    //},
    gotoStudyMaterial() {
      this.$store.dispatch("liveData/set_selectedActivity", 10);
      this.$router.push("/activityStudyMaterial");
    },
    goToActivity(mod, i) {
      // if (i === 0) {
      //   this.gotoMyDashboard(i)
      // }
      if (i === 0) {
        this.gotoTimeline(i)
      }
      if (i === 1) {
        this.gotoMySubjects(i)
      }
      if (i === 2) {
        this.gotoMyTimetable(i)
      }
      if (i === 3) {
        this.gotoAttendanceAnalysis(i)
      }
      if (i === 4) {
        this.gotoExams(i)
      }
      if (i === 5) {
        this.gotoFeedbackForm(i)
      }
    },
    goToActivityCorporate(mod, i) {
      if (i === 0) {
        this.activityCorporateDashboard(i)
      }
      if (i === 1) {
        this.gotoTimeline(i)
      }
      if (i === 2) {
        this.gotoMySubjects(i)
      }
      if (i === 3) {
        this.gotoMyTimetable(i)
      }
      if (i === 4) {
        this.gotoAttendanceAnalysis(i)
      }
      if (i === 5) {
        this.gotoExams(i)
      }
      if (i === 6) {
        this.gotoFeedbackForm(i)
      }
    },
    gotoMyDashboard(i) {
      this.$store.dispatch("liveData/set_selectedActivity", i);
      this.$router.push("/activityMyDashboard");
    },
    gotoFeedbackForm(i) {
      this.$store.dispatch('liveData/set_selectedActivity', i)
      this.$router.push('/activityFeedbackList')
    },
    // gotoFeedbackForm() {
    //   const FORM_VIEWER = new URL('https://feedback-form-studium.vercel.app/student')
    //   FORM_VIEWER.searchParams.set('importedInstituteId', this.selectedSemester.instituteId)
    //   FORM_VIEWER.searchParams.set('semId', this.selectedSemester.semId)
    //   FORM_VIEWER.searchParams.set('studentId', this.selectedSemester.uId)
    //   FORM_VIEWER.searchParams.set('departmentName', this.selectedSemester.department)
    //   FORM_VIEWER.searchParams.set('courseName', this.selectedSemester.courseYear)
    //   FORM_VIEWER.searchParams.set('divisionName', this.selectedSemester.division)
    //   this.formLink = FORM_VIEWER
    //   this.isFormModalOpen = true
    // },
    async logout() {
      let fcmToken = ""
      if (firebase.messaging.isSupported()) {
        try {
          fcmToken = await firebase.messaging().getToken();
        } catch (e) {
          if (e.code !== 'messaging/permission-default') {
            console.error(e)
          }
        }
      }
      if (Array.isArray(this.userData.registrationToken)) {
        const tokenArray = []
        this.userData.registrationToken.map(async (token) => {
          if (token === fcmToken) {
          } else {
            tokenArray.push(token)
          }
        })
        const obtToPush = {
          uId: this.userData.uId,
          registrationToken: tokenArray
        }
        const response = await this.userRepositoryInstance.deleteRegistrationToken(obtToPush);
        console.log('response', response);
      } else {
        if (this.userData.registrationToken === fcmToken) {
        } else {
          const obtToPush = {
            uId: this.userData.uId,
            registrationToken: this.userData.registrationToken
          }
          const response = await this.userRepositoryInstance.deleteRegistrationToken(obtToPush);
          console.log('response', response);
        }
      }

      this.logoutDialog = false;

      this.$store.commit("user/reset");
      this.$store.commit("instituteData/reset");
      this.$store.commit("mappings/reset");
      this.$store.commit("liveData/reset");
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    },
    async onInit(promise) {
      try {
        const { capabilities } = await promise
        console.log('capabilities', capabilities);
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.qrCodeScannerError = "user denied camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.qrCodeScannerError = "no suitable camera device installed"
        } else if (error.name === 'NotSupportedError') {
          this.qrCodeScannerError = "page is not served over HTTPS (or localhost)"
        } else if (error.name === 'NotReadableError') {
          this.qrCodeScannerError = "maybe camera is already in use"
        } else if (error.name === 'OverconstrainedError') {
          this.qrCodeScannerError = "did you requested the front camera although there is none?"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qrCodeScannerError = "browser seems to be lacking features"
        }
      } finally {
        // hide loading indicator
      }
    },
    async onDecode(promise) {
      try {
        const qrData = JSON.parse(await promise)
        console.log('qrData', qrData);
        qrData['uId'] = this.userData.uId;
        qrData['status'] = 0;
        qrData['deviceId'] = this.fingerprint;
        qrData['timeOfScan'] = (new Date()).toISOString();
        if (qrData.eventId) {
          if (qrData.applicableInstitutes.includes(this.userData.instituteId)) {
            qrData.fullName = `${this.userData?.firstName || ''} ${this.userData?.middleName || ''} ${this.userData?.lastName || ''}`;
            qrData.instituteId = this.userData?.instituteId || '';
            qrData.instituteName = this.brandingName || '';
            qrData.semName = this.userData?.semName || '';
            qrData.department = this.userData?.department || '';
            qrData.courseYear = this.userData?.courseYear || '';
            qrData.division = this.userData?.division || '';
            qrData.collegePRNNo = this.userData?.collegePRNNo || '';
            qrData.rollNo = this.userData?.rollNumber || '';
            qrData.userName = this.userData?.userName || '';
          }
          console.log('this.userData', this.userData);
          console.log('this.userData?.userName', this.userData?.userName);
          console.log('qrData web', qrData);
          sendEventQrDataToFireBase(qrData)
        } else {
          sendQrDataToFireBase(qrData)
        }
        this.showQrCodeScanner = false
        this.pause()
        await this.timeout(200)
        this.unpause()
        // alert(qrData.token)
        setTimeout(this.showResult, 500)
      } catch (error) {
        console.error("QR Code Reader", error.message)
      }
    },
    showResult() {
      this.qrResultByLiveData = this.$store.getters['liveData/get_attendanceInfoByQr']
      this.showQrAttendanceResult = true;
    },
    unpause() {
      this.camera = 'auto'
    },
    pause() {
      this.camera = 'off'
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
};
</script>

<style src="./activityTestHome.css"></style>
