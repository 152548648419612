import nbaNetworkManager from '../NetworkManager/nba'
import ApiNames from '../ApiNames/NBA'

class NBARepository {
  constructor (context) {
    this.context = context
  }

  async getBatchesOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.postRequest(ApiNames.getBatchesOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.data
  }
}

export default NBARepository
