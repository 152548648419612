import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Exam'

class Exam {
  constructor (context) {
    this.context = context
  }

  async allExamForHallTicket (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.allExamForHallTicket, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getDetailsOfAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getDetailsOfAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exam
  }

  async getAnalysisOfAnExamForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAnalysisOfAnExamForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examAnalysis
  }

  async getExamsOfACriteria (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamsOfACriteria, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async getExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    // const examApiKey = this.context.$store.getters['instituteData/get_examApiKey']
    const examApiKey = this.context.$store.getters["instituteData/get_selectedInstitute"];
    const apiKey = examApiKey
    networkManager.setHeader({ apiKey })
    const response = await networkManager.postRequest(ApiNames.getExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExamsAndSubjectAndQuestionDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExamsAndSubjectAndQuestionDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }
}

export default Exam
