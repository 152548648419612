import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/UserAuthentication";

class UserAuthentication {
  constructor(context) {
    this.context = context;
  }

  async getUserAuthenticationByUid(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthenticationByUid,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async resetPassword(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.resetPassword,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  // update visited routes
  async updateVisitedRoutes(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.postRequest(
      ApiNames.updateVisitedRoutes,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default UserAuthentication;
