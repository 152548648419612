export default {
  createTopicFeedback: '/topicFeedback/createTopicFeedback',
  getTopicFeedbackById: '/topicFeedback/getTopicFeedbackById',
  getTopicFeedbacksForAStudent: '/topicFeedback/getTopicFeedbacksForAStudent',
  getTopicFeedbacksForStudent: '/topicFeedback/getTopicFeedbacksForStudent',
  getTopicFeedbacksForAStudentForASubject: '/topicFeedback/getTopicFeedbacksForAStudentForASubject',
  getTopicFeedbacksForAFacultyForASubject: '/topicFeedback/getTopicFeedbacksForAFacultyForASubject',
  getTopicsOfASubjectOfAFaculty: '/topicFeedback/getTopicsOfASubjectOfAFaculty',
  getTopicFeedbacksForAFaculty: '/topicFeedback/getTopicFeedbacksForAFaculty',
  getTopicFeedbacksForADivisionForASubject: '/topicFeedback/getTopicFeedbacksForADivisionForASubject',
  submitFeedbackOfAStudent: '/topicFeedback/submitFeedbackOfAStudent',
  getTopicFeedbacksByTopicId: '/topicFeedback/getTopicFeedbackByTopicId',
  getAllTopicFeedbacksByTopicId: '/topicFeedback/getAllTopicFeedbacksByTopicId'
}
