import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/AssessmentRubric";

class AssessmentRubricRepository {
  constructor(context) {
    this.context = context;
  }

  async getAssessmentRubricById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssessmentRubricById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default AssessmentRubricRepository;
