import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/OnlineLecture'

class OnlineLecture {
  constructor (context) {
    this.context = context
  }

  async getLinkOfOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLinkOfOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.redirectionUrl
  }

  async getRedirectionUrl (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRedirectionUrl, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.redirectionUrl
  }

  async getRecordingOfAnOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRecordingOfAnOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.recordedVideoLink
  }

  async getLectureMeetingId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureMeetingId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default OnlineLecture
