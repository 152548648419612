<template src="./activityAppearForExam.html"></template>
<script>
import apiV3 from '../../NetworkManager/apiV3';
import showStatus from '../../NetworkManager/showStatus';
import { differenceInSeconds, parseISO } from 'date-fns';

export default {
  name: "activityAppearForExam",
  props: ["prop_startTime"],
  data() {
    return {
      selectedExam: null,
      questions: [],
      selectedQuestion: {},
      dataProcessed: false,
      answers: [],
      index: 0,
      examId: "",
      waitingForQuestions: false,
      userData: {},
      duration: 0,
      interval: null,
      hours: 0,
      minutes: 0,
      seconds: 0,
      showResult: false,
      result: null,
      finishing: false,
      disable: false,
      status: "",
      maxTime: 0,
      snack: false,
      snackText: "",
      unanswered: 0,
      confirmFinish: false,
      isLoadingQuestions: false,
      isSubmittingExam: false,
      isExamResult: false,
      testEndAt: ''
    };
  },
  watch: {
    duration: function () {
      this.hours = Math.floor(this.duration / 3600);
      const dur = this.duration % 3600;
      this.minutes = Math.floor(dur / 60);
      this.seconds = dur % 60;
    }
  },
  async created() {
    // console.log('prop_startTime', this.prop_startTime)
    this.isLoadingQuestions = true
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedExam = this.$store.getters["liveData/get_selectedExam"];
    try {
      const { questions } = await apiV3.getRequest('/exam/questions', {
        examId: this.selectedExam._id,
        role: 0
      })
      this.questions = questions
    } catch (e) {
      if (e.response.status === 403) {
        alert('Exam has not started yet')
      } else {
        console.error(e)
        alert('An Error Occured')
      }
      this.routeToExams()
    }

    //Converting to seconds
    const durationInSecs = this.selectedExam.durationInMins * 60
    const diff = differenceInSeconds(new Date(), parseISO(this.selectedExam.dateTimeUTC))
    this.duration = durationInSecs - diff
    this.answers = Array(this.questions.length).fill("")

    /*
      This selects the first question when starting the exam.
      Without this no question will be displayed on first load
      unless you click on any question numbers
    */
    this.selectQuestion(this.questions[0], 0)

    const self = this
    this.interval = setInterval(function () {
      if (self.duration > 0) {
        self.duration--;
      } else {
        self.finishExam();
      }
    }, 1000);
    this.isLoadingQuestions = false
  },
  methods: {
    /* processData(res) { */
    /*   this.answers = []; */
    /*   // for (let i = 0; i < res.exam.questions.length; i++) { */
    /*   // } */
    /*   console.log(this.questions); */
    /*   this.duration = parseInt(res.exam.duration); */
    /*   this.maxTime = res.exam.duration; */
    /*   console.log(res.exam.duration, this.duration); */
    /*   const self = this; */
    /*   this.interval = setInterval(function () { */
    /*     if (self.duration > 0) { */
    /*       self.duration--; */
    /*     } else { */
    /*       console.log(!self.finishing && self.status === "ok"); */
    /*       if (!self.finishing && self.status === "ok") { */
    /*         self.finishExam(); */
    /*       } */
    /*     } */
    /*   }, 1000); */
    /*   for (let i = 0; i < this.questions.length; i++) { */
    /*     if (res.exam.answers[this.questions[i].questionNumber]) { */
    /*       this.questions[i].answered = true; */
    /*       this.answers.push( */
    /*         res.exam.answers[this.questions[i].questionNumber].answer */
    /*       ); */
    /*     } else { */
    /*       this.questions[i].answered = false; */
    /*       if (this.questions[i].type === "Multiselect MCQ") { */
    /*         this.answers.push([]); */
    /*       } else { */
    /*         this.answers.push(""); */
    /*       } */
    /*     } */
    /*   } */
    /*   if (this.questions.length > 0) { */
    /*     this.selectedQuestion = this.questions[0]; */
    /*     console.log(this.questions[0]); */
    /*     this.index = 0; */
    /*   } */
    /*   this.dataProcessed = true; */
    /*   console.log(this.dataProcessed); */
    /* }, */
    async selectQuestion(que, i) {
      //TODO use this to save in localstorage
      /* await this.submitAnswer(); */
      this.selectedQuestion = que;
      this.index = i;
      console.log(this.answers);
    },
    async submitAnswer() {
      if (this.answers[this.index]) {
        const objToPush = {
          instituteId: this.selectedExam.instituteId,
          semId: this.selectedExam.semId,
          examId: this.selectedExam.examId,
          subjectId: this.selectedExam.subjectId,
          uId: this.userData.uId,
          answer: this.answers[this.index],
          questionNumber: this.selectedQuestion.questionNumber
        };
        const tempQuestion = this.selectedQuestion;
        // console.log(objToPush);
        this.socket.emit("submitAnswerForAStudent", objToPush);
        this.disable = true;
        const self = this;
        this.socket.on("submitAnswerForAStudentAck", (res) => {
          console.log(res);
          if (res === "1") {
            tempQuestion.answered = true;
          } else {
            self.showError("Something went wrong while submitting answer!");
          }
          self.disable = false;
        });
      }
    },
    async gotoNextQuestion() {
      this.index++;
      this.selectedQuestion = this.questions[this.index];
    },
    async gotoPreviousQuestion() {
      this.index--;
      this.selectedQuestion = this.questions[this.index];
    },
    async finishExam() {
      try {
        this.isSubmittingExam = true;
        for (const [i, answer] of [...this.answers].entries()) {
          if (answer) {
            this.questions[i].selectedAnswer = answer
          } else {
            this.questions[i].selectedAnswer = ""
          }
        }
        this.testEndAt = new Date()
        // console.log('this.testEndAt', this.testEndAt)
        const time1 = new Date(this.prop_startTime);
        const time2 = new Date(this.testEndAt);
        // console.log('time1', time1, time2)
        const timeDifferenceInMilliseconds = time2 - time1;
        const totalSeconds = timeDifferenceInMilliseconds / 1000;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        const formattedTimeDifference = `${formattedMinutes}:${formattedSeconds}`;
        // console.log('formattedTimeDifference', formattedTimeDifference);
        const data = {
          studentId: this.userData.uId,
          examId: this.selectedExam._id,
          answers: this.questions,
          timeTaken: formattedTimeDifference
        }
        this.result = await apiV3.postRequest('/exam/save', data)
        showStatus('Exam saved successfully', 2000, 'success', this)
        this.isExamResult = true
        clearInterval(this.interval)
      } catch (e) {
        console.error(e)
        showStatus('An error occured, try again', 2000, 'error', this)
      } finally {
        this.isSubmittingExam = false
      }
    },
    // viewResult () {
    //   let objToPush = {
    //     instituteId: this.selectedExam.instituteId,
    //     semId: this.selectedExam.semId,
    //     examId: this.selectedExam.examId,
    //     subjectId: this.selectedExam.subjectId,
    //     uId: this.userData.uId
    //   }
    //   this.socket.emit('getResultsForAStudent', objToPush)
    //   let self = this
    //   this.socket.on('getResultsForAStudentAck', res => {
    //     if (res !== '0') {
    //       self.result = res.marksObtained
    //       self.showResult = true
    //     } else {
    //       self.showError('Something went wrong while fetching result!')
    //     }
    //   })
    // },
    showError(str) {
      this.snack = true;
      this.snackText = str;
    },
    checkIfFinishIsAllowed() {
      this.unanswered = 0;
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].answered) {
        } else {
          this.unanswered++;
        }
      }
      this.confirmFinish = true;
    },
    closeExamResult() {
      this.isExamResult = false
      window.scroll(0, 0)
      this.routeToExams()
    },
    routeToExams() {
      window.scroll(0, 0)
      if (this.isMobileView) {
        this.$router.replace({
          name: "activityExams2"
        });
      } else {
        this.$router.replace({
          name: "activityExams"
        });
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>
<style scoped src="./activityAppearForExam.css"></style>
