import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/StudyMaterial";

class StudyMaterialRepository {
  constructor(context) {
    this.context = context;
  }

  async getStudyMaterialOfFaculty(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getStudyMaterial,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default StudyMaterialRepository;
