<template src="./activityCourseSection.html"></template>

<script>
import SubjectsRepository from "../../Repository/Subjects";
import CourseRepository from "../../Repository/Course";
import { spacesUrl } from "../../NetworkManager";
import Vue from "vue"
import VueSimpleAlert from "vue-simple-alert";
// import $ from 'jquery';

Vue.use(VueSimpleAlert);
export default {
  name: "activityCourseSection",
  props: ["prop_courseDetails", "prop_course", "prop_QuizeCourse", "prop_indexSelectedSubSection", "prop_indexOfSelectedSection", "quizeSubmitted"],
  data() {
    return {
      isMobileView: false,
      isEdcInstitute: false,
      selectedInstitute: '',
      subsectionsFinished: false,
      quizeSubmit: this.quizeSubmitted ? this.quizeSubmitted : false,
      isSubsectionCompleted: false,
      isAssignmentLoading: true,
      files: [],
      course: {},
      indexOfSelectedSubsection: 0,
      documentFiles: [],
      subsectionNo: 0,
      currentUploadProgress: 0,
      uploadedDocumentFiles: [],
      chosenFile: null,
      subsectionTitle: '',
      sectionTitle: '',
      assignment: '',
      video: '',
      audio: '',
      document: '',
      webDocLink: '',
      selectedcourse: {},
      quiz: '',
      uploadFileData: [],
      backBtn: '',
      currentlyUploadingNumber: 0,
      nextBtn: '',
      myVideo: '',
      myAudio: '',
      sectionDescription: '',
      courseDetails: {},
      sections: [],
      assignmentDescription: '',
      assignmentLink: '',
      userData: {},
      signedUrl: [],
      signedUrlsData: [],
      subSections: [],
      courseSections: [],
      studentCourse: {},
      contentType: '',
      subSecRating: 0,
      shortAns: '',
      indexOfSelectedSection: null,
      selectedOption: null,
      assignmentUploadType: '',
      videoLength: 1,
      currentTime: 0,
      audioLength: 1,
      listenTime: 0,
      docLink: '',
      isDocModalOpen: false,
      step: 1,
      index: 0
    }
  },
  mounted() {
    document.addEventListener('contextmenu', (e) => e.preventDefault());
    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }
    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        event.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      ) { return false; }
    };
  },
  async created() {
    this.videoLength = 1
    // this.currentTime = 0
    this.audioLength = 1
    this.listenTime = 0
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.selectedcourse = this.$store.getters["liveData/get_selectedSection"];
    this.courseSections = this.$store.getters["liveData/get_courseSections"];
    this.userData = this.$store.getters['user/get_userData']
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.courseName = this.selectedcourse.courseName
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.CourseRepositoryInstance = new CourseRepository(this);
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    // this.isCorporate = this.$store.getters["liveData/get_isCorporateInstitute"];
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }
    if (this.prop_indexOfSelectedSection !== undefined) {
      this.indexOfSelectedSection = this.prop_indexOfSelectedSection
      this.subsectionNo = this.prop_indexSelectedSubSection
      this.selectedcourse = this.courseSections[this.indexOfSelectedSection]
      if (this.quizeSubmit === true) {
        await this.gotoNextSubsect({}, this.prop_indexSelectedSubSection)
      }
    } else {
      this.indexOfSelectedSection = this.courseSections.indexOf(this.selectedcourse)
    }
    this.courseDetails = this.prop_courseDetails
    this.assignmentFileType()
    this.chkNopen(this.selectedcourse.subSections[this.index])
  },
  beforeDestroy() {
    document.removeEventListener('blur', () => { })
    document.removeEventListener('visibilitychange', () => { })
    document.removeEventListener('keydown', () => { })
    document.removeEventListener('contextmenu', () => { })
  },
  methods: {
    getRealTime(item, i) {
      this.selectedcourse.subSections[i] = item
      this.index = i
      this.myVideo = document.getElementById(`${item.subsectionTitle}`)
      this.videoLength = Math.round(this.myVideo.duration)
      this.currentTime = Math.round(this.myVideo.currentTime);
      item.watchtime += this.currentTime
      item.vidLength = this.videoLength
      document.addEventListener('visibilitychange', this.visibilityChange(this.myVideo), false);
      window.addEventListener('blur', this.onBlur(this.myVideo));
      localStorage.setItem(item.subSectionFileUrl, this.currentTime);
    },
    getAudioTime(item, i) {
      this.myAudio = document.getElementById(`${item.subsectionTitle}`)
      this.audioLength = Math.round(this.myAudio.duration)
      this.listenTime = Math.round(this.myAudio.currentTime);
    },
    gotoPrevSubsect() {
      this.subsectionNo = this.subsectionNo - 1
      if (this.selectedcourse.subSections[this.subsectionNo].contentType === "Document") {
            this.openFileInWeb(this.selectedcourse.subSections[this.subsectionNo].subSectionFileUrl)
          }
      this.myVideo.pause();
      this.myAudio.pause();
    },
    chkCompleted(obj) {
      return obj.isSubsectionCompleted
    },
    async gotoNextSubsect(subsection, subSectionIndex) {
      this.sections = []
      this.subSections = this.courseSections[this.indexOfSelectedSection].subSections
      const tempSubSections = []
      const localTempSubsection = []
      this.subSections.map((obj, i) => {
        if (obj.isSubsectionCompleted !== true) {
          const subsectionData = {
            contentType: obj.contentType,
            subsectionTitle: obj.subsectionTitle,
            subsectionCredit: obj.subsectionCredit,
            subSectionId: obj.subSectionId ? obj.subSectionId : ''
          }
          if (i === subSectionIndex) {
            subsectionData.isSubsectionCompleted = true
            subsectionData.subsectionStudentCredit = parseInt(obj.subsectionStudentCredit) + parseInt(obj.subsectionCredit)
            if (obj.assignmentDescription) {
              subsectionData.assignmentDescription = obj.assignmentDescription
            }
            if (obj.assignmentLink) {
              subsectionData.assignmentLink = obj.assignmentLink
            }
            if (obj.assignmentFileUrl) {
              subsectionData.assignmentFileUrl = obj.assignmentFileUrl
            }
            if (obj.fileName) {
              subsectionData.fileName = this.signedUrlsData.fileName
            }
            if (obj.studentAnswers) {
              subsectionData.studentAnswers = obj.studentAnswers
            }
            if (obj.studentMarks) {
              subsectionData.studentMarks = obj.studentMarks
            }
            if (obj.studentTimeTaken) {
              subsectionData.studentTimeTaken = obj.studentTimeTaken
            }
            if (obj.feedbackArr) {
              subsectionData.feedbackArr = obj.feedbackArr
            }
          } else {
            subsectionData.isSubsectionCompleted = obj.isSubsectionCompleted
          }
          tempSubSections.push(subsectionData)
        } else {
          const objToPush = {
            contentType: obj.contentType,
            subsectionTitle: obj.subsectionTitle,
            subsectionCredit: obj.subsectionCredit,
            isSubsectionCompleted: obj.isSubsectionCompleted,
            subsectionStudentCredit: obj.subsectionStudentCredit,
            subSectionId: obj.subSectionId ? obj.subSectionId : ''
          }
          if (obj.assignmentDescription) {
            objToPush.assignmentDescription = obj.assignmentDescription
          }
          if (obj.assignmentLink) {
            objToPush.assignmentLink = obj.assignmentLink
          }
          if (obj.assignmentFileUrl) {
            objToPush.assignmentFileUrl = obj.assignmentFileUrl
          }
          if (obj.fileName) {
            objToPush.fileName = obj.fileName
          }
          if (obj.studentAnswers) {
            objToPush.studentAnswers = obj.studentAnswers
          }
          if (obj.studentMarks) {
            objToPush.studentMarks = obj.studentMarks
          }
          if (obj.studentTimeTaken) {
            objToPush.studentTimeTaken = obj.studentTimeTaken
          }
          if (obj.feedbackArr) {
            objToPush.feedbackArr = obj.feedbackArr
          }
          tempSubSections.push(objToPush)
        }
      })
      this.subSections.map((obj, i) => {
        if (tempSubSections[i].subsectionStudentCredit) {
          obj.subsectionStudentCredit = tempSubSections[i].subsectionStudentCredit
        }
        if (tempSubSections[i].isSubsectionCompleted) {
          obj.isSubsectionCompleted = tempSubSections[i].isSubsectionCompleted
        }
        if (tempSubSections[i].assignmentLink) {
          obj.assignmentLink = tempSubSections[i].assignmentLink
        }
        if (tempSubSections[i].assignmentFileUrl) {
          obj.assignmentFileUrl = tempSubSections[i].assignmentFileUrl
        }
        if (tempSubSections[i].fileName) {
          obj.fileName = tempSubSections[i].fileName
        }
        if (tempSubSections[i].studentAnswers) {
          obj.studentAnswers = tempSubSections[i].studentAnswers
        }
        if (tempSubSections[i].studentMarks) {
          obj.studentMarks = tempSubSections[i].studentMarks
        }
        if (tempSubSections[i].studentTimeTaken) {
          obj.studentTimeTaken = tempSubSections[i].studentTimeTaken
        }
        if (tempSubSections[i].feedbackArr) {
          obj.feedbackArr = tempSubSections[i].feedbackArr
        }
        localTempSubsection.push({ ...obj })
      })
      this.selectedcourse.subSections = localTempSubsection
      this.courseSections.map((section, i) => {
        if (i === this.indexOfSelectedSection) {
          const sectionData = {
            sectionTitle: section.sectionTitle,
            sectionId: section._id
          }
          sectionData.creditsRecieved = 0
          tempSubSections.map((obj) => {
            sectionData.creditsRecieved += obj.subsectionStudentCredit !== undefined ? parseInt(obj.subsectionStudentCredit) : 0
          })
          sectionData.subSections = tempSubSections
          this.sections.push(sectionData)
        } else {
          section['sectionId'] = section._id
          this.sections.push(section)
        }
      })
      const objToPush = {
        instituteId: this.selectedSemester.instituteId,
        semId: this.selectedSemester.semId,
        department: this.selectedSemester.department,
        courseYear: this.selectedSemester.courseYear,
        courseId: this.selectedcourse.courseId,
        uId: this.selectedSemester.uId,
        sections: this.sections,
        isCourseCompleted: this.isCourseCompleted
      }
      await this.CourseRepositoryInstance.createStudentCourse(objToPush)
      this.documentFiles = []
      if (this.selectedcourse.subSections.length - 1 === this.subsectionNo) {
        this.goBack()
      } else {
        if (this.quizeSubmit === false) {
          this.subsectionNo = this.subsectionNo + 1
          if (this.selectedcourse.subSections[this.subsectionNo].contentType === "Document") {
            this.openFileInWeb(this.selectedcourse.subSections[this.subsectionNo].subSectionFileUrl)
          }
        }
      }
      this.currentUploadProgress = 0
      this.listenTime = 0
      this.audLength = 1
      this.currentTime = 0
      this.vidLength = 1
      try {
        this.myVideo.pause();
        this.myAudio.pause();
      } catch (e) {
        console.log('e', e);
      }
      this.$store.commit("liveData/set_selectedSection", {
        selectedSection: this.courseSections[this.indexOfSelectedSection]
      });
      // this.chkNopen(this.selectedcourse.subSections[this.index])
    },
    getFileName(fileUrl) {
      return fileUrl.split('/')[fileUrl.split('/').length - 1]
    },
    async uploadAssignmentFromStudent() {
      if (this.documentFiles.length === 0) return [];
      const documentFilePromises = [];
      const documentFiles = this.documentFiles.filter(item => item.name);

      documentFiles.forEach(documentFile => {
        documentFilePromises.push(
          this.getSignedUrl(documentFile, `${this.userData.uId}/documents/`)
        );
      });
      this.signedUrlsData = await Promise.all(documentFilePromises);
      const signedUrls = this.signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === this.documentFiles.length) {
        documentFiles.forEach((documentFile, i) => {
          uploadPromises.push(this.uploadToSpaces(documentFile, signedUrls[i]));
        });
        await Promise.all(uploadPromises);
        return this.signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          name: documentFiles[i].name,
          mimeType: item.mimeType
        }));
      } else {
        return [];
      }
    },
    async uploadFile(obj, i) {
      this.$confirm("Are you sure? Once Assignment Submitted, then it cannot be changed").then(async () => {
        this.indexOfSelectedSubsection = this.selectedcourse.subSections.indexOf(obj)
        const fileData = await this.uploadAssignmentFromStudent()
        fileData.map((item, i) => {
          obj.assignmentFileUrl = item.url
        })
        this.syncData(obj)
      });
    },
    syncData(obj) {
      this.selectedcourse.subSections[this.indexOfSelectedSubsection] = obj
      this.isAssignmentLoading = false
    },
    disableNextBtn(subsection, i) {
      if (subsection.contentType === 'Assignment') {
        if (subsection.assignmentDescription === '' || subsection.assignmentLink === '' || subsection.assignmentFileUrl === '') {
          return true
        } else {
          return false
        }
      }
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.CourseRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      return res;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    gotoStartQuiz(obj, i) {
      this.$confirm("You want to start the Quiz?").then(() => {
        if (this.isMobileView) {
          this.$router.push({
            name: "activityCourseQuiz2",
            params: {
              prop_course: obj,
              prop_subjectInfo: this.prop_course,
              prop_indexSelectedSubSection: i,
              prop_indexOfSelectedSection: this.indexOfSelectedSection,
              prop_courseDetails: this.courseDetails
            }
          });
        } else {
          this.$router.push({
            name: "activityCourseQuiz",
            params: {
              prop_course: obj,
              prop_subjectInfo: this.prop_course,
              prop_indexSelectedSubSection: i,
              prop_indexOfSelectedSection: this.indexOfSelectedSection,
              prop_courseDetails: this.courseDetails
            }
          });
        }
      });
    },
    async gotoQuizResult(course, i) {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityQuizResult2",
          params: {
            prop_course: course,
            prop_subjectInfo: this.prop_course,
            prop_indexOfSelectedSection: this.indexOfSelectedSection,
            prop_indexSelectedSubSection: i,
            prop_courseDetails: this.prop_courseDetails
          }
        });
      } else {
        this.$router.push({
          name: "activityQuizResult",
          params: {
            prop_course: course,
            prop_subjectInfo: this.prop_course,
            prop_indexOfSelectedSection: this.indexOfSelectedSection,
            prop_indexSelectedSubSection: i,
            prop_courseDetails: this.prop_courseDetails
          }
        });
      }
    },
    async gotoAssignmentResult(course, i) {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityAssignmentResult2",
          params: {
            prop_student: course,
            prop_subjectInfo: this.prop_course,
            prop_indexOfSelectedSection: this.indexOfSelectedSection,
            prop_indexSelectedSubSection: i,
            prop_courseDetails: this.prop_courseDetails
          }
        });
      } else {
        this.$router.push({
          name: "activityAssignmentResult",
          params: {
            prop_student: course,
            prop_subjectInfo: this.prop_course,
            prop_indexOfSelectedSection: this.indexOfSelectedSection,
            prop_indexSelectedSubSection: i,
            prop_courseDetails: this.prop_courseDetails
          }
        });
      }
    },
    goBack() {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseMaterial2",
          params: {
            prop_courseDetails: this.prop_courseDetails
          }
        });
      } else {
        this.$router.push({
          name: "activityCourseMaterial",
          params: {
            prop_courseDetails: this.prop_courseDetails
          }
        });
      }
    },
    assignmentFileType() {
      const studentsSubsections = this.selectedcourse.subSections
      studentsSubsections.map((obj) => {
        if (obj.contentType === 'Assignment') {
          obj.allowedFileTypeStudents.map((type) => {
            if (type === 'Any') {
              this.assignmentUploadType = "*"
            }
            if (type === 'Audio') {
              this.assignmentUploadType = "audio/*"
            }
            if (type === 'Video') {
              this.assignmentUploadType = "video/*"
            }
            if (type === 'PDF') {
              this.assignmentUploadType = ".pdf"
            }
            if (type === 'Excel') {
              this.assignmentUploadType = ".doc, .docx, .xlsx"
            }
            if (type === 'Img') {
              this.assignmentUploadType = "image/*"
            }
          })
        }
      })
    },
    visibilityChange(element) {
      // const videoEls = element
      if (document.hidden && Array.isArray(element)) {
        element.forEach(el => el.pause())
      }
    },
    onBlur(element) {
      // const videoEls = element
      if (Array.isArray(element)) {
        element.forEach(el => el.pause())
      }
    },
    openFile(attachment) {
      try {
        const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
        PDF_VIEWER.searchParams.set('pdf', attachment)
        this.docLink = PDF_VIEWER
        this.isDocModalOpen = true
      } catch (e) {
        console.log('e', e);
      }
    },
    chkNopen(attachment) {
      if (attachment.contentType === "Document") {
        this.openFileInWeb(attachment.subSectionFileUrl)
      }
    },
    openFileInWeb(attachment) {
      try {
        const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
        PDF_VIEWER.searchParams.set('pdf', attachment)
        this.webDocLink = PDF_VIEWER
        return true
      } catch (e) {
        console.log('e', e);
      }
    },
    videoWachedTime(obj, i) {
      obj.currentTime = localStorage.getItem(this.selectedcourse.subSections[i].subSectionFileUrl);
      obj.videoUrl = obj.subSectionFileUrl + '#t=' + obj.currentTime
      return true
    }
  }
}
</script>

<style scoped src="./activityCourseSection.css"></style>
