import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/timetable'

class TimetableRepository {
  constructor (context) {
    this.context = context
  }

  async getSubjectsOfAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }

  async getAllocationsOfAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTimetableSlotsOfAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.timetableSlots
  }
}

export default TimetableRepository
