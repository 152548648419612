import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Assessment";

class AssessmentRepository {
  constructor(context) {
    this.context = context;
  }

  async getAssessmentsOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssessmentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default AssessmentRepository;
