import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SemesterUser'

class SemesterUser {
  constructor (context) {
    this.context = context
  }

  async getCurrentSemesterUserForAUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCurrentSemesterUserForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getSubjectsOfASemesterUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfASemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSemesterUsersForAUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsByInstituteAndDepartmentAndUid (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsByInstituteAndDepartmentAndUid, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async updateSubjects (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSubjects, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
}

export default SemesterUser
