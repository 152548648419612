import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/User";

class User {
  constructor(context) {
    this.context = context;
  }

  async login(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(JSON.stringify(data));
    const response = await networkManager.postRequest(ApiNames.login, data);
    this.context.$store.commit("user/set_userData", { uId: response.uId });
    this.context.$store.commit("liveData/set_loading", false);
  }

  async getUserProfile(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getUserProfile,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.user;
  }

  async getUserAuthentication(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getUserAuthentication,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return { emails: response.emails, phones: response.phones };
  }

  async deleteRegistrationToken(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.deleteRegistrationToken,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return { emails: response.emails, phones: response.phones };
  }

  async getFullNameOfUser(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getFullNameOfUser,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.fullName;
  }

  async getFullNameOfUsers(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getFullNameOfUsers,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.fullNames;
  }

  async updateFirstName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateFirstName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.firstName;
  }

  async updateMiddleName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateMiddleName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateLastName(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateLastName,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.lastName;
  }

  async updateDateOfBirth(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateDateOfBirth,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.dateOfBirth;
  }

  async updateGender(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateGender,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.gender;
  }

  async updateEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateEmail,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.email;
  }

  async updatePhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updatePhone,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.phone;
  }

  async addPhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.postRequest(ApiNames.addPhone, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response.phone;
  }

  async deleteEmail(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.deleteRequest(ApiNames.deleteEmail, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deletePhone(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.deleteRequest(ApiNames.deletePhone, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async loginWithUserNameAndPassword(data) {
    try {
      this.context.$store.commit("liveData/set_loading", true);
      console.log(JSON.stringify(data));
      const response = await networkManager.postRequest(
        ApiNames.loginWithUserNameAndPassword,
        data
      );
      return response;
    } catch (e) {
      if (e.message === "Request failed with status code 404") {
        return {};
      }
      console.error(`Error in logging in user: ${e}`);
    } finally {
      this.context.$store.commit("liveData/set_loading", false);
    }
  }

  async passwordUpdate(data) {
    try {
      this.context.$store.commit("liveData/set_loading", true);
      console.log(JSON.stringify(data));
      await networkManager.putRequest(ApiNames.passwordUpdate, data);
      this.context.$store.commit("liveData/set_loading", false);
      return { error: false };
    } catch (e) {
      console.error(`Error resetting password: ${e}`);
      if (e.message === "Request failed with status code 400") {
        return { error: true, message: "Current password does not match" };
      }
    } finally {
      this.context.$store.commit("liveData/set_loading", false);
    }
  }

  async forgotPassword(data) {
    try {
      this.context.$store.commit("liveData/set_loading", true);
      console.log(JSON.stringify(data));
      await networkManager.postRequest(ApiNames.forgotPassword, data);
      this.context.$store.commit("liveData/set_loading", false);
    } catch (e) {
      console.error(`Error in forgot password: ${e}`);
    } finally {
      this.context.$store.commit("liveData/set_loading", false);
    }
  }

  async saveStudentsAccdamicCouseDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsAccdamicCouseDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveStudentsAccdamicInternshipDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsAccdamicInternshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveStudentsAccdamicJobDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsAccdamicJobDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveStudentsAccdamicHigherStudies(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsAccdamicHigherStudies,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveStudentsAccdamicEntrepreneurshipDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsAccdamicEntrepreneurshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveStudentsCoActivitesEventsDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveStudentsCoActivitesEventsDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async saveExtraStudentsDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.saveExtraStudentsDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async getAccadamicAndCoActivityDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.getRequest(
      ApiNames.getAccadamicAndCoActivityDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return { response: response };
  }

  async deleteStudentsAccdamicCouseDetails(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsAccdamicCouseDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteStudentsAccdamicInternshipDetails(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsAccdamicInternshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteStudentsAccdamicJobDetails(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsAccdamicJobDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteStudentsAccdamicHigherStudiesDetails(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsAccdamicHigherStudies,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteStudentsAccdamicEntrepreneurshipDetails(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsAccdamicEntrepreneurshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async deleteStudentsCoActivitesEvents(data) {
    console.log("delete started...");
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    await networkManager.putRequest(
      ApiNames.deleteStudentsCoActivitesEvents,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
  }

  async updateStudentsAccdamicCouseDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsAccdamicCouseDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsAccdamicInternshipDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsAccdamicInternshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsAccdamicJobDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsAccdamicJobDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsAccdamicHigherStudies(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsAccdamicHigherStudies,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsAccdamicEntrepreneurshipDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsAccdamicEntrepreneurshipDetails,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsCoActivities(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsCoActivities,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }

  async updateStudentsProfileImage(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(data);
    const response = await networkManager.putRequest(
      ApiNames.updateStudentsProfilePhoto,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.middleName;
  }
}

export default User;
