<template src="./activitySelectInstituteNSemester.html"></template>
<script>
import GetInstitutesAndSemestersRepository from "../../Repository/GetInstitutesAndSemesters";
import showStatus from "../../NetworkManager/showStatus";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SemesterRepository from "../../Repository/Semester";
import UserRepository from "../../Repository/User";
import auth from "../../Services/Firebase/auth";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";

export default {
  name: "activitySelectInstituteNSemester",
  props: ["prop_subject"],
  data() {
    return {
      userAuthData: [],
      selected: [],
      notSelected: [],
      dialog1: false,
      dialog2: false,
      isMobileView: false,
      creatingInstitute: false,
      userData: {},
      alignment: "center",
      justify: "center",
      selectedInstitute: "",
      institutes: [],
      semesters: [],
      showPreviousSemestersDialog: false,
      user: "",
      tempFirstName: "",
      tempMiddleName: "",
      tempLastName: "",
      logoutDialog: false,
      isLoading: false
    };
  },
  created() {
    // const getInstituteData = this.$store.getters["liveData/get_isStudyMaterialDownloadable"];
    const applicable =
      this.$store.getters["instituteData/get_applicableInstitutes"];
      console.log('applicable', applicable);
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.getInstitutesAndSemestersRepositoryInstance =
      new GetInstitutesAndSemestersRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.UserAuthenticationRepositoryInstance =
      new UserAuthenticationRepository(this);

    this.userData = this.$store.getters["user/get_userData"];
    this.fetchData();
  },
  methods: {
    // this.$store.commit("liveData/set_emailBody", {
    //     emailBody: this.institute.emailBody,
    //   });
    async fetchData() {
      try {
        const objToPush = {
          uId: this.userData.uId
        };
        const [user, institutes] = await Promise.all([
          this.userRepositoryInstance.getUserProfile(objToPush),
          await this.getInstitutesAndSemestersRepositoryInstance.getInstitutes(
            objToPush
          )
        ]);
        this.user = user;
        this.tempFirstName = user.firstName;
        this.tempMiddleName = user.middleName;
        this.tempLastName = user.lastName;
        this.institutes = institutes;
        this.$store.commit("liveData/set_senderMailPassword", {
          isStudyMaterialDownloadable: institutes.isStudyMaterialDownloadable
        });
        // isStudyMaterialDownloadable
        showStatus(
          "Got Institutes and Semesters Successfully!",
          1000,
          "success",
          this
        );
        this.selectInstitute(this.institutes[0]);

        const objToPush2 = {
          uId: this.userData.uId
        };
        this.userAuthData =
          await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(
            objToPush2
          );
        let usernameForLiveData = "";
        if (this.userAuthData?.userAuthentications.length > 0) {
          this.userAuthData.userAuthentications.map((data) => {
            if (data.userName && data.userName !== "") {
              usernameForLiveData = data.userName
            }
          });
        }
        this.user['userName'] = usernameForLiveData
      } catch (err) {
        console.log(err);
      }
    },
    async selectInstitute(institute) {
      this.isLoading = true;
      const { examApiKey = null } = institute;
      this.$store.dispatch("instituteData/set_examApiKey", examApiKey);
      this.selectedInstitute = institute.instituteId;
      const instituteName = institute.instituteName;
      this.$store.dispatch(
        "instituteData/set_selectedInstitute",
        this.selectedInstitute
      );
      this.$store.dispatch("instituteData/set_instituteName", instituteName);

      const instituteDataToSave = {
        email: institute.instituteEmail,
        examApiKey: institute.examApiKey,
        examInstituteId: institute.examInstituteId,
        logo: institute.instituteLogoLink,
        name: institute.instituteName,
        instituteProvidedId: institute.instituteProvidedId,
        isEklavvyaIntegration: institute.isEklavvyaIntegration
        // isCorporateInstitute: institute.isCorporateInstitute ? institute.isCorporateInstitute : false
      };
      this.$store.dispatch(
        "instituteData/selectInstitute",
        instituteDataToSave
      );
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute
      };
      try {
        const semesterUserObject =
          await this.semesterUserRepositoryInstance.getCurrentSemesterUserForAUser(
            objToPush
          );
        objToPush.semId = semesterUserObject.semId;
        semesterUserObject.semName =
          await this.semesterRepositoryInstance.getNameOfASemester(objToPush);
        semesterUserObject.examCandidateID = this.user.examCandidateID;
        this.selectSemester(semesterUserObject);
      } catch (err) {
        // console.log(err)
        this.getPreviousSemesters();
      }
      // to show selection of institute as highlighted
    },
    async getPreviousSemesters() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        uId: this.userData.uId
      };
      try {
        this.semesters =
          await this.semesterUserRepositoryInstance.getSemesterUsersForAUser(
            objToPush
          );
      } catch (err) {
        console.log(err);
        this.semesters = [];
      }

      const semIdToSemNameMapping = {};
      try {
        const semesterNamesArray =
          await this.semesterRepositoryInstance.getNamesOfSemesters(objToPush);
        semesterNamesArray.forEach((semesterNameObject) => {
          semIdToSemNameMapping[semesterNameObject.semId] =
            semesterNameObject.semName;
        });
      } catch (err) {
        console.log(err);
      }

      this.semesters.forEach((semester) => {
        if (semIdToSemNameMapping[semester.semId]) {
          semester.semName = semIdToSemNameMapping[semester.semId];
        } else {
          semester.semName = "";
        }
      });
      this.semesters = this.semesters.slice();
      this.showPreviousSemestersDialog = true;
    },
    async selectSemester(semesterObject) {
      try {
        const semesters =
          await this.semesterRepositoryInstance.getNamesOfSemesters({
            uId: this.userData.uId,
            instituteId: this.selectedInstitute
          });
        const dataToSave = { ...semesterObject, ...this.user };
        this.$store.dispatch("user/set_userData", dataToSave);
        this.$store.commit(
          "instituteData/set_selectedSemester",
          semesterObject
        );
        const isMobileView = this.$store.getters["liveData/get_isMobileView"];
        if (process.env.VUE_APP_WHITELABEL_NAME === "sppu1") {
          //name is set sppu in env local file  (temp adjustment)
          // this.dialog1 = true
          if (isMobileView) {
            this.$router.push("/activityProfile2");
          } else {
            this.$router.push("/activityProfile");
          }
        } else {
          if (this.prop_subject) {
            if (!isMobileView) {
              this.$router.push({
                name: "activityCourseMaterial",
                params: {
                  prop_subject: this.prop_subject
                }
              });
            } else {
              this.$router.push({
                name: "activityCourseMaterial2",
                params: {
                  prop_subject: this.prop_subject
                }
              });
            }
          } else {
            if (Array.isArray(semesters) && semesters.length > 0) {
              if (!isMobileView) {
                setTimeout(
                  () => this.$router.push("/activityChangeSemester"),
                  100
                );
              } else {
                setTimeout(
                  () => this.$router.push("/activityChangeSemester2"),
                  100
                );
              }
            } else if (!isMobileView) {
              setTimeout(() => this.$router.push("/activityTestHome"), 100);
            } else {
              setTimeout(() => this.$router.push("/activityHome"), 100);
            }
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    startCourse() {
      this.dialog1 = false;
      this.dialog2 = true;
    },
    cancelCourse() {
      this.dialog1 = false;
      this.logout();
    },
    disAgreeTerms() {
      this.dialog2 = false;
      this.logout();
    },
    async agreeTerms() {
      this.dialog2 = false;
      const objToPush1 = {
        uId: this.userData.uId,
        isTermsAgree: true
      };
      try {
        const response =
          await this.UserAuthenticationRepositoryInstance.updateVisitedRoutes(
            objToPush1
          );
          console.log('response', response);
        this.$router.push("/activityChangeSemester");
      } catch (error) {
        console.log(error);
      }
    },

    gotoMyProfile() {
      this.$router.push("/activityUser");
    },
    logout() {
      this.logoutDialog = false;
      this.$store.commit("user/reset");
      this.$store.commit("instituteData/reset");
      this.$store.commit("mappings/reset");
      this.$store.commit("liveData/reset");
      const user = window.localStorage.getItem("userKey");
      if (user) {
        window.localStorage.removeItem("userKey");
        this.$router.push("/activityWelcome");
      } else {
        auth.logout(this);
      }
    }
  }
};
</script>
<style scoped src="./activitySelectInstituteNSemester.css">
</style>
