<template src="./activityMyDashboard.html"></template>

<script>
import VueApexCharts from "vue-apexcharts";
import LectureRepository from "../../Repository/lectures";
import apiV3 from "../../NetworkManager/apiV3";
import TopicFeedbackRepository from '../../Repository/TopicFeedback'
import AssignmentUserRepository from "../../Repository/AssignmentUser"
import { addMinutes, compareAsc, isFuture, isWithinInterval, parseISO } from 'date-fns'
import CourseRepository from "../../Repository/Course";

export default {
  name: "activityMyDashboard",
  components: {
    apexcharts: VueApexCharts
  },
  data() {
    return {
      subjectNameArrayForAverage: [],
      subjectAverageArray: [],
      questionCount: 0,
      subjectFeedbackStarCount: 0,
      totalFeedbackFormCount: 0,
      submittedFeedbackFormCount: 0,
      assignmentPercentage: 0,
      assignmentAnalysis: {},
      attendanceAnalysis: {},
      absolute: true,
      overlay1: true,
      overlay2: true,
      overlay3: true,
      overlay4: true,
      overlay5: true,
      overlay6: true,
      subjectCategories: [],
      subjectSum: 0,
      subjectNameArray: [],
      subjectIdArray: [],
      quickTest: [],
      sum: 0,
      marks: 0,
      categories: [],
      studentCourse: [],
      allSubjectsAverage: 0,
      subjectAverage: 0,
      subjectGrandTotal: 0,
      totalConducted: 0,
      attendance: 0,
      totalPresent: 0,
      feedbackStatusPercentage: 0,
      totalSubsections: 0,
      subsectionCompletedCount: 0,
      selectedInstitute: "",
      userData: {},
      selectedSemester: {},
      isMobileView: false,
      subjectsMapping: {},
      progressBar: false,
      items: [
        "Quick Exam Analysis",
        "Semester Exam Analysis",
        "Subject-wise analysis"
      ],
      listItem: [
        "Subject 1",
        "Subject 2",
        "Subject 3",
        "Subject 4",
        "Subject 5",
        "Subject 6",
        "Subject 7",
        "Subject 8",
        "Subject 9"
      ],
      barSeries: [],
      barOptions: {
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "red",
            fontSize: "22px"
          }
        },
        colors: ["#050992"],
        chart: {
          type: "bar",
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "rounded",
            borderRadius: 10,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 30,
                  color: "#FA3333"
                },
                {
                  from: 30,
                  to: 60,
                  color: "#11BC7D"
                }
              ]
            }
          }
        },
        yaxis: {
          title: {
            text: "Performance (%)"
          }
        },
        xaxis: {
          title: {
            text: "Subject"
          },
          tickPlacement: "between"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          // custom: function({ series, seriesIndex, dataPointIndex, w }) {
          //   return (
          //     '<div class="arrow_box">' +
          //     "<span>" +
          //     w.globals.labels[dataPointIndex] +
          //     ": " +
          //     series[seriesIndex][dataPointIndex] +
          //     "</span>" +
          //     "</div>"
          //   )
          // }
          enabled: false,
          PerformancePercentage: 0
        }
      }
    };
  },
  async created() {
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this)
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.$store.commit("liveData/set_selectedActivityName", "My Progress");
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.CourseRepositoryInstance = new CourseRepository(this);
    if (process.env.VUE_APP_WHITELABEL_NAME) {
      this.careerfirstChanges = process.env.VUE_APP_WHITELABEL_NAME
    }
    if (this.careerfirstChanges !== 'careerfirst') {
      this.attendanceAnalysisFunc()
      this.examPerformanceFunc()
      this.assignmentStatusFunc()
      this.feedbackAnalysisFunc()
    }
    this.fetchStudentData()
  },
  methods: {
    async attendanceAnalysisFunc() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId,
        department: this.userData.department,
        courseYear: this.userData.courseYear,
        division: this.userData.division,
        batch: this.userData.batch
      };
      try {
        this.attendanceAnalysis =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForAStudent(
            objToPush
          );
        this.attendanceAnalysis.map((subject) => {
          this.totalPresent += parseInt(subject.totalAttended);
          this.totalConducted += parseInt(subject.totalConducted);
        });
        this.attendance = Math.round(
          (this.totalPresent / this.totalConducted) * 100
        );
      } catch (err) {
        this.attendanceAnalysis = [];
        console.log(err);
      }

      this.overlay1 = false
    },
    async examPerformanceFunc() {
      const response = await apiV3.getRequest("/exam/all", {
        category: "quick_exam",
        semId: this.userData.semId,
        studentId: this.userData.uId
      });
      await this.processQuickTests(response.exams);
      console.log('response', response);

      for (const test of this.quickTest) {
        if (test.state === 'conducted') {
          this.categories.push(test.name)
          if (isNaN(test.totalMarksObtained)) {
            this.marks += 0
            this.sum += 0
          } else {
            this.marks += parseInt(test.totalMarksObtained)
            this.sum += parseInt(test.totalOutOf)
          }
          if (this.subjectNameArray.indexOf(test.subjectName) === -1) {
            this.subjectNameArray.push(test.subjectName)
          }
        }
      }

      const data = []
      this.subjectNameArray.map((subjectName) => {
        this.subjectSum = 0
        for (const test of this.quickTest) {
          if (test.state === 'conducted' && test.subjectName === subjectName) {
            this.subjectCategories.push(test.name)
            if (isNaN(test.totalMarksObtained)) {
              this.subjectSum += 0
              this.subjectGrandTotal += 0
            } else {
              this.subjectSum += parseInt(test.totalMarksObtained)
              this.subjectGrandTotal += parseInt(test.totalOutOf)
            }
          }
        }

        const subjectAverage = (this.subjectSum / this.subjectGrandTotal) * 100
        data.push(subjectAverage.toFixed(2))
      })

      this.barOptions = {
        ...this.barOptions,
        xaxis: { categories: this.subjectNameArray }
      }

      this.barSeries = [{ name: "subject name", data: data }]

      const AVG = (this.marks / this.sum) * 100;
      if (isNaN(AVG)) {
        this.allSubjectsAverage = 0
      } else {
        this.allSubjectsAverage = parseInt(AVG)
      }
      this.overlay2 = false
      this.overlay3 = false
    },
    async assignmentStatusFunc() {
      const objToPush1 = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      };
      try {
        this.assignmentAnalysis =
          await this.assignmentUserRepositoryInstance.assignmentAnalysis(objToPush1);
      } catch (err) {
        this.assignmentAnalysis = [];
        console.log(err);
      }

      const avg = (this.assignmentAnalysis.totalSubmittedAssignmentCount / this.assignmentAnalysis.totalSubjectAssignmentCount) * 100
      if (isNaN(avg)) {
        this.assignmentPercentage = 0
      } else {
        this.assignmentPercentage = avg
      }
      this.overlay4 = false
    },
    async feedbackAnalysisFunc() {
      this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
      for (const [key] of Object.entries(this.subjectsMapping)) {
        this.subjectIdArray.push(`${key}`)
      }
      const res = await this.topicFeedbackRepositoryInstance.getTopicFeedbacksForStudent(
        {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          subjectIds: this.subjectIdArray
        }
      );
      this.submittedFeedbackFormCount = 0
      res.map((subject) => {
        Object.values(subject).forEach(element => {
          this.subjectFeedbackStarCount = 0
          this.subejctFeedbackAverage = 0
          this.totalFeedbackFormCount += parseInt(element.length)
          element.map((e) => {
            if (e.responses !== undefined) {
              for (const [key, value] of Object.entries(e.responses)) {
                if (key === '0') {
                  for (const [resKey] of Object.entries(value)) {
                    if (resKey === this.userData.uId) {
                      this.submittedFeedbackFormCount += 1
                    }
                  }
                }
                for (const [resKey, resValue] of Object.entries(value)) {
                  this.questionCount += 1
                  console.log('resKey', resKey);
                  this.subjectFeedbackStarCount += parseInt(resValue)
                }
                this.subejctFeedbackAverage = Math.round(this.subjectFeedbackStarCount / this.questionCount)
              }
            }
          })
          for (const [key] of Object.entries(subject)) {
            Object.values(this.subjectsMapping).forEach((details) => {
              if (details.subjectId === key) {
                this.subjectAverageArray.push({ subjectName: details.subjectName, subjectAvg: this.subejctFeedbackAverage })
              }
            })
          }
        });
      })
      const avg = ((this.submittedFeedbackFormCount / this.totalFeedbackFormCount) * 100).toFixed(2)
      if (isNaN(avg)) {
        this.feedbackStatusPercentage = 0
      } else {
        this.feedbackStatusPercentage = avg
      }
      this.overlay5 = false
      this.overlay6 = false
    },
    async processQuickTests(exams) {
      const now = new Date()
      for (const test of exams) {
        let state = ''
        let hasSubmitted = false
        const examStartTime = parseISO(test.dateTimeUTC)

        if (isFuture(examStartTime)) {
          state = 'pending'
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }

        const endTime = addMinutes(examStartTime, test.durationInMins)
        if (compareAsc(endTime, now) === -1 || compareAsc(endTime, now) === 0) {
          state = 'conducted'
          hasSubmitted = false
          const response = await this.getExamAnswers(test._id)
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            hasSubmitted = true
            const { totalMarksObtained, totalOutOf } = response
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`
            test.totalMarksObtained = totalMarksObtained
            test.totalOutOf = totalOutOf
          }
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }

        const isOngoing = isWithinInterval(now, { start: examStartTime, end: endTime })
        if (isOngoing) {
          state = 'ongoing'
          hasSubmitted = false
          const response = await this.getExamAnswers(test._id)
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            const { totalMarksObtained, totalOutOf } = response
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`
            hasSubmitted = true
          }
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }
      }
    },
    async getExamAnswers(examId) {
      try {
        const response = await apiV3.getRequest("/exam/answers", {
          studentId: this.userData.uId,
          examId
        })
        return response
      } catch (e) {
        return []
      }
    },
    async fetchStudentData() {
      this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
      for (const [key] of Object.entries(this.subjectsMapping)) {
        this.subjectIdArray.push(`${key}`)
      }
      try {
        const objToPush = {
          instituteId: this.selectedSemester.instituteId,
          semId: this.selectedSemester.semId,
          department: this.selectedSemester.department,
          courseYear: this.selectedSemester.courseYear,
          courseIds: this.subjectIdArray,
          uId: this.selectedSemester.uId
        }
        const result = await this.CourseRepositoryInstance.getAllCoursesInfoOfSingleStudent(objToPush)
        this.studentCourse = result.course
        for (const [key, value] of Object.entries(this.subjectsMapping)) {
          this.studentCourse.map((obj) => {
            if (obj.courseId === key) {
              obj.subjectName = value.subjectName
            }
          })
        }
        try {
          const objToPush = {
            instituteId: this.selectedSemester.instituteId,
            semId: this.selectedSemester.semId,
            department: this.selectedSemester.department,
            courseYear: this.selectedSemester.courseYear
          }
          this.courseDetails = await this.CourseRepositoryInstance.getAllCoursesInfoOfInstitute(objToPush)
          console.log('this.courseDetails', this.courseDetails);
          console.log('this.studentCourse', this.studentCourse);
          this.courseDetails.course.map((course) => {
            this.studentCourse.map((student) => {
              if (student.courseId === course.courseId) {
                student.courseDescription = course.courseDescription
                student.courseDescription = course.courseDescription
              }
            })
          })
        } catch (err) {
          console.log(err)
        }
      } catch (e) {
        this.studentCourse = []
        console.log('e', e);
      }
      this.syncCourseData()
    },
    async syncCourseData() {
      console.log('this.studentCourse', this.studentCourse);
      this.studentCourse.map((course) => {
        course.courseCredit = 0
        course.courseStudentCredit = 0
        course.sections.map((section) => {
          section.totalCredit = 0
          section.totalStudentCredit = 0
          this.totalSubsections += section.subSections.length
          section.subSections.map((subsection) => {
            this.subsectionCompletedCount += subsection.isSubsectionCompleted === true ? 1 : 0
            section.totalCredit += subsection.subsectionCredit ? parseInt(subsection.subsectionCredit) : 0
            section.totalStudentCredit += subsection.subsectionStudentCredit ? parseInt(subsection.subsectionStudentCredit) : 0
          })
          course.courseCredit += section.totalCredit ? parseInt(section.totalCredit) : 0
          course.subsectionCompletedCount += section.totalCredit ? parseInt(section.totalCredit) : 0
          course.courseStudentCredit += section.totalStudentCredit ? parseInt(section.totalStudentCredit) : 0
        })
        course.PerformancePercentage = (Number(this.subsectionCompletedCount) / Number(this.totalSubsections) * 100)
        this.PerformancePercentage = isNaN(Number(course.PerformancePercentage)) ? 0 : Number(course.PerformancePercentage)
        this.progressBar = true
      })
    },
    gotoCourseMaterial(subject) {
      subject.courseYear = this.selectedSemester.courseYear
      subject.department = this.selectedSemester.department
      subject.division = this.selectedSemester.division
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseMaterialRevamped2",
          params: {
            prop_subject: this.subjectsMapping[subject.courseId],
            prop_subjects: this.subjects
          }
        });
      } else {
        // console.log('subject', subject);
        // console.log('this.subjects', this.subjects);
        this.$router.push({
          name: "activityCourseMaterialRevamped",
          params: {
            prop_subject: this.subjectsMapping[subject.courseId],
            prop_subjects: this.subjects
          }
        });
      }
    }
  }
};
</script>

<style src="./activityMyDashboard.css" scoped></style>
