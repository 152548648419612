<template src="./activityCourseFeedback.html"></template>
<script>
import CourseRepository from "../../Repository/Course";

export default {
    name: "activityCourseSection",
    props: ["prop_courseDetails", "prop_subject", "prop_studentCourse"],
    data() {
        return {
            isMobileView: false,
            feedbackDescription: '',
            courseRating: null,
            courseDetails: null
        }
    },
    created() {
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.CourseRepositoryInstance = new CourseRepository(this);
        this.selectedSemester = this.$store.getters[
            "instituteData/get_selectedSemester"
        ];
        this.courseDetails = this.prop_courseDetails
        this.courseRating = this.prop_studentCourse.courseRating
        this.feedbackDescription = this.prop_studentCourse.feedbackDescription
    },
    methods: {
        async submitFeedback() {
            console.log('this.courseRating', this.courseRating);
            if (!this.courseRating) {
                alert("Star rating is compulsory")
            } else if (this.feedbackDescription === '') {
                alert("Feedback Description is compulsory")
            } else {
                const objToPush = {
                    instituteId: this.prop_courseDetails.instituteId,
                    semId: this.prop_courseDetails.semId,
                    department: this.prop_courseDetails.department,
                    courseYear: this.prop_courseDetails.courseYear,
                    courseId: this.prop_courseDetails.courseId,
                    uId: this.selectedSemester.uId,
                    isFeedbackGiven: true,
                    feedbackDescription: this.feedbackDescription,
                    courseRating: this.courseRating
                }
                console.log('objToPush', objToPush);
                await this.CourseRepositoryInstance.createStudentCourse(objToPush)
                this.goToBack()
            }
        },
        goToBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseMaterialRevamped2",
                    params: {
                        prop_subject: this.prop_subject
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseMaterialRevamped",
                    params: {
                        prop_subject: this.prop_subject
                    }
                });
            }
        }
    }
}
</script>
<style scoped src="./activityCourseFeedback.css">

</style>
