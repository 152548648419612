<template src='./activityTopicFeedback.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import { convertDateTime } from '../../Services/Utils/DateTimeFormatter'

import TopicRepository from '../../Repository/Topic'
import TopicFeedbackRepository from '../../Repository/TopicFeedback'
import TopicFeedbackActionRepository from '../../Repository/TopicFeedbackAction'
import TopicFeedbackTemplateRepository from '../../Repository/TopicFeedbackTemplate'
import TopicFeedbackActionTemplateRepository from '../../Repository/TopicFeedbackActionTemplate'
import SemesterUserRepository from '../../Repository/SemesterUser'

import showStatus from '../../NetworkManager/showStatus'

export default {
  name: 'activityTopicFeedback',
  components: {
    inputContainer
  },
  data () {
    return {
      userData: {},
      topicFeedbacks: [],
      topicFeedbackTemplates: [],
      topics: [],
      topicFeedbackActions: [],
      topicFeedbackActionTemplates: [],
      headersForTopicFeedback: [
        {
          text: 'Name Of Topic Feedback',
          value: 'topicFeedbackName'
        },
        {
          text: 'Topics',
          value: 'topics',
          align: 'center'
        },
        {
          text: 'Created On',
          value: 'dateOfCreation'
        },
        {
          text: 'Response',
          value: 'response'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      selectedTopicFeedbackTemplate: '',
      selectedSubjectString: '',
      subjects: [],
      subjectNames: [],
      subjectsMapping: {},
      selectedTopicFeedback: '',
      loading: true,
      openFillFeedbackDialog: false,
      filledFeedbackOptions: {},
      showFeedbackActionsDialog: false,
      feedbackActionsOfSelectedFeedback: [],
      selectedTopicFeedbackQuestions: [],
      refresh: true,
      isEditing: true
    }
  },
  created () {
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']

    this.topicRepositoryInstance = new TopicRepository(this)
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this)
    this.topicFeedbackActionRepositoryInstance = new TopicFeedbackActionRepository(this)
    this.topicFeedbackTemplateRepositoryInstance = new TopicFeedbackTemplateRepository(this)
    this.topicFeedbackActionTemplateRepositoryInstance = new TopicFeedbackActionTemplateRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    // this.dummyJsonRepositoryInstance = new DummyJsonRepository(this)

    this.fetchData()
  },
  mounted () {
    this.$store.commit('liveData/set_selectedActivityName', 'Topic Feedback')
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId
        }
        const subjectIds = await this.semesterUserRepositoryInstance.getSubjectsOfASemesterUser(objToPush) || []
        for (let i = 0; i < subjectIds.length; i++) {
          const subjectId = subjectIds[i]
          if (!this.subjectsMapping[subjectId]) {
            try {
              const subject = await this.subjectsRepositoryInstance.getDetailsOfASubject({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                subjectId: subjectId
              })
              this.subjectsMapping[subject.subjectId] = subject
              this.$store.commit('mappings/add_subjectsMapping', subject)
            } catch (err) {
              console.log(err)
            }
          }
        }
        this.subjects = Object.values(this.subjectsMapping)
        this.subjectNames = this.subjects.map((subject) => subject.subjectName)
        if (!this.selectedSubjectString) {
          this.selectedSubjectString = this.subjects[0].subjectName
        }
        this.selectSubject()
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async selectSubject () {
      this.topics = []
      try {
        this.selectedSubject = this.subjects.find((elem) => elem.subjectName === this.selectedSubjectString)
        console.log(this.selectedSubject)
        if (this.selectedSubject) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.userData.department,
            courseYear: this.userData.courseYear,
            division: this.userData.division,
            batch: this.userData.batch,
            subjectId: this.selectedSubject.subjectId,
            uId: this.userData.uId
          }
          console.log(objToPush)
          this.topics = await this.topicRepositoryInstance.getTopicsOfADivisionOfASubject(objToPush)
          // this.topics = DummyJsonRepository
          this.getTopicFeedbacks(objToPush)
        }
      } catch (err) {
        console.log(err)
        this.topics = []
      }
    },
    async getTopicFeedbacks (objToPush) {
      this.topicFeedbacks = []
      this.topicFeedbackActions = []
      this.topicFeedbackActionTemplates = []

      try {
        this.topicFeedbacks = await this.topicFeedbackRepositoryInstance.getTopicFeedbacksForAStudentForASubject(objToPush)
      } catch (err) {
        this.topicFeedbacks = []
        console.log(err)
      }
      try {
        this.topicFeedbackTemplates = await this.topicFeedbackTemplateRepositoryInstance.getTopicFeedbackTemplatesOfAnInstitute(this.selectedSubject)
      } catch (err) {
        this.topicFeedbackTemplates = []
        console.log(err)
      }

      for (let i = 0; i < this.topicFeedbacks.length; i++) {
        this.topicFeedbacks[i].topics = this.topicFeedbacks[i].topicIds
          .map((topicId) => this.topics.find((elem) => elem.topicId === topicId))
          .filter((elem) => elem)

        const topicFeedbackTemplate = this.topicFeedbackTemplates.find((elem) => elem.topicFeedbackTemplateId === this.topicFeedbacks[i].topicFeedbackTemplateId)

        if (topicFeedbackTemplate) {
          this.topicFeedbacks[i].topicFeedbackTemplate = topicFeedbackTemplate
        }
        this.topicFeedbacks[i].isFilled = Object.keys(this.topicFeedbacks[i].responses).includes(this.userData.uId)
      }
      this.topicFeedbacks.splice()

      const promises = this.topicFeedbacks
        .filter((topicFeedback) => topicFeedback.isFilled)
        .map((topicFeedback) => this.getTopicFeedbackActionForAFeedback(topicFeedback.topicFeedbackId))

      await Promise.allSettled(promises)
    },
    async getTopicFeedbackActionForAFeedback (topicFeedbackId) {
      try {
        const topicFeedbackActions = await this.topicFeedbackActionRepositoryInstance.getTopicFeedbackActionsOfATopicFeedback({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackId: topicFeedbackId
        })
        if (topicFeedbackActions.length > 0) {
          for (let i = 0; i < topicFeedbackActions.length; i++) {
            let topicFeedbackActionTemplate = this.topicFeedbackActionTemplates.find((elem) => elem.topicFeedbackTemplateId === topicFeedbackActions[i].topicFeedbackActionTemplateId)

            if (!topicFeedbackActionTemplate) {
              topicFeedbackActionTemplate = await this.topicFeedbackActionTemplateRepositoryInstance.getTopicFeedbackActionTemplateById({
                instituteId: this.selectedInstitute,
                topicFeedbackActionTemplateId: topicFeedbackActions[i].topicFeedbackActionTemplateId
              })
              this.topicFeedbackActionTemplates.push(topicFeedbackActionTemplate)
            }

            const topicFeedbackAction = topicFeedbackActions[i].responses.find((response) => response.uIds.includes(this.userData.uId))

            topicFeedbackActions[i].topicFeedbackActionTemplate = topicFeedbackActionTemplate
            topicFeedbackActions[i].isFilled = topicFeedbackActions[i].responses.filter((response) => response.uIds.includes(this.userData.uId)).length > 0
            topicFeedbackActions[i].selectedAction = topicFeedbackAction ? topicFeedbackAction.actionId : undefined
            this.topicFeedbackActions.push(topicFeedbackActions[i])
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    openFillFeedbackDialogFun (item, isEditing) {
      this.isEditing = isEditing
      this.showFillTopicFeedbackDialog = true
      this.openFillFeedbackDialog = true
      this.selectedTopicFeedback = item
      this.selectedTopicFeedbackQuestions = this.selectedTopicFeedback.topicFeedbackTemplate
      this.selectedTopicFeedbackQuestions.questions.forEach((questionObj) => {
        this.filledFeedbackOptions[questionObj.questionId] = this.selectedTopicFeedback.responses[this.userData.uId] ? this.selectedTopicFeedback.responses[this.userData.uId][questionObj.questionId] : ''
      })
    },
    closeFeedbackDialog () {
      this.openFillFeedbackDialog = false
      this.selectedTopicFeedback = ''
      this.filledFeedbackOptions = {}
      this.isEditing = true
    },
    async submitFeedbackResponse () {
      const keys = Object.keys(this.filledFeedbackOptions)
      if (Object.values(this.filledFeedbackOptions).filter((elem) => elem).length === keys.length) {
        const promises = keys.map(
          (key) => this.topicFeedbackRepositoryInstance.submitFeedbackOfAStudent({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            topicFeedbackId: this.selectedTopicFeedback.topicFeedbackId,
            uId: this.userData.uId,
            answer: this.filledFeedbackOptions
          })
        )
        await Promise.allSettled(promises)
        const index = this.topicFeedbacks.findIndex((elem) => elem.topicFeedbackId === this.selectedTopicFeedback.topicFeedbackId)
        this.topicFeedbacks[index].isFilled = true
        this.closeFeedbackDialog()
        console.log('response has been submitted')
      } else {
        showStatus('Fill all questions', 1000, 'error', this)
      }
    },
    openFeedbackActionsDialog (item) {
      this.showFeedbackActionsDialog = true
      this.selectedTopicFeedback = item
      this.feedbackActionsOfSelectedFeedback = this.topicFeedbackActions.filter((elem) => elem.topicFeedbackId === this.selectedTopicFeedback.topicFeedbackId)
    },
    closeFeedbackActionsDialog () {
      this.showFeedbackActionsDialog = false
      this.selectedTopicFeedback = ''
      this.feedbackActionsOfSelectedFeedback = []
    },
    async submitFeedbackActionResponse (selectedFeedbackAction, selectedActionId) {
      this.refresh = false
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackId: this.selectedTopicFeedback.topicFeedbackId,
          topicFeedbackActionId: selectedFeedbackAction.topicFeedbackActionId,
          uId: this.userData.uId,
          actionId: selectedActionId
        }
        await this.topicFeedbackActionRepositoryInstance.submitActionByAStudentForATopicFeedbackAction(objToPush)
        const index = this.feedbackActionsOfSelectedFeedback.findIndex((elem) => elem.topicFeedbackActionId === selectedFeedbackAction.topicFeedbackActionId)
        this.feedbackActionsOfSelectedFeedback[index].selectedAction = selectedActionId
      } catch (err) {
        console.log(err)
        showStatus('Could not submit response', 1000, 'error', this)
      }
      this.refresh = true
    },
    selectOptionForFeedbackQuestion (question, option) {
      this.refresh = false
      this.filledFeedbackOptions[question.questionId] = option
      this.refresh = true
    },
    convertDateTime (item) {
      return convertDateTime(item)
    }
  }
}
</script>

<style src='./activityTopicFeedback.css'>
</style>
