import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Posts'

class Posts {
  constructor (context) {
    this.context = context
  }

  async createPost (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createPost, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }

  async getPosts (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getPosts, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.result
  }
}

export default Posts
