<template src='./activityNotifications.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import NotificationRepository from '../../Repository/Notification'
export default {
  name: 'activityNotifications',
  components: {
    inputContainer
  },
  data () {
    return {
      dataFechted: false,
      datesWiseNotifications: {},
      userData: null,
      selectedInstitute: '',
      selectedSemester: {},
      notifications: [],
      headers: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Description',
          value: 'description'
        }
      ]
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'My Notifications')
    this.notificationRepositoryInstance = new NotificationRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    console.log(this.userData, this.selectedInstitute)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.dataFechted = false
      this.notifications = []
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      console.log(objToPush)
      try {
        this.notifications = await this.notificationRepositoryInstance.getTodaysNotifications(objToPush)
      } catch (err) {
        console.log(err)
        this.notifications = []
      }
      await this.processNotifications()
    },
    async processNotifications () {
      this.notifications.forEach((notification) => {
        const dateString = notification.dateTime.slice(0, 10)
        if (!this.datesWiseNotifications[dateString]) {
          this.datesWiseNotifications[dateString] = []
        }
        this.datesWiseNotifications[dateString].push(notification)
      })
      this.dataFechted = true
    },
    milliToDate (milli) {
      const D = new Date(parseInt(milli))
      console.log(('0' + D.getDate()).slice(-2) + '/' + ('0' + (D.getMonth() + 1)).slice(-2) + '/' + D.getFullYear())
      return ('0' + D.getDate()).slice(-2) + '/' + ('0' + (D.getMonth() + 1)).slice(-2) + '/' + D.getFullYear()
    }
  }
}
</script>

<style src='./activityNotifications.css'>
</style>
