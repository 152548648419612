
import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Topic'

class TopicRepository {
  constructor (context) {
    this.context = context
  }

  async getTopicsOfADivisionOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfADivisionOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicRatingForASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicRatingForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicRating
  }

  async updateTopicRating (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateTopicRating, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicRating
  }

  async getTopicsOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }

  async getTopicsOfAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicsOfAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topics
  }
}

export default TopicRepository
