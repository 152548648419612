<template src="./activityStudyMaterial.html"></template>

<script>
import auth from "../../Services/Firebase/auth";
import SemesterRepository from "../../Repository/Semester";
import SubjectsRepository from "../../Repository/Subjects";
import TopicsRepository from "../../Repository/Topic";
import UserRepository from "../../Repository/User";
import StudyMaterialRepository from "../../Repository/StudyMaterial";
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import { convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import PostsRepository from "../../Repository/Posts";
import _ from "lodash";

var firestorage = null;
export default {
  name: "activityTimeline",
  components: {
    inputContainer
  },
  props: ["prop_subject", 'prop_groupData'],
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            // console.log("hello", downloadURL);
            const indexOfSlash = this.fileCurrentlyUploading.type.indexOf("/");
            this.downloadURLs.push({
              downloadURL: downloadURL,
              isMedia: ["image", "video"].includes(
                this.fileCurrentlyUploading.type
                  .substring(0, indexOfSlash)
                  .toLowerCase()
              )
            });
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              this.uploadPost();
            } else {
              this.progressUpload = 0;
              this.upload(this.uploadCount);
            }
          });
        }
      );
    }
  },
  data() {
    return {
      isEklavvyaHosting: false,
      filterSubject: "",
      filterTopic: "All",
      filterFileFormats: [],
      fileFormats: ["All", "Videos", "Images", "Documents"],
      filterTopicId: "all",
      filterFormat: "All",

      editingPostId: "",
      videoFiles: [],
      videoThumbnailFiles: [],
      imageFiles: [],
      documentFiles: [],

      subjects: [],
      selectedSubject: "",
      selectedSubjectId: "",
      topics: [],
      selectedTopic: "",
      selectedTopicId: "",
      postTitle: "",
      postDescription: "",
      videoFormats: ["mp4", "avi", "mpeg", "mov", "wmv"],
      imageFormats: ["jpg", "png"],
      documentFormats: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "svg", "ppt", "pptx"],
      posts: [],
      playingIndex: undefined,
      currentlyUploadingNumber: 0,
      currentUploadProgress: 0,
      uploadedVideoFiles: [],
      snackbar: false,
      message: "",

      isDownloadable: false,
      isMobileView: false,
      userData: {},
      files: [],
      files2: [],
      tempTitle: "",
      tempDescription: "",
      uploadTask: "",
      uploadEnd: false,
      uploading: false,
      showAddingDialog: false,
      showToStudents: false,
      showToFaculties: false,
      selectedDepartment: "",
      selectedDepartment2: "",
      departments: [],
      selectedCourseYear: "",
      courseYears: [],
      selectedDivision: "",
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      academicDataTree: {},
      isImportant: false,
      selectedFacultyCriteria: [],
      selectedStudentCriteria: [],
      fileCurrentlyUploading: "",
      watermarkName: '',
      watermarkLogo: '',
      watermarkUserName: '',
      isLoadingMaterial: false,
      isDocModalOpen: false,
      isStudyMaterialDownloadable: false,
      docLink: ''
    };
  },
  created() {
    // console.log('prop_groupData', this.prop_groupData)
    if (window.location.href.indexOf("eklavvya") > -1) {
      this.isEklavvyaHosting = true
    }
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.addEventListener("keydown", function (event) {
      if (
        (event.ctrlKey || event.metaKey) &&
        "cvxspwuaz".indexOf(event.key) !== -1
      ) {
        event.preventDefault();
      }
    }, false);
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.postsRepositoryInstance = new PostsRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.topicsRepositoryInstance = new TopicsRepository(this);
    this.studyMaterialRepositoryInstance = new StudyMaterialRepository(this);
    this.selectedInstitute = this.$store.getters[
      "instituteData/get_selectedInstitute"
    ];
    this.selectedSemester = this.$store.getters[
      "instituteData/get_selectedSemester"
    ];
    this.$store.commit("liveData/set_selectedActivityName", "Study Material");
    this.$store.commit('liveData/set_selectedActivityName', 'Study Material')
    this.userData = this.$store.getters["user/get_userData"];
    const tempInsData = this.$store.getters["instituteData/selectedInstitute"];
    this.watermarkUserName = `${this.userData.firstName} ${this.userData.lastName}`
    this.watermarkName = tempInsData.name
    this.watermarkLogo = tempInsData.logo
    // console.log(this.userData);

    // console.log("Prosp", this.prop_subject);
    if (this.prop_subject) {
      this.selectedSubjectId = this.prop_subject.subjectId;
      this.fetchTopics(this.prop_subject.subjectId);
    }

    this.getMasterDataTree();
    this.fetchData();
    document.addEventListener('visibilitychange', this.visibilityChange, false);
    window.addEventListener('blur', this.onBlur);
  },
  beforeDestroy() {
    document.removeEventListener('blur', () => { })
    document.removeEventListener('visibilitychange', () => { })
    document.removeEventListener('keydown', () => { })
    document.removeEventListener('contextmenu', () => { })
  },
  methods: {
    visibilityChange(_e) {
      const videoEls = document.querySelectorAll(".videoRef")
      if (document.hidden) {
        videoEls.forEach(el => el.pause())
      }
    },
    onBlur() {
      const videoEls = document.querySelectorAll(".videoRef")
      videoEls.forEach(el => el.pause())
    },
    async onFilterBySubject(subjectName) {
      this.filterSubject = subjectName;
      const selectedSubjectObj = this.subjects.filter(
        item => item.subjectName === subjectName
      );
      if (selectedSubjectObj.length > 0) {
        const { subjectId } = selectedSubjectObj[0];
        // console.log("Filter Subject", subjectId, subjectName);
        await this.fetchTopics(subjectId);
      }
    },
    async onFilterByFileFormat(fileFormat) {
      this.filterFileFormat = fileFormat;
      let mimeTypes = [];
      switch (fileFormat) {
        case "Videos": {
          mimeTypes = this.videoFormats;
          break;
        }
        case "Images": {
          mimeTypes = this.imageFormats;
          break;
        }
        case "Documents": {
          mimeTypes = this.documentFormats;
          break;
        }
        default: {
          mimeTypes = [];
        }
      }

      this.filterFileFormats = mimeTypes;
      const params = {};
      if (mimeTypes.length > 0) {
        params.mimeTypes = mimeTypes;
      }

      if (this.filterTopicId && this.filterTopicId !== "all") {
        params.topicId = this.filterTopicId;
      }
      await this.fetchPosts(params);
    },
    async onFilterByTopic(topicName) {
      this.filterTopic = topicName;
      const selectedTopicObj = this.topics.filter(
        item => item.topicName === topicName
      );
      if (selectedTopicObj.length > 0) {
        const { topicId } = selectedTopicObj[0];
        // console.log("Selected Topic", topicId, topicName);
        const params = {
          topicId
        };
        if (topicId === "all") {
          delete params.topicId;
          this.filterTopicId = "";
        } else {
          this.filterTopicId = topicId;
        }
        if (this.filterFileFormats.length > 0) {
          params.mimeTypes = this.filterFileFormats;
        }
        await this.fetchPosts(params);
      }
    },
    async fetchSubjects() {
      try {
        const subjects = this.$store.getters['mappings/get_subjectsMapping']
        this.subjects = Object.keys(subjects).map(subjectId => subjects[subjectId])
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTopics(subjectId) {
      try {
        let topics = await this.topicsRepositoryInstance.getTopicsOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId
        });
        // this.topics = topics;
        if (this.prop_groupData) {
          if (this.userData.division && this.userData.division !== '') {
            topics = topics.filter(data => (data.assignedGroupForSubject?.groupId === this.prop_groupData.groupId) || data.division === this.userData.division)
          } else {
            topics = topics.filter(data => (data.assignedGroupForSubject?.groupId === this.prop_groupData.groupId))
          }
        } else if (this.userData.division && this.userData.division !== '') {
          if (this.userData.batch && this.userData.batch !== '') {
            let dummy = []
            if (topics.length > 0) {
              dummy = topics.filter((data) => data.batch === this.userData.batch)
            }
            topics = dummy
          } else {
            topics = topics.filter((data) => data.division === this.userData.division)
          }
        }
        // console.log('this.topics', topics, this.userData, this.prop_groupData)
        const uniqueTopics = _.uniqBy(topics, "topicId").map(item => ({
          topicId: item.topicId,
          topicName: item.topicName
        }));
        this.topics = [{ topicId: "all", topicName: "All" }, ...uniqueTopics];
        // console.log("Unique Topics", uniqueTopics);
      } catch (error) {
        console.log(error);
      }
    },

    clearFields() {
      this.videoFiles = [];
      this.uploadedVideoFiles = [];
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.selectedTopicId = "";
      this.selectedTopic = "";
      this.selectedSubject = "";
      this.selectedSubjectId = "";
      this.isImportant = false;
      this.postTitle = "";
      this.postDescription = "";
    },
    async onDialogAddClick() {
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.uploading = true;
      // const nonEmptyFiles =
      //   this.imageFiles.filter(item => item.name).length +
      //   this.videoFiles.filter(item => item.name).length +
      //   this.documentFiles.filter(item => item.name).length;
      // console.log("Total Files", this.videoFiles[0]);
      // await this.uploadImages();
      this.uploadedVideoFiles = await this.uploadVideos();
      this.onDialogCancelClick();
      this.uploading = false;
    },

    async fetchPosts(_params = {}) {
      try {
        this.isStudyMaterialDownloadable = this.$store.getters["liveData/get_isStudyMaterialDownloadable"];
        // console.log('this.isStudyMaterialDownloadable', this.isStudyMaterialDownloadable);
        this.isLoadingMaterial = true
        const params = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          // uId: this.userData.uId,
          subjectId: this.selectedSubjectId,
          ..._params
        };
        const response = await this.studyMaterialRepositoryInstance.getStudyMaterialOfFaculty(
          params
        );
        if (this.userData.batch && this.userData.batch !== '') {
          let dummy = []
          // console.log('response.data', response.data);
          if (response.data.length > 0) {
            dummy = response.data.filter((data) => data.batch === this.userData.batch)
          }
          response.data = dummy
        }
        // console.log("Posts", response);
        // console.log("this.prop_groupData", this.prop_groupData);
        if (this.prop_groupData) {
          if (this.userData.division && this.userData.division !== '') {
            this.posts = response.data.filter(data => (data.assignedGroupForSubject?.groupId === this.prop_groupData.groupId) || data.division === this.userData.division)
          } else {
            this.posts = response.data.filter(data => (data.assignedGroupForSubject?.groupId === this.prop_groupData.groupId))
            // topics = topics.filter(data => (data.assignedGroupForSubject?.groupId === this.prop_groupData.groupId))
          }
        } else if (this.userData.division && this.userData.division !== '') {
            if (this.userData.batch && this.userData.batch !== '') {
              this.posts = response.data.filter((data) => (data.batch === this.userData.batch) || (!data.division && !data.batch));
            } else {
              this.posts = response.data.filter((data) => data.division === this.userData.division || !data.division)
          }
        } else {
          this.posts = response.data
        }
        // this.posts = response.data;
        // if (this.isStudyMaterialDownloadable === true) {
        //   this.posts.map((obj) => {
        //     obj.isDownloadable = true
        //   })
        // }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingMaterial = false
      }
    },
    async fetchData() {
      this.fetchSubjects();
      this.fetchPosts();
    },
    async getFullNamesOfUsers(firstIndex, lastIndex) {
      // console.log(firstIndex, lastIndex);
      try {
        const objToPush = {
          uIds: []
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.posts[i].postedById);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        );

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            elem => elem.uId === this.posts[i].postedById
          );
          this.posts[i].nameOfPostee = user ? user.fullName : "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getFullName(index) {
      try {
        this.posts[
          index
        ].nameOfPostee = await this.userRepositoryInstance.getFullNameOfUser({
          uId: this.posts[index].postedById
        });
      } catch (err) {
        console.log(err);
        this.posts[index].nameOfPostee = "";
      }
    },
    pushFiles() {
      // console.log(this.files);
      this.files2.forEach(file => {
        this.files.push(file);
      });
      this.files2 = [];
      this.showAddingDialog = false;
    },
    removeFile(i) {
      this.files = arrayOps.removeFromArray(this.files, i);
    },
    filesChanged(e) {
      console.log(e);
    },
    getFileName(url) {
      const decodedUrl = decodeURIComponent(url);
      const lastIndexOfDelimiter = decodedUrl.lastIndexOf("________");
      const indexOfQueryParams = decodedUrl.lastIndexOf("?alt");

      return decodedUrl.substring(
        lastIndexOfDelimiter + "________".length,
        indexOfQueryParams
      );
    },
    async getMasterDataTree() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        // console.log(objToPush);
        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        );
        this.constructAcademicDataTree(semestersList);
      } catch (err) {
        console.log(err);
      }
    },
    async constructAcademicDataTree(semestersList) {
      const academicDataTree = {};

      semestersList.forEach(sem => {
        if (!academicDataTree[sem.department]) {
          academicDataTree[sem.department] = {};
        }
        if (!academicDataTree[sem.department][sem.courseYear]) {
          academicDataTree[sem.department][sem.courseYear] = [];
        }
        academicDataTree[sem.department][sem.courseYear].push(sem.division);
        this.departments = ["All Departments"];
        this.departments = this.departments.concat(
          Object.keys(academicDataTree)
        );
        this.academicDataTree = academicDataTree;
      });
    },
    selectDepartment() {
      this.courseYears = [];
      this.selectedCourseYear = "";
      this.divisions = [];
      this.selectedDivision = "";
      this.courseYears = ["All Course Years"];
      if (this.academicDataTree[this.selectedDepartment]) {
        this.courseYears = this.courseYears.concat(
          Object.keys(this.academicDataTree[this.selectedDepartment])
        );
      }
    },
    selectCourseYear() {
      this.divisions = [];
      this.selectedDivision = "";
      this.divisions = ["All Divisions"];
      if (
        this.academicDataTree[this.selectedDepartment][this.selectedCourseYear]
      ) {
        this.divisions = this.divisions.concat(
          this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
          ]
        );
      }
    },
    dismissStudentCriteria() {
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addStudentCriteria() {
      if (
        this.selectedDepartment === "All Departments" ||
        this.selectedCourseYear === "All Course Years" ||
        this.selectedDivision === "All Divisions"
      ) {
        this.addAllStudentCriteria();
      } else {
        const criteriaToInsert = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        };
        if (
          this.selectedStudentCriteria.filter(
            elem =>
              elem.department === criteriaToInsert.department &&
              elem.courseYear === criteriaToInsert.courseYear &&
              elem.division === criteriaToInsert.division
          ).length === 0
        ) {
          this.selectedStudentCriteria.push(criteriaToInsert);
        }
      }
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addAllStudentCriteria() {
      if (this.selectedDepartment === "All Departments") {
        this.selectedStudentCriteria = [];
        Object.keys(this.academicDataTree).forEach(department => {
          Object.keys(this.academicDataTree[department]).forEach(courseYear => {
            this.academicDataTree[department][courseYear].forEach(division => {
              this.selectedStudentCriteria.push({
                department: department,
                courseYear: courseYear,
                division: division
              });
            });
          });
        });
      } else if (this.selectedCourseYear === "All Course Years") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem => elem.department !== this.selectedDepartment
        );
        Object.keys(this.academicDataTree[this.selectedDepartment]).forEach(
          courseYear => {
            this.academicDataTree[this.selectedDepartment][courseYear].forEach(
              division => {
                this.selectedStudentCriteria.push({
                  department: this.selectedDepartment,
                  courseYear: courseYear,
                  division: division
                });
              }
            );
          }
        );
      } else if (this.selectedDivision === "All Divisions") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem =>
            elem.department !== this.selectedDepartment ||
            elem.courseYear !== this.selectedCourseYear
        );
        this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
        ].forEach(division => {
          this.selectedStudentCriteria.push({
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: division
          });
        });
      }
    },
    // uploadFiles() {
    //   this.loading = true;
    //   this.downloadURLs = [];
    //   this.uploadCount = 0;
    //   console.log(this.files);
    //   if (this.files.length === 0) {
    //     this.uploadPost();
    //   } else {
    //     this.upload(this.uploadCount);
    //     // this.files.forEach(file => {
    //     //   this.upload(file)
    //     // })
    //   }
    // },
    upload(fileNumber) {
      // console.log("uploading");
      this.fileName = this.files[fileNumber].name;
      this.uploading = true;
      // console.log(this.fileName);
      this.fileCurrentlyUploading = this.files[fileNumber];
      this.uploadTask = firestorage
        .ref(
          "posts/" +
          this.userData.uId +
          "/" +
          new Date().valueOf().toString() +
          "________" +
          this.files[fileNumber].name
        )
        .put(this.files[fileNumber]);
    },
    async uploadPost() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        title: this.tempTitle,
        description: this.tempDescription,
        isImportant: this.isImportant,
        postedById: this.userData.uId,
        dateOfPost: convertToISTTime(new Date()).toISOString(),
        images: this.downloadURLs
          .filter(url => !url.isMedia)
          .map(url => url.downloadURL),
        otherMedia: this.downloadURLs
          .filter(url => url.isMedia)
          .map(url => url.downloadURL),
        criteria: {
          forFaculties: this.selectedFacultyCriteria.length > 0,
          forStudents: this.selectedStudentCriteria.length > 0,
          facultyCriteria: this.selectedFacultyCriteria,
          studentCriteria: this.selectedStudentCriteria
        }
      };
      // console.log(objToPush);
      try {
        await this.postsRepositoryInstance.createPost(objToPush);
        this.resetAddPost();
        this.fetchData();
      } catch (err) {
        console.log(err);
      }
      // console.log(objToPush);
    },
    getFileType(fileUrl) {
      const intermediatePath = fileUrl.split("?alt=media")[0];
      return intermediatePath.substring(
        intermediatePath.lastIndexOf("."),
        intermediatePath.length
      );
    },
    resetStudentCriteria() {
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedDivision = "";
    },
    dismissFacultyCriteria() {
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    addFacultyCriteria() {
      if (this.selectedDepartment2 === "All Departments") {
        this.selectedFacultyCriteria = [];
        Object.keys(this.academicDataTree).forEach(department => {
          this.selectedFacultyCriteria.push({
            department: department
          });
        });
      } else if (
        !this.selectedFacultyCriteria.includes(this.selectedDepartment2)
      ) {
        this.selectedFacultyCriteria.push({
          department: this.selectedDepartment2
        });
      }
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    removeStudentCriteria(index) {
      this.selectedStudentCriteria = arrayOps.removeFromArray(
        this.selectedStudentCriteria,
        index
      );
    },
    removeFacultyCriteria(index) {
      this.selectedFacultyCriteria = arrayOps.removeFromArray(
        this.selectedFacultyCriteria,
        index
      );
    },
    resetAddPost() {
      this.tempTitle = "";
      this.tempDescription = "";
      this.showToStudents = false;
      this.showToFaculties = false;
      this.selectedDepartment = "";
      this.selectedDepartment2 = "";
      this.departments = [];
      this.selectedCourseYear = "";
      this.courseYears = [];
      this.selectedDivision = "";
      this.divisions = [];
      this.isImportant = false;
      this.selectedFacultyCriteria = [];
      this.selectedStudentCriteria = [];
    },
    openFile(attachment) {
      if (attachment.mimeType.includes('pdf')) {
        const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
        PDF_VIEWER.searchParams.set('pdf', attachment.url)
        this.docLink = PDF_VIEWER
        this.isDocModalOpen = true
      } else {
        window.open(attachment.url, '_blank')
      }
    }
  }
};
</script>

<style src="./activityStudyMaterial.css"></style>
