<template src="./activityQuizResult.html"></template>
<script>
// import SubjectsRepository from "../../Repository/Subjects";
import moment from "moment";
import 'moment-duration-format';

export default {
    name: "activityQuizResult",
    props: ["prop_courseDetails", "prop_course", "prop_QuizeCourse", "prop_indexSelectedSubSection", "prop_indexOfSelectedSection", "prop_subject"],
    data() {
        return {
            solutionFileUrl: [],
            imageSolutionDialog: false,
            queForImageDialog: '',
            ansForImageDialog: '',
            ansForImageLink: '',
            isMobileView: false,
            isLoading: false,
            course: {},
            courseSections: [],
            subSections: [],
            studentAns: '',
            secondCount: 0,
            counter: 0,
            total: 0,
            obtainedMarks: 0,
            attemptNo: 1,
            correct: '',
            wrong: '',
            count: 0,
            timeTaken: 0,
            T: 0,
            time: 0,
            totalMarks: 0,
            timeTakenToQuiz: '',
            attemptNames: [],
            studentMarks: [],
            timerCount: this.prop_course.durationOfquiz * 60000,
            indexSelectedSubSection: this.prop_indexSelectedSubSection,
            indexOfSelectedSection: this.prop_indexOfSelectedSection
        }
    },
    created() {
        window.scroll(0, 0)
        this.course = this.prop_course;
        if (this.course.solutionFile && typeof this.course.solutionFile[0] === 'object') {
            this.course.solutionFile = [this.course.solutionFile[0].url]
        }
        this.prop_course.studentAnswers.map((obj, i) => {
            this.attemptNames.push({ name: `Attempt No ${i + 1}`, number: i })
        })
        this.courseName = this.prop_course.courseName
        this.$store.commit("liveData/set_selectedActivityName", this.prop_course.courseName);
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.courseSections = this.$store.getters["liveData/get_courseSections"]
        this.getTotalMarks()
        this.calculateAttempt(1)
    },
    methods: {
        showImageOfSolution(item) {
            console.log(item)
            this.queForImageDialog = item.question
            this.ansForImageDialog = item.answer
            this.ansForImageLink = []
            // this.ansForImageLink = item.imageForSolution[0].url
            this.course.questions.map((data) => {
                if (item.answer === data.answer) {
                    if (data.imageForSolution && data.imageForSolution.length > 0) {
                        this.ansForImageLink = data.imageForSolution[0].url || data.imageForSolution[0]
                    }
                }
            })
            this.imageSolutionDialog = true
        },
        calculateAttempt(i) {
            if (this.course.studentAnswers[i - 1]) {
                this.getTimeTaken(i - 1)
                this.getStudentMarks(i - 1)
                this.tempMarks(i - 1)
                this.getData(i - 1)
                this.correctAns(i - 1)
            } else {
                alert('Attempt Not Given')
                this.studentMarks = []
                this.obtainedMarks = 0
                this.count = 0
                this.time = 0
            }
        },
        getTimeTaken(i) {
            this.timeTaken = this.course.studentAnswers[i].timeTaken
            // var minutes = Math.floor(this.timeTaken / 60000);
            // var seconds = ((this.timeTaken % 60000) / 1000).toFixed(0);
            // this.time = minutes + ":" + (seconds < 10 ? '0' : '') + seconds
            console.log('this.timeTaken', this.timeTaken);
            this.timeTakenToQuiz = moment.duration(this.timeTaken, "seconds").format("m:ss")
            console.log('this.timeTakenToQuiz', this.timeTakenToQuiz);
        },
        tempMarks(i) {
            this.course.studentAnswers[i].answers.map((obj) => {
                this.total = parseInt(obj.marks)
            })
        },
        getTotalMarks() {
            this.course.questions.map((obj) => {
                if (!isNaN(parseInt(obj.marks))) {
                    this.totalMarks += parseInt(obj.marks)
                }
            })
        },
        getData(i) {
            this.studentMarks = this.course.studentAnswers[i].answers
        },
        goBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseMaterialRevamped2",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails,
                        prop_subject: this.prop_subject
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseMaterialRevamped",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails,
                        prop_subject: this.prop_subject
                    }
                });
            }
        },
        getStudentMarks(i) {
            this.obtainedMarks = this.course.studentAnswers[i].studentMarks;
        },
        correctAns(i) {
            this.count = 0
            this.course.studentAnswers[i].answers.map((obj) => {
                if (obj.studentTempAnswers === obj.answer) {
                    this.count += 1
                }
            })
        }
    }
}
</script>
<style scoped src="./activityQuizResult.css"></style>
