import showStatus from '../../NetworkManager/showStatus'

export function downloadFileViaUrl(
  url,
  filename,
  successMessage,
  errorMessage,
  thisObject
) {
  if (!successMessage) successMessage = "File Downloaded Successfully.";
  if (!errorMessage) errorMessage = "Error downloading the file.";

  if (url && filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        showStatus(successMessage, 2000, "success", thisObject);
      })
      .catch((error) => {
        console.error("error", error);
        showStatus(errorMessage, 7000, "error", thisObject);
      });
  }
}
