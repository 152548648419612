<template src="./activityFillFeedbackForm.html"></template>
<script>
// import auth from '../../../Services/Firebase/auth'
// import state from '../../../Store/Modules/liveData'
import networkManager from '../../../NetworkManager/index'
// import v3 from '../../../NetworkManager/apiV3'
import showStatus from '../../../NetworkManager/showStatus'
import PortalFooter from '../../../Components/PortalFooter'
import inputContainer from '../../../Components/inputContainer'

export default {
    name: 'activityFillFeedbackForm',
    props: ["formId"],
    components: {
        PortalFooter,
        inputContainer
    },
    data() {
        return {
            loading: false,
            isMobileView: false,
            userData: null,
            selectedInstitute: '',
            selectedSemester: '',
            formTitle: '',
            dueDate: '',
            cbIsNBACriteriaAttainment: '',
            selectedAns: '',
            questionList: [],
            qOptions: [],
            response: []
        }
    },
    created() {
        // this.uId = this.$store.getters['user/get_userData'].uId
        this.selectedTopic = this.$store.getters['liveData/get_selectedTopic']

        this.$store.commit('liveData/set_selectedActivityName', 'Feedback Form')

        this.isMobileView = this.$store.getters['liveData/get_isMobileView']

        this.userData = this.$store.getters['user/get_userData']
        this.selectedInstitute = this.$store.getters[
            'instituteData/get_selectedInstitute'
        ]
        this.selectedSemester = this.$store.getters[
            'instituteData/get_selectedSemester'
        ]

        this.getFeedbackFormDetails()
    },
    methods: {
        activity() {
            this.$tours['activityFillFeedbackForm'].start()
        },
        gotoFormListActivity() {
            if (this.isMobileView) {
                this.$router.push({
                    name: 'activityFeedbackList2'
                })
            } else {
                this.$router.push({
                    name: 'activityFeedbackList'
                })
            }
        },
        async getFeedbackFormDetails() {
            try {
                const res = await networkManager.getRequest('/feedbackWithNBAAttainment/getFeedbackByFeedbackID', {
                    feedbackId: this.formId
                })
                const data = await res.feedback
                this.formTitle = data[0].formTitle
                this.dueDate = data[0].endDate
                this.cbIsNBACriteriaAttainment = data[0].isNBACriteriaAttainment
                if (data[0].isNBACriteriaAttainment) {
                    data[0].questions.map((item, index) => {
                        this.response.push({ question: item.question, options: data[0].nbaAttainmentLevels })
                    })
                } else {
                    this.response = data[0].questions
                }
            } catch (err) {
                showStatus(err, 6000, 'error', this)
            }
        },
        async submitForm() {
            let flag = false;
            this.response.map((item, index) => {
                if (!item.answer) {
                    flag = true;
                }
            })
            if (!flag) {
                //Submit Feedback data
                this.response.map((item, index) => {
                    if (!this.cbIsNBACriteriaAttainment) {
                        if (item.questionType === "Multiple Choice Question") {
                            delete item.options
                        }
                    } else {
                        delete item.options
                    }
                })
                try {
                    const res = await networkManager.postRequest('/feedbackWithNBAAttainment/saveResponse', {
                        feedbackFormId: this.formId,
                        userId: this.userData.uId,
                        studentResponse: this.response
                    })
                    await res
                    this.gotoFormListActivity()
                    showStatus("Form submitted Successfully!!", 6000, 'success', this)
                } catch (err) {
                    showStatus(err, 6000, 'error', this)
                }
            } else {
                alert("Fill blank fields")
                showStatus("Fill blank fields", 6000, 'error', this)
            }
        },
        getCurrentDate() {
            const date = new Date();

            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
    }
}
</script>
<style scoped src="./activityFillFeedbackForm.css"></style>
