export default {
  updateCourseInfo: "/courses/updateCourseInfo",
  getSingleCourse: "/courses/getSingleCourse",
  getSignedUrl: "/spaces/presigned_url",
  createStudentCourse: "/courses/createStudentCourse",
  getSingleStudentCourse: "/courses/getSingleStudentCourse",
  getAllCoursesInfoOfSingleStudent: "/courses/getAllCoursesInfoOfSingleStudent",
  getAllCoursesInfoOfInstitute: "/courses/getAllCoursesInfoOfInstitute",
  getAllMediaLibraryByInstitute: "/mediaLibrary/getAllMediaLibraryByInstitute",
  getSyncCoursesDataOfSingleStudent: "/courses/syncCoursesDataOfSingleStudent"
};
