<template src="./activityAttendanceAnalysis.html"></template>

<script>
import ApexCharts from "apexcharts";
import { subjectTypes } from "../../Constants/Utils/Statics";
// import { convertDate, convertTime } from "../../Services/Utils/DateTimeFormatter";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import LectureRepository from "../../Repository/lectures";
import SubjectsRepository from "../../Repository/Subjects";
import TopicRepository from "../../Repository/Topic";
import TopicFeedbackRepository from "../../Repository/TopicFeedback";
// import moment from "moment";
import Vue from "vue";
import {
  Vuetify,
  VApp,
  VCard,
  Vslidegroup,
  Vrating
  /* other imports ... */
} from "vuetify";
import {
  convertDate,
  convertTime
} from "../../Services/Utils/DateTimeFormatter";
export default {
  name: "activityAttendanceAnalysis",
  components: {
    inputContainer
  },
  data() {
    return {
      topicsData: [],
      subjectForAttendanceAnalysis: [],
      selectedSubjectTypeForAttendance: '',
      headersForLectDetails: [
        {
          text: 'Lecture Date',
          value: 'dateTime'
        },
        {
          text: 'Lecture Time',
          value: 'time'
        },
        {
          text: 'Attendance',
          value: 'attendance'
        }
      ],
      detailsOfAttendance: false,
      allLectureInfoForSubject: [],
      allDetailsOfSubjects: [],
      allFilteredLectureOfDiv: [],
      allInfoSelectedSubject: [],
      lecturesList: [],
      topicFeedbacks: [],
      topicDetails: [],
      selectedFeedback: {},
      totalPresent: 0,
      totalAbsent: 0,
      isMobileView: false,
      justify: "space-around",
      defaulterLimit: 75,
      showFeedbackDialogModel: false,
      loading: false,
      userData: {},
      topicIds: [],
      topicsMapping: {},
      attendanceAnalysis: {},
      selectedInstitute: "",
      selectedSemester: {},
      items: [],
      selectedTopicId: "",
      questions: [],
      lectureIds: [],
      tempAnswers: [],
      headers: [
        {
          text: "Topic Name",
          value: "topicName",
          width: "30%"
        },
        {
          text: "Date",
          value: "date",
          width: "30%"
        },
        {
          text: "Status",
          value: "status",
          width: "30%"
        },
        {
          text: "",
          value: "action",
          width: "20%"
        }
      ],
      subjectsMapping: {},
      subjectTypeWiseSubjects: {},
      subjectNamestoIds: {},
      selectedSubjectType: "Normal Theory",
      subjectTypes: subjectTypes,
      subjects: [],
      subjectIds: [],
      selectedSubjectString: "",
      selectedSubject: {},
      isDefaulter: false,
      totalAttendedLectures: "0/0",
      refreshed: false,
      chartOptions: {
        colors: ["#050992", "#ff0000"],
        plotOptions: {
          pie: {
            customScale: 0.8,
            donut: {
              size: "45%",
              labels: {
                show: true
              }
            }
          }
        },
        series: [100, 55],
        chart: {
          width: 400,
          type: "donut"
        },
        labels: ["Present", "Absent"],
        legend: {
          position: "right",
          offsetY: 160
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      barOptions: {
        series: [
          {
            name: "Attendance",
            data: []
          }
        ],
        chart: {
          type: "bar",
          height: 257
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "70%",
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 74,
                  color: "#ff0000"
                },
                {
                  from: 75,
                  to: 89,
                  color: "#050992"
                },
                {
                  from: 90,
                  to: 100,
                  color: "#11bc7d"
                }
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      }
    };
  },
  created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.topicRepositoryInstance = new TopicRepository(this);
    subjectTypes.forEach((subjectType) => {
      this.subjectTypeWiseSubjects[subjectType] = [];
    });
    this.userData = this.$store.getters["user/get_userData"];
    console.log(this.userData);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this);
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    console.log('this.subjectsMapping', this.subjectsMapping);
    this.fetchData();
    this.fetchDataNew();
  },
  mounted() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      "Attendance Analysis"
    );
  },
  methods: {
    subjectChangeAttendance() {
      this.allInfoSelectedSubject = []
      this.allDetailsOfSubjects.map((data) => {
        if (data.subjectName === this.selectedSubjectTypeForAttendance) {
          this.allInfoSelectedSubject = data
        }
      })
    },
    openDetailsAttendance(sub) {
      this.detailsOfAttendance = true
      this.allLectureInfoForSubject = []
      this.allLectureInfoForSubject = sub
    },
    calculateAnalysisForAllSubject(lecArr) {
      this.allDetailsOfSubjects = []
      const subjectId = []
      lecArr.map((lec) => {
        if (!subjectId.includes(lec.subjectId)) {
          subjectId.push(lec.subjectId)
        }
      })
      console.log('subjectId', subjectId);
      subjectId.map((id) => {
        if (!this.subjectForAttendanceAnalysis.includes(this.subjectsMapping[id].subjectName)) {
          this.subjectForAttendanceAnalysis.push(this.subjectsMapping[id].subjectName)
        }
        const obj = {
          subjectId: id,
          subjectName: this.subjectsMapping[id].subjectName,
          subjectType: this.subjectsMapping[id].subjectType,
          allLecture: [],
          totalLect: 0,
          attendedLec: 0
        }
        lecArr.map((lec) => {
          if (id === lec.subjectId) {
            obj.totalLect++
            obj.allLecture.push(lec)
            if (lec.present.includes(this.userData.uId)) {
              obj.attendedLec++;
            }
          }
        })
        obj.totalPercentage = ((obj.attendedLec / obj.totalLect) * 100).toFixed(2)
        this.allDetailsOfSubjects.push(obj)
      })
      console.log('this.allDetailsOfSubjects', this.allDetailsOfSubjects);
      this.chartDataNew()
    },
    fake() {
      console.log('', Vue, Vuetify, VApp, VCard, Vslidegroup, Vrating);
    },
    async fetchDataNew() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.userData.department,
        courseYear: this.userData.courseYear,
        division: this.userData.division
      };
      try {
        // this.attendanceAnalysis = []
        const attendanceAnalysis = await this.lectureRepositoryInstance.allLectureOfDiv(objToPush);
        this.allFilteredLectureOfDiv = []
        this.allFilteredLectureOfDiv = attendanceAnalysis.filter((data) => {
          if (this.userData.subjects.includes(data.subjectId) && (data.present.includes(this.userData.uId) || data.absent.includes(this.userData.uId))) {
            return data
          }
        })
        if (this.userData.batch && this.userData.batch !== '') {
          let dummy = []
          if (this.allFilteredLectureOfDiv.length > 0) {
            dummy = this.allFilteredLectureOfDiv.filter((data) => data.batch === this.userData.batch)
          }
          this.allFilteredLectureOfDiv = dummy
        }
        this.calculateAnalysisForAllSubject(this.allFilteredLectureOfDiv)
        // console.log('this.allFilteredLectureOfDiv', this.allFilteredLectureOfDiv);
        // this.userData.subjects
      } catch (err) {
        this.attendanceAnalysis = [];
        console.log(err);
      }
    },
    async chartDataNew() {
      document.getElementById("chart").innerHTML = "<h1>loading</h1>";
      this.totalPresent = 0;
      this.totalAbsent = 0;
      let allLect = 0
      this.allDetailsOfSubjects.map((data) => {
        this.totalPresent = this.totalPresent + data.attendedLec
        allLect = allLect + data.totalLect
      })
      this.totalAbsent = allLect - this.totalPresent

      document.getElementById("chart").innerHTML = "";
      this.chartOptions.series = [this.totalPresent, this.totalAbsent];
      this.isDefaulter =
        (this.totalPresent / (this.totalAbsent + this.totalPresent)) * 100 <
        this.defaulterLimit;
      var chart = new ApexCharts(
        document.getElementById("chart"),
        this.chartOptions
      );
      chart.render();
    },
    async fetchData() {
      try {
        // const objToPush = {
        //   instituteId: this.selectedInstitute,
        //   semId: this.selectedSemester.semId,
        //   uId: this.userData.uId
        // };
        // this.subjectIds =
        //   (await this.semesterUserRepositoryInstance.getSubjectsOfASemesterUser(
        //     objToPush
        //   )) || [];
        // console.log('this.subjectsMapping', this.subjectsMapping, this.subjectIds);
        this.subjectIds = Object.keys(this.subjectsMapping)
        for (let i = 0; i < this.subjectIds.length; i++) {
          const subjectId = this.subjectIds[i];
          // if (!this.subjectsMapping[subjectId]) {
          //   try {
          //     const subject =
          //       await this.subjectsRepositoryInstance.getDetailsOfASubject({
          //         instituteId: this.selectedInstitute,
          //         semId: this.selectedSemester.semId,
          //         subjectId: subjectId
          //       });
          //     this.subjectsMapping[subject.subjectId] = subject;
          //     this.$store.commit("mappings/add_subjectsMapping", subject);
          //   } catch (err) {
          //     console.log(err);
          //   }
          // }
          if (this.subjectsMapping[subjectId]) {
            if (
              this.subjectsMapping[subjectId].subjectType ===
              this.selectedSubjectType
            ) {
              this.barOptions.xaxis.categories.push(
                this.subjectsMapping[subjectId].subjectName +
                " | " +
                this.subjectsMapping[subjectId].subjectType
              );
            }

            this.subjectTypeWiseSubjects[
              this.subjectsMapping[subjectId].subjectType
            ].push(subjectId);
            this.subjects.push(
              this.subjectsMapping[subjectId].subjectName +
              " | " +
              this.subjectsMapping[subjectId].subjectType
            );
            this.subjectNamestoIds[
              this.subjectsMapping[subjectId].subjectName +
              " | " +
              this.subjectsMapping[subjectId].subjectType
            ] = subjectId;
          }
        }
        if (!this.selectedSubjectString) {
          this.selectedSubjectString = this.subjects[0];
        }
      } catch (err) {
        console.error(err);
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          batch: this.userData.batch
        };
        try {
          this.attendanceAnalysis = []
          const attendanceAnalysis =
            await this.lectureRepositoryInstance.getAttendanceAnalysisForAStudent(
              objToPush
            );
          attendanceAnalysis.map((obj) => {
            if (this.subjectIds.includes(obj.subjectId)) {
              this.attendanceAnalysis.push(obj)
            }
          }
          )
        } catch (err) {
          this.attendanceAnalysis = [];
          console.log(err);
        }

        for (let i = 0; i < this.attendanceAnalysis.length; i++) {
          const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
            this.attendanceAnalysis[i].subjectId
          );

          if (indexOfSubject > -1) {
            this.barOptions.series[indexOfSubject].data =
              this.attendanceAnalysis[i].totalConducted > 0
                ? (
                  (this.attendanceAnalysis[i].totalAttended /
                    this.attendanceAnalysis[i].totalConducted) *
                  100
                ).toFixed(0)
                : 0;
          } else {
          }
        }
      } catch (err) {
        console.log(err);
      }

      this.selectSubject();
      // await this.setBarOptions();

      // this.renderCharts()
    },
    // renderCharts () {
    //   this.chart = new ApexCharts(document.getElementById('chart'), this.chartOptions)
    //   this.chart.render()
    //   this.bar = new ApexCharts(document.getElementById('bar'), this.barOptions)
    //   this.bar.render()
    // },
    // async selectSubjectTypeForBarGraph () {
    //   for (let i = 0; i < this.subjectTypeWiseSubjects[this.selectedSubjectType].length; i++) {
    //     const subjectId = this.subjectTypeWiseSubjects[this.selectedSubjectType][i]
    //     if (this.subjectsMapping[subjectId] && this.subjectsMapping[subjectId]) {
    //       this.barOptions.xaxis.categories.push(this.subjectsMapping[subjectId].subjectName + ' | ' + subjectTypes[this.subjectsMapping[subjectId].subjectType])
    //     }
    //   }
    //   this.renderCharts()
    // },
    async setBarOptions() {
      document.getElementById("chart").innerHTML = "<h1>loading</h1>";
      document.getElementById("bar").innerHTML = "<h1>loading</h1>";
      this.barOptions.xaxis.categories = [];
      this.barOptions.series[0].data = [];
      for (let i = 0; i < this.subjects.length; i++) {
        const subjectType = this.subjects[i].split(" | ")[1];
        if (this.selectedSubjectType === subjectType) {
          this.barOptions.xaxis.categories.push(this.subjects[i]);
        }
      }

      for (let i = 0; i < this.barOptions.xaxis.categories.length; i++) {
        this.barOptions.series[0].data[i] = 0;
      }
      this.totalPresent = 0;
      this.totalAbsent = 0;
      for (let i = 0; i < this.attendanceAnalysis.length; i++) {
        // console.log('this.attendanceAnalysis[i].subjectId', this.attendanceAnalysis[i].subjectId);
        // console.log('this.subjectsMapping', this.subjectsMapping);
        this.totalPresent += parseInt(this.attendanceAnalysis[i].totalAttended);
        this.totalAbsent +=
          parseInt(this.attendanceAnalysis[i].totalConducted) -
          parseInt(this.attendanceAnalysis[i].totalAttended);
        const indexOfSubject = this.barOptions.xaxis.categories.indexOf(
          this.subjectsMapping[this.attendanceAnalysis[i].subjectId].subjectName +
          " | " +
          this.subjectsMapping[this.attendanceAnalysis[i].subjectId]
            .subjectType
        );

        if (indexOfSubject > -1) {
          this.barOptions.series[0].data[indexOfSubject] =
            this.attendanceAnalysis[i].totalConducted > 0
              ? (
                (this.attendanceAnalysis[i].totalAttended /
                  this.attendanceAnalysis[i].totalConducted) *
                100
              ).toFixed(0)
              : 0;
        }
      }
      document.getElementById("chart").innerHTML = "";
      document.getElementById("bar").innerHTML = "";

      this.chartOptions.series = [this.totalPresent, this.totalAbsent];
      this.isDefaulter =
        (this.totalPresent / (this.totalAbsent + this.totalPresent)) * 100 <
        this.defaulterLimit;
      var chart = new ApexCharts(
        document.getElementById("chart"),
        this.chartOptions
      );
      chart.render();
      var bar = new ApexCharts(document.getElementById("bar"), this.barOptions);
      bar.render();
    },
    async showFeedbackDialog(topic) {
      this.selectedTopicId = topic.topicId;
      // Call APi to get feedback questions for the topic
      const topicFeedbacks = this.topicFeedbacks.filter(
        (item) => item.topicId === topic.topicId
      );
      if (topicFeedbacks.length > 0) {
        const feedback = topicFeedbacks[0];
        this.selectedFeedback = feedback;
        this.questions = feedback.questions;
        this.tempAnswers = [];
        this.refreshed = true;
        this.questions.forEach((que, i) => {
          this.tempAnswers[i] = -1;
        });
        this.showFeedbackDialogModel = true;
      } else {
        this.showFeedbackDialogModel = false;
      }
    },
    async submitFeedback() {
      this.loading = true;
      const promises = [];
      // console.log('tempAnswers', this.tempAnswers);
      this.questions.forEach((question, index) => {
        promises.push({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackId: this.selectedFeedback.topicFeedbackId,
          uId: this.userData.uId,
          questionId: index.toString(),
          rating: this.tempAnswers[index].toString()
        });
      });
      // console.log('promises', promises);
      for (let i = 0; i < promises.length; i++) {
        await this.topicFeedbackRepositoryInstance.submitFeedbackOfAStudent(
          promises[i]
        );
      }
      const response = await Promise.all(promises);
      console.log('response', response);
      this.showFeedbackDialogModel = false;
      this.loading = false;
      await this.selectSubject()
    },
    async selectSubject() {
      // console.log('selectedSubjectString', this.selectedSubjectString);
      this.loading = true;
      const subjectAttendance = this.attendanceAnalysis.find(
        (elem) =>
          elem.subjectId === this.subjectNamestoIds[this.selectedSubjectString]
      );

      if (subjectAttendance) {
        this.totalAttendedLectures =
          subjectAttendance.totalAttended +
          "/" +
          subjectAttendance.totalConducted;
      }
      this.selectedSubject =
        this.subjectsMapping[
        this.subjectNamestoIds[this.selectedSubjectString]
        ];
      this.lectureIds = []
      this.topicIds = []
      this.topicsData = []
      this.lecturesList = {}
      // Call APi to get topics for the subject
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.userData.department,
        courseYear: this.userData.courseYear,
        division: this.userData.division,
        batch: this.userData.batch,
        subjectId: this.selectedSubject.subjectId,
        uId: this.userData.uId
      };
      const tempTopics = await this.topicRepositoryInstance.getTopicsOfAStudent(objToPush)
      tempTopics.forEach(topic => {
        this.lectureIds.push(topic.lectureId)
      });
      const objTogetLecturesBySubject = {
        lectures: this.lectureIds
      }
      this.lecturesList = await this.lectureRepositoryInstance.getLecturesBySubject(
        objTogetLecturesBySubject
      )
      this.topicDetails = tempTopics.reduce((group, topic) => {
        const { topicId } = topic;
        this.topicIds.push(topicId);
        group[topicId] = group[topicId] ?? [];
        group[topicId].push(topic);
        return group;
      }, {});
      this.topicFeedbacks = await this.topicFeedbackRepositoryInstance.getAllTopicFeedbacksByTopicId({ topicIds: this.topicIds })
      for (const [key, value] of Object.entries(this.topicDetails)) {
        const lectures = []
        if (key !== undefined) {
          value.map((singleTopic) => {
            this.lecturesList.map((lecture) => {
              if (singleTopic.lectureId === lecture.lectureId) {
                lectures.push(lecture)
              }
            })
          })
          let avg = 0
          this.topicFeedbacks.map((topicFeedback) => {
            value[0].isFeedbackAvailable = false
            if (topicFeedback.topicId === key && topicFeedback.responses !== undefined) {
              let count = 0;
              value[0].isFeedbackAvailable = true
              const feedbackKeyCount = []
              for (const [feedbackKey, feedbackValue] of Object.entries(topicFeedback.responses)) {
                feedbackKeyCount.push(feedbackKey)
                if (feedbackValue[this.userData.uId]) {
                  count = parseInt(count) + parseInt(feedbackValue[this.userData.uId])
                  avg = parseInt(count) / parseInt(feedbackKeyCount.length);
                }
              }
            }
          })
          value[0].studentAvgRating = Math.round(avg)
          value[0].lectures = lectures;
          this.topicsData.push(value[0])
        }
      }
      this.topicsData.map((topic) => {
        let presentCount = 0;
        topic.lectures.map((lecture) => {
          if (lecture.present.includes(this.userData.uId)) {
            presentCount += 1;
          }
        })
        if (presentCount !== 0 && topic.lectures.length === presentCount && topic.isFeedbackAvailable === false) {
          topic.topicFeedbackEnable = true
        } else {
          topic.topicFeedbackEnable = false
        }
      })
      this.loading = false;
    },
    dowloadLectureVideo(link) {
      window.open(link, "_blank");
    },
    convertDate(dateTime) {
      return convertDate(dateTime);
    },
    convertTime(dateTime) {
      return convertTime(dateTime);
    }
  }
};
</script>

<style src="./activityAttendanceAnalysis.css"></style>
