<template src="./activityCourseCertificate.html"></template>
<script>
import UserRepository from "../../Repository/User";
import InstituteRepository from "../../Repository/Institute";
import CourseRepository from "../../Repository/Course";
import html2pdf from 'html2pdf.js'
export default {
    name: "activityCourseMaterial",
    props: ["prop_course"],
    data() {
        return {
            isMobileView: false,
            userDetails: '',
            instituteLogoLink: '',
            instituteDetails: {},
            selectedSemester: {}
        }
    },
    created() {
        this.courseDetails = this.prop_course
        console.log('this.courseDetails', this.courseDetails);
        this.courseName = this.prop_course.courseName
        this.$store.commit("liveData/set_selectedActivityName", this.prop_course.courseName);
        this.selectedSemester = this.$store.getters[
            "instituteData/get_selectedSemester"
        ];
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.UserRepositoryInstance = new UserRepository(this);
        this.InstituteRepositoryInstance = new InstituteRepository(this);
        this.CourseRepositoryInstance = new CourseRepository(this);
        this.fetchData();
        this.makeCertified()
    },
    methods: {
        async fetchData() {
            try {
                const objToPush = {
                    uId: this.selectedSemester.uId
                }
                console.log('objToPush', objToPush);
                const userDetails = await this.UserRepositoryInstance.getFullNameOfUser(
                    objToPush
                );
                this.userDetails = userDetails
                console.log('this.userDetails', this.userDetails);
                this.instituteData();
            } catch (err) {
                console.log(err)
            }
        },
        async makeCertified() {
            const objToPush = {
                instituteId: this.selectedSemester.instituteId,
                semId: this.selectedSemester.semId,
                department: this.selectedSemester.department,
                courseYear: this.selectedSemester.courseYear,
                courseId: this.courseDetails.courseId,
                uId: this.selectedSemester.uId,
                isCertified: true
            }
            console.log('objToPush', objToPush);
            await this.CourseRepositoryInstance.createStudentCourse(objToPush)
        },
        goToBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseMaterialRevamped2",
                    params: {
                        prop_courseDetails: this.prop_course
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseMaterialRevamped",
                    params: {
                        prop_courseDetails: this.prop_course
                    }
                });
            }
        },
        async instituteData() {
            try {
                const objToPush = {
                    uId: this.selectedSemester.uId,
                    instituteId: this.selectedSemester.instituteId
                }
                console.log('objToPush', objToPush);
                const instituteDetails = await this.InstituteRepositoryInstance.getInstituteInformation(
                    objToPush
                );
                this.instituteDetails = instituteDetails
                console.log('instituteDetails', instituteDetails);
            } catch (err) {
                console.log(err)
            }
        },
        downloadPDF() {
            if (this.isMobileView) {
                html2pdf(this.$refs.courseCertificate, {
                    margin: [-0.5, 1.3],
                    filename: 'certificate.pdf',
                    enableLinks: true,
                    image: { type: 'jpg', quality: 0.1 },
                    html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
                    jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
                })
            } else {
                html2pdf(this.$refs.courseCertificate, {
                    margin: [0.5, 1],
                    filename: 'certificate.pdf',
                    enableLinks: true,
                    image: { type: 'jpg', quality: 0.1 },
                    html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
                    jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
                })
            }
        }
    }
}
</script>
<style scoped src="./activityCourseCertificate.css">

</style>
