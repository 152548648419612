import * as XLSX from 'xlsx/xlsx.mjs'

export function createExcelThroughJson(data, fileName) {
    // Create a new workbook
    const wb = XLSX.utils.book_new()

    // Add a new worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet(data)

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate the Excel file and download it
    if (!fileName) {
      XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
        origin: -1
      })
      XLSX.writeFile(wb, 'table_Data.xlsx')
    } else {
      XLSX.writeFile(wb, `${fileName}.xlsx`)
    }

    // // Extract Data (create a workbook object from the table)
    // var workbook = XLSX.utils.table_to_book(table_elt);
    // console.log('workbook', workbook);
    // // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    // XLSX.writeFile(workbook, "table_Data.xlsx");
  }
