import firebase from 'firebase'
import { store } from '../../Store/index'
// import { ref, set } from "firebase/database";
// import { getAnalytics, isSupported } from "firebase/analytics";

var config = {
  apiKey: 'AIzaSyBEv8iqVOBCyAEOUzqNYNPF6CfAf5GPXBg',
  authDomain: 'finalinprospect.firebaseapp.com',
  databaseURL: 'https://finalinprospect.firebaseio.com',
  projectId: 'finalinprospect',
  storageBucket: 'finalinprospect.appspot.com',
  messagingSenderId: '787468078530',
  appId: '1:787468078530:web:9f1b569a476f4239'
}
// let database
const auth = {
  context: null,
  messaging: null,
  // analytics: null,

  init(context) {
    // let self = this
    this.context = context
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
      firebase.database()
    } else {
      firebase.app() // if already initialized, use that one
    }
    firebase.auth().onAuthStateChanged((user) => {
      // const self = this
      if (user) {
        console.log('user', user);
        user.getIdToken().then((authToken) => {
          console.log('authToken', authToken);
          this.context.$store.dispatch('user/set_user', user)
          this.context.$store.dispatch('user/set_authToken', authToken)
          this.context.$router.push('/activitySetup')
        })
      }
    })
  },
  getFirebaseObject() {
    return firebase
  },
  getMessagingObject() {
    // firebase.initializeApp(config)
    this.init(this.context)
    return firebase.messaging()
  },
  login() {
    var provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('Profile')
    provider.addScope('email')
    return firebase.auth().signInWithPopup(provider)
      .catch(function (err) {
        console.log(err)
        alert('Something went wrong while logging in using Gmail. Please try again.')
      })
  },
  loginWithMicrosoft() {
    var provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('profile');
    provider.addScope('email');
    return firebase.auth().signInWithPopup(provider)
    .catch(function (err) {
      console.log(err)
      alert('Something went wrong while logging in using microsoft. Please try again.')
    });
  },
  user() {
    return this.context ? firebase.auth().currentUser : null
  },
  logout(context) {
    firebase.auth().signOut()
      .then(function () {
        context.$router.push('/activityWelcome')
      })
      .catch(function (err) {
        console.log(err)
      })
  },
  async getPortalNavigationSecurity(data) {
    try {
        const collectionRef = firebase.database().ref('portalNavigationBackup');
        const snapshot = await collectionRef.orderByChild('adminUId').equalTo(data.adminUId).once('value');
        let isFound = false;
        snapshot.forEach((childSnapshot) => {
            const temp = childSnapshot.val();
            if (temp.authenticationId === data.reAuthenticationId) {
                isFound = true;
            }
        });
        return isFound;
    } catch (error) {
        console.error(error);
        return false;
    }
  },
  async getInstituteCustomizationFromFirebase(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    const docRef = firebase
      .database()
      .ref("/instituteCustomization/" + instituteId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  }
}

// Use the database reference to read or write data
// For example:

export const sendQrDataToFireBase = (data) => {
  firebase.database().ref('qrCodeAttendance/' + data.lectureId).push(data);
  const commentsRef = firebase.database().ref('qrCodeAttendance/' + data.lectureId);
  commentsRef.on('child_changed', (data) => {
    const value = data.val()
    console.log('value', value);
    store.commit("liveData/set_attendanceInfoByQr", {
      attendanceInfoByQr: value
    })
    console.log('child updated')

  });
}

export const sendEventQrDataToFireBase = (data) => {
  firebase.database().ref('qrCodeEventAttendance/' + data.eventId).push(data);
  const commentsRef = firebase.database().ref('qrCodeEventAttendance/' + data.eventId);
  commentsRef.on('child_changed', (data) => {
    const value = data.val()
    console.log('eventData', value);
    store.commit("liveData/set_attendanceInfoByQr", {
      attendanceInfoByQr: value
    })
    console.log('child updated')

  });
}

export default auth
