<template src="./activityFeedbackList.html"></template>
<script>
// import auth from '../../../Services/Firebase/auth'
// import state from '../../../Store/Modules/liveData'
import networkManager from '../../../NetworkManager/index'
// import v3 from '../../../NetworkManager/apiV3'
import { convertDate } from "../../../Services/Utils/DateTimeFormatter";
import showStatus from '../../../NetworkManager/showStatus'
import PortalFooter from '../../../Components/PortalFooter'

export default {
    name: 'activityFeedbackList',
    components: {
        PortalFooter
    },
    data() {
        return {
            search: "",
            loadTable: false,
            loading: false,
            isMobileView: false,
            userData: null,
            selectedInstitute: '',
            selectedSemester: '',
            headers: [
                {
                    text: 'Form Name',
                    align: 'left',
                    value: 'formTitle',
                    class: "DataTable"
                },
                {
                    text: 'Subject',
                    align: 'left',
                    value: 'subjectName'
                },
                {
                    text: "Type",
                    align: 'left',
                    value: 'formType'
                },
                {
                    text: 'Due Date',
                    align: 'left',
                    value: 'endDate'
                },
                {
                    text: 'Submitted on',
                    align: 'left',
                    value: 'submittedOn'
                },
                {
                    text: 'Status',
                    align: 'left',
                    value: 'submittedStatus'
                }
            ],
            feedbackFormsList: [],
            pastFeedbackFormsList: [],
            submittedFormList: []
        }
    },
    created() {
        // this.uId = this.$store.getters['user/get_userData'].uId
        this.selectedTopic = this.$store.getters['liveData/get_selectedTopic']

        this.$store.commit('liveData/set_selectedActivityName', 'Feedback Form')

        this.isMobileView = this.$store.getters['liveData/get_isMobileView']

        this.userData = this.$store.getters['user/get_userData']
        this.selectedInstitute = this.$store.getters[
            'instituteData/get_selectedInstitute'
        ]
        this.selectedSemester = this.$store.getters[
            'instituteData/get_selectedSemester'
        ]

        this.getStudentFillFormDetails();
    },
    methods: {
        activity() {
            this.$tours['activityFeedbackList'].start()
        },
        gotoFillFeedbackFormActivity(id) {
            if (this.isMobileView) {
                this.$router.push({
                    name: 'activityFillFeedbackForm2',
                    params: {
                        formId: id
                    }
                })
            } else {
                this.$router.push({
                    name: 'activityFillFeedbackForm',
                    params: {
                        formId: id
                    }
                })
            }
        },
        async getStudentFillFormDetails() {
            this.submittedFormList = []
            try {
                const res = await networkManager.getRequest('/feedbackWithNBAAttainment/getFeedbacksByUserId', {
                    userId: this.userData.uId
                })
                const data = await res.feedbacks
                data.map((item, index) => {
                    this.submittedFormList.push(item)
                })
                this.getFormList()
            } catch (err) {
                showStatus(err, 6000, 'error', this)
            }
        },
        async getFormList() {
            this.feedbackFormsList = []
            try {
                const res = await networkManager.getRequest('/feedbackWithNBAAttainment/getAllFeedbacksForStudents', {
                    instituteId: this.selectedInstitute,
                    semId: this.userData.semId,
                    department: this.userData.department,
                    courseYear: this.userData.courseYear,
                    division: this.userData.division
                })
                const data = await res.feedbacks
                data.map((item, index) => {
                    if (item.formType === "Course Exit Survey" || item.formType === "Miscellaneous") {
                        if (this.userData.subjects.includes(item.subjectId)) {
                            if (this.submittedFormList.length > 0) {
                                item['submittedOn'] = "-"
                                item['submittedStatus'] = false
                                this.submittedFormList.map((s, i) => {
                                    if (s.feedbackFormId === item._id) {
                                        item['submittedOn'] = convertDate(s.createdAt)
                                        item['submittedStatus'] = true
                                    }
                                })
                            } else {
                                item['submittedOn'] = "-"
                                item['submittedStatus'] = false
                                // this.feedbackFormsList.push(item)
                            }
                            if (new Date(item.endDate) > new Date()) {
                                if (!this.feedbackFormsList.includes(item)) {
                                    this.feedbackFormsList.push(item)
                                }
                            } else {
                                if (!this.pastFeedbackFormsList.includes(item)) {
                                    this.pastFeedbackFormsList.push(item)
                                }
                            }
                        }
                    }
                })
            } catch (err) {
                showStatus(err, 6000, 'error', this)
            }
        }
    }
}
</script>
<style scoped src="./activityFeedbackList.css"></style>
