import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamSettings'

class ExamSettings {
  constructor (context) {
    this.context = context
  }

  async getInstituteExamSetting (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getInstituteExamSetting, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default ExamSettings
