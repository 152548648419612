import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/zoomMeeting'

class zoomMeeting {
  constructor (context) {
    this.context = context
  }

  async createZoomMeeting (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createZoomMeeting, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async joinZoomMeeting (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.joinZoomMeeting, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getrecordingLink (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getrecordingLink, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default zoomMeeting
