<template src="./activityExamAnalysis.html"></template>

<script>
import apiV3 from '../../NetworkManager/apiV3';
import ExamRepository from "../../Repository/Exam";

export default {
  name: "activityExamAnalysis",
  props: ["prop_exam", "prop_allAttempts"],
  data() {
    return {
      examAnalysis: {},
      item: {},
      isLoading: false,
      count: 0,
      attemptNo: 1,
      attemptData: {},
      questions: 0,
      time: 0,
      responseLength: 0,
      timeTake: '',
      actualAnswer: []
    };
  },
  async created() {
    this.count = 0
    this.responseLength = this.prop_exam.response?.length
    // console.log('this.responseLength', this.responseLength)
    // console.log(' ', this.prop_exam, this.prop_allAttempts)
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.examRepositoryInstance = new ExamRepository(this);
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    this.$store.commit("liveData/set_selectedActivityName", "Exam Analysis");
    this.examRepositoryInstance = new ExamRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.item = this.prop_exam;
    await this.getAnalysis();
    this.calculateAttempt(1)
  },
  methods: {
    calculateObtainedMarks(que, ans) {
      let marks = 0
      if (Array.isArray(this.actualAnswer) && this.actualAnswer.length > 0) {
        this.actualAnswer.map((item) => {
          if (item.question === que) {
            // console.log('item', item);
            if (ans === item.answer) {

              marks = item.marks
            }
          }
        })
      }
      return marks
    },
    async getAnalysis() {
      this.count = 0
      try {
        this.isLoading = true
        this.actualAnswer = []
        const values = await apiV3.getRequest(
          `/exam/getQuestionsByExam?examId=${this.item._id}`
        );
        this.actualAnswer = [...values.questions]
        // console.log('this.actualAnswer', this.actualAnswer);
        const { answers, totalMarksObtained, totalOutOf } = await apiV3.getRequest(
          `/exam/answers?examId=${this.item._id}&studentId=${this.userData.uId}`
        );
        this.examAnalysis = {
          questions: answers,
          totalMarksObtained,
          totalOutOf
        }
        // console.log('answers', answers);
        // this.examAnalysis.questions.forEach((exam) => {
        //   if (exam.answer === exam.selectedAnswer) this.count++
        // })
        this.examName = this.item.name;
        this.subjectName = this.item.subjectName;
        this.examType = this.item.examType;
      } catch (e) {
        console.log('errrr');
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    getCorrectedAns(que) {
      let ans = ''
      if (Array.isArray(this.actualAnswer) && this.actualAnswer.length > 0) {
        this.actualAnswer.map((item) => {
          if (item.question === que) {
            ans = item.answer
          }
        })
      }
      return ans
    },
    checkAnsIsCorrect(selectedAns, que) {
      // console.log('selectedAns', selectedAns);
      // console.log('que', que);
      let flag = false
      if (Array.isArray(this.actualAnswer) && this.actualAnswer.length > 0) {
        this.actualAnswer.map((item) => {
          if (item.question === que && selectedAns === item.answer) {
            // console.log('item', item);
            flag = true
          }
        })
      }
      return flag
    },
    calculateAttempt(i) {
      // Assuming prop_exam is an array containing the data for each attempt.
      // console.log('check');
      this.count = 0
      this.attemptData = {}
      if (this.prop_exam.response[i - 1]) {
        // console.log('this.prop_exam.response', this.prop_exam.response);
        this.attemptData = this.prop_exam.response[i - 1];
        // console.log('this.actualAnswer1', this.actualAnswer);
        // console.log('this.actualAnswer', this.actualAnswer.length);
        // console.log('this.attemptData.answers', this.attemptData);
        if (this.actualAnswer.length > 0 && Array.isArray(this.attemptData.answers)) {
          this.actualAnswer.map((item, i) => {
            this.attemptData.answers.map((ans) => {
              if (ans.question === item.question) {
                ans.index = i
                ans.answer = item.answer
                ans.marks = item.marks
              }
            })
          })
          this.attemptData.answers = this.attemptData.answers.sort((a, b) => a.index - b.index);
          // console.log('this.attemptData.answers', this.attemptData.answers);
        }
        this.attemptData.totalOutOf = 0
        this.attemptData.totalMarksObtained = 0
        for (let j = 0; j < this.attemptData?.answers.length; j++) {
          // console.log('this.attemptData?.answers[j].marks', this.attemptData?.answers[j].marks);
          this.attemptData.totalOutOf = Number(this.attemptData.totalOutOf) + Number(this.attemptData?.answers[j].marks)
          if (this.attemptData?.answers[j].answer === this.attemptData?.answers[j].selectedAnswer) {
            this.count++;
            this.attemptData.totalMarksObtained = Number(this.attemptData.totalMarksObtained) + Number(this.attemptData?.answers[j].marks)
          }
        }
        // console.log('this.count', this.count);
        // console.log('attemptData1', this.attemptData)

        this.questions = this.attemptData.answers.length
        this.time = this.attemptData.timeTaken
      } else {
        // console.log('attemptData2', this.attemptData)
        alert('Attempt Not Given')
        this.questions = 0
        this.attemptData.totalMarksObtained = 0
        this.attemptData.totalOutOf = 0
        this.count = 0
        this.time = '00:00'
      }
    },
    goBack() {
      if (this.isMobileView) {
        this.$router.push("/activityExams2");
      } else {
        this.$router.push("/activityExams");
      }
      // if (this.isMobileView) {
      //     this.$router.push({
      //         name: "activityCourseMaterialRevamped2",
      //         params: {
      //             prop_course: this.prop_subjectInfo,
      //             prop_indexOfSelectedSection: this.indexOfSelectedSection,
      //             prop_indexSelectedSubSection: this.indexSelectedSubSection,
      //             prop_courseDetails: this.prop_courseDetails,
      //             prop_subject: this.prop_subject
      //         }
      //     });
      // } else {
      //     this.$router.push({
      //         name: "activityCourseMaterialRevamped",
      //         params: {
      //             prop_course: this.prop_subjectInfo,
      //             prop_indexOfSelectedSection: this.indexOfSelectedSection,
      //             prop_indexSelectedSubSection: this.indexSelectedSubSection,
      //             prop_courseDetails: this.prop_courseDetails,
      //             prop_subject: this.prop_subject
      //         }
      //     });
      // }
    }
  }
};
</script>
<style scoped src="./activityExamAnalysis.css"></style>
