import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Course";

class CourseRepository {
  constructor(context) {
    this.context = context;
  }

  async updateCourseInfo(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateCourseInfo,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSingleCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSingleCourse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createStudentCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createStudentCourse,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSingleStudentCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSingleStudentCourse,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getAllCoursesInfoOfSingleStudent(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllCoursesInfoOfSingleStudent,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getAllCoursesInfoOfInstitute(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllCoursesInfoOfInstitute,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getAllMediaLibraryByInstitute(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(ApiNames);
    const response = await networkManager.getRequest(
      ApiNames.getAllMediaLibraryByInstitute,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.media;
  }

  async getSyncCoursesDataOfSingleStudent(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSyncCoursesDataOfSingleStudent,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default CourseRepository;
