const numOps = {
    roundOff(value) {
      if (!isNaN(value)) {
        return Math.round(value);
      }
    },
    roundOffUptoTwoDecimals(value) {
      if (!isNaN(value)) {
        return Math.round(value * 100) / 100;
      }
    },
    roundOffUptoOneDecimals(value) {
      if (!isNaN(value)) {
        return Math.round(value * 10) / 10;
      }
    },
    calculatePercentage(partialValue, totalValue) {
      return totalValue !== 0 ? this.roundOffUptoTwoDecimals((100 * partialValue) / totalValue) : 0
    }
  };
  export default numOps;
