<template src='./activityProfile.html'></template>
<script>
import inputContainer from '../../Components/inputContainer'
import UserRepository from '../../Repository/User'
import CourseRepository from "../../Repository/Course";
import UserAuthentication from "../../Repository/UserAuthentication"
import arrayOps from '../../Services/Utils/arrayOps'
import html2pdf from "html2pdf.js";
import showStatus from "../../NetworkManager/showStatus";

export default {
  name: 'activityProfile',
  components: {
    inputContainer
  },
  data() {
    return {
      i: null,
      dialogExtraFieldSppu: false,
      addingEmail: false,
      addingPhone: false,
      userData: null,
      tempEmail: '',
      tempPhone: '',
      tempFirstName: '',
      tempMiddleName: '',
      tempLastName: '',
      resetPassword: '',
      tempAddress: '',
      tempPhones: [],
      tempDateOfBirth: '',
      tempEmails: [],
      tempGender: '',
      tempBloodGroup: '',
      selectedSemester: "",
      selectedTab: 1,
      showOptions: false,
      selectedOption: null,
      isModalVisible: false,
      couseEditingModel: false,
      internshipEditingModel: false,
      higherStudiesEditingModel: false,
      jobsEditingModel: false,
      enterpreneurshipEditingModel: false,
      InternshipsModel: false,
      JobModel: false,
      higherStudiesModel: false,
      EntrepreneurshipModel: false,
      coActivitiEvents: false,
      courseModelFormCount: [0],
      imagePrevModelShow: false,
      prevFileTypeisPdf: false,
      hideCouse: false,
      hideInternship: false,
      hideHigherStudies: false,
      hideJobs: false,
      hideEnterpreneurship: false,
      onlineOfflineDropDown: false,
      deleteProfilePhotoModel: false,
      deletetingOption: "",
      usersDetailsPrevModel: false,
      extraStudentsDetailsChanges: false,
      extraStudentsDetails: {
        collegeEligibilitynumber: '',
        collegeName: '',
        collegeCourseYear: '',
        collegeDepartmentName: ''
      },
      userAccadamicDetails: {
        userAccadamicCourseDetails: [],
        userAccadamicInternshipDetails: [],
        userAccadamicJobDetails: [],
        userAccadamicHigherStudiesDetails: [],
        userAccadamicEntrepreneurshipDetails: []
      },
      UserCoActivitiesEventsDetails: [],
      userAccadamicAndCoActiviDeleteteModel: false,
      deletingObject: '',
      deletingObjectId: "",
      editingCouseData: '',
      editingInternshipData: '',
      editingJobData: '',
      editingHigherStudiesData: '',
      showfilterOptions: false,
      filterOption: '6',
      editingEntrepreneurshipData: '',
      editingEventData: '',
      editingEventModel: false,
      uploadingLoading: false,
      options: [
        { id: '1', label: 'Courses' },
        { id: '2', label: 'Internship' },
        { id: '3', label: 'Job' },
        { id: '4', label: 'Higher studies' },
        { id: '5', label: 'Entrepreneurship' }
      ],
      filteroptions: [
        { id: '1', label: 'Courses' },
        { id: '2', label: 'Internship' },
        { id: '3', label: 'Job' },
        { id: '4', label: 'Higher studies' },
        { id: '5', label: 'Entrepreneurship' },
        { id: '6', label: 'All' }
      ],
      onlineOffline: ['Online', 'Offline'],
      AllCourseModelData: [
        {
          courseName: '',
          courseType: '',
          courseDuration: '',
          creditsReceived: '',
          certificateFiles: [],
          instituteCourse: ''

        }
      ],
      AllInternshipModelData: [
        {
          organizationName: '',
          managerName: '',
          proofFile: '',
          offerLetterFile: '',
          role: '',
          stipend: '',
          certificateFile: '',
          startingdate: '',
          endingdate: ''
        }
      ],
      AllJobModelData: [
        {
          organizationName: '',
          managerName: '',
          offerLetterFile: [],
          appoinMentLetterFile: [],
          role: '',
          package: '',
          joiningDate: ''
        }
      ],
      AllHigherStudiesModelData: [
        {
          degreeName: '',
          universityName: '',
          examSeat: '',
          examScoreFile: [],
          appoinMentLetterFile: [],
          examScore: ""
        }
      ],
      AllEntrepreneurshipModelData: [
        {
          companyName: '',
          domineName: '',
          projectTitle: '',
          projectDocFile: [],
          proofFile: []
        }
      ],
      AllCoActivitiEventsModelData: [
        {
          eventName: '',
          eventType: '',
          instituteEvent: '',
          stateEvent: '',
          eventDetails: '',
          venueDetails: '',
          dateOfEvent: '',
          participationEventCertificate: [],
          awardReceived: '',
          eventOutcome: '',
          ceritificates: []
        }
      ],
      showFilePrev: false,
      filePrevUrl: '',
      bloodGroups: [
        'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'
      ],
      genders: ['Male', 'Female', 'Other'],
      isMobileView: false,
      user: {},
      saveConfirmationDialog: false
    }
  },
  async created() {
    if (process.env.VUE_APP_WHITELABEL_NAME) {
      this.sppuChanges = process.env.VUE_APP_WHITELABEL_NAME
    }

    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.userRepositoryInstance = new UserRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'My Profile')
    this.userData = this.$store.getters['user/get_userData']
    this.CourseRepositoryInstance = new CourseRepository(this);
    this.userAuthenticationRepositoryInstance = new UserAuthentication(this)

    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    // if (process.env.VUE_APP_WHITELABEL_NAME === 'sppu') {
    //   this.extraStudentsDetailsChanges = true
    // }
    await this.fetchData()
    // if (this.sppuChanges === 'sppu') {
    //   this.dialogExtraFieldSppu = true
    // }
    this.getAccadamicAndCoActivityDetails()
    document.addEventListener('click', this.handleDocumentClick);
  },
  methods: {
    handleDocumentClick(event) {
      if (this.$refs.filterOptions && !this.$refs.filterOptions.contains(event.target)) {
        // Clicked outside of pop-up, so close it
        this.closeFilterOptions();
      }
    },
    closeFilterAndSelectOption() {
      this.showfilterOptions = false;
      this.showOptions = false;
    },
    async fetchData() {
      const objToPush = {
        uId: this.userData.uId
      }
      try {
        this.user = await this.userRepositoryInstance.getUserProfile(objToPush)
        this.tempFirstName = this.user.firstName
        this.tempMiddleName = this.user.middleName
        this.tempLastName = this.user.lastName
        this.tempDateOfBirth = this.user.dateOfBirth ? this.parseDate(this.user.dateOfBirth) : ''
        this.tempGender = this.user.gender
        this.extraStudentsDetails = {
          collegeEligibilitynumber: this.user.collegeEligibilitynumber,
          collegeName: this.user.collegeName,
          collegeCourseYear: this.user.collegeCourseYear,
          collegeDepartmentName: this.user.collegeDepartmentName
        }
        this.user.emails = []
        this.user.phones = []

        //

        const emailsAndPhones = await this.userRepositoryInstance.getUserAuthentication(objToPush)
        if (emailsAndPhones.emails) {
          emailsAndPhones.emails.forEach((email) => {
            this.tempEmails.push(email)
            this.user.emails.push(email)
          })
        }
        if (emailsAndPhones.phones) {
          emailsAndPhones.phones.forEach((phone) => {
            this.tempPhones.push(phone)
            this.user.phones.push(phone)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getAccadamicAndCoActivityDetails() {

      const objToPush = {
        uId: this.userData.uId
      }
      try {
        // getting the accadamic course details of the students
        // here using the same api to get co activities datas also
        const accadamicAndCoActivitesResponse = await this.userRepositoryInstance.getAccadamicAndCoActivityDetails(objToPush)
        //
        this.userAccadamicDetails.userAccadamicCourseDetails = accadamicAndCoActivitesResponse.response.userdata.accdamicCouseDetails
        this.userAccadamicDetails.userAccadamicInternshipDetails = accadamicAndCoActivitesResponse.response.userdata.accdamicInternshipDetails
        this.userAccadamicDetails.userAccadamicJobDetails = accadamicAndCoActivitesResponse.response.userdata.accdamicJobDetails
        this.userAccadamicDetails.userAccadamicHigherStudiesDetails = accadamicAndCoActivitesResponse.response.userdata.accdamicHigherStudiesDetails
        this.userAccadamicDetails.userAccadamicEntrepreneurshipDetails = accadamicAndCoActivitesResponse.response.userdata.accdamicEntrepreneurshipDetails
        this.UserCoActivitiesEventsDetails = accadamicAndCoActivitesResponse.response.userdata.coActivityEventsDetails
      } catch (err) {
        console.log(err)
      }
    },
    saveConfirmation() {
      this.saveConfirmationDialog = true;
    },
    async save() {
      if (this.resetPassword !== '') {
        const objToPush = {
          uId: this.userData.uId,
          newPassword: this.resetPassword
        }
        await this.userAuthenticationRepositoryInstance.resetPassword(objToPush)
      }
     if (this.tempFirstName !== this.user.firstName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            firstName: this.tempFirstName
          }
          this.tempFirstName = await this.userRepositoryInstance.updateFirstName(objToPush)
          this.user.firstName = this.tempFirstName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.tempMiddleName !== this.user.middleName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            middleName: this.tempMiddleName
          }
          this.tempMiddleName = await this.userRepositoryInstance.updateMiddleName(objToPush)
          this.user.middleName = this.tempMiddleName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.tempLastName !== this.user.lastName) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            lastName: this.tempLastName
          }
          this.tempLastName = await this.userRepositoryInstance.updateLastName(objToPush)
          this.user.lastName = this.tempLastName
        } catch (err) {
          console.log(err)
        }
      }
      if (this.parseDate(this.convertDateOfBirth(this.tempDateOfBirth)) !== this.user.dateOfBirth) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            dateOfBirth: this.convertDateOfBirth(this.tempDateOfBirth)
          }
          const updatedDateOfBirth = await this.userRepositoryInstance.updateDateOfBirth(objToPush)
          this.tempDateOfBirth = this.parseDate(updatedDateOfBirth)
          this.user.dateOfBirth = updatedDateOfBirth
        } catch (err) {
          console.log(err)
        }
      }

      // if (this.user?.phones.length > 0) {
      //   try {
      //     const objToPush = {
      //       uId: this.userData.uId,
      //       previousPhone: this.user.phones[0],
      //       newPhone: this.tempPhones[0]
      //     }
      //     await this.userRepositoryInstance.updatePhone(objToPush)
      //   } catch (err) {
      //     console.log(err)
      //   }
      // }

      if (this.user?.phones.length > 0) {
        try {

          //******************** Check if the new phone number contains only numbers ********************//
          if (this.tempPhones[0].toString().length !== 10) {
            if (!/^\d+$/.test(this.tempPhones[0])) {
              showStatus(
                "Phone number should only contain numbers!",
                3000,
                "error",
                this
              );
              return;
            }

            //******************** Check if the new phone number is exactly 10 digits long ********************//
            if (this.tempPhones[0].length !== 10) {
              showStatus(
                "Phone number should be exactly 10 digits long!",
                3000,
                "error",
                this
              );
              return;
            }
          }

          const objToPush = {
            uId: this.userData.uId,
            previousPhone: this.user.phones[0],
            newPhone: this.tempPhones[0]
          }
          await this.userRepositoryInstance.updatePhone(objToPush)
          showStatus(
            "Changes Saved Successfully!",
            1000,
            "success",
            this
          );
        } catch (err) {
          console.log(err)
        }
      }

      if (this.tempGender !== this.user.gender) {
        try {
          const objToPush = {
            uId: this.userData.uId,
            gender: this.tempGender
          }
          this.tempGender = await this.userRepositoryInstance.updateGender(objToPush)
          this.user.gender = this.tempGender
        } catch (err) {
          console.log(err)
        }
      }
      await this.addAndRemoveEmailsAndPhones()
      this.saveConfirmationDialog = false;
      showStatus(
        "Changes Saved Successfully!",
        1000,
        "success",
        this
      );
    },
    async addAndRemoveEmailsAndPhones() {
      const emailsToDelete = this.user.emails.filter((email) => !this.tempEmails.includes(email))

      for (let i = 0; i < emailsToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deleteEmail({
            uId: this.user.uId,
            email: emailsToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToDelete = this.user.phones.filter((phone) => !this.tempPhones.includes(phone))

      for (let i = 0; i < phonesToDelete.length; i++) {
        try {
          await this.userRepositoryInstance.deletePhone({
            uId: this.user.uId,
            phone: phonesToDelete[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const emailsToAdd = this.tempEmails.filter((email) => !this.user.emails.includes(email))

      for (let i = 0; i < emailsToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addEmail({
            uId: this.user.uId,
            email: emailsToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }

      const phonesToAdd = this.tempPhones.filter((phone) => !this.user.phones.includes(phone))

      for (let i = 0; i < phonesToAdd.length; i++) {
        try {
          await this.userRepositoryInstance.addPhone({
            uId: this.user.uId,
            phone: phonesToAdd[i]
          })
        } catch (err) {
          console.log(err)
        }
      }
      this.fetchData()
    },
    addEmail() {
      this.tempEmails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhone() {
      this.tempPhones.push(this.tempPhone)
      this.tempPhone = ''
      this.addingPhone = false
    },
    parseDate(dateOfBirth) {

      const parsedDate = new Date(dateOfBirth)
      return parsedDate.getFullYear() +
        '-' +
        ('0' + (parsedDate.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('0' + parsedDate.getDate()).slice(-2)
    },
    removeFromArray(item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    convertFromYYYYMMDDToDate(dateString, timeString) {
      const dateArray = dateString.split('-').map((date) => date)

      return new Date(
        ...dateArray
      )
    },
    convertDateOfBirth(selectedDate) {
      const originalDate = new Date(selectedDate);
      const formattedDate = originalDate.toString();
      return formattedDate
    },
    // handling profile photo uploding
    async handleProfileUplode(event) {
      this.uploadingLoading = true
      const profilePhoto = await this.uplodeArrayOfFiles(Array.from(event.target.files))
      // console.log(profilePhoto[0].presignedUrl)
      const url = profilePhoto[0].presignedUrl
      const urlSearchParams = new URLSearchParams(url.split('?')[1]);
      urlSearchParams.delete('AWSAccessKeyId');
      const newUrl = url.split('?')[0] + '?' + urlSearchParams.toString();
      const profilePicture = {
        url: newUrl,
        name: Array.from(event.target.files)[0].name
      }
      const postData = {
        userId: this.userData.uId,
        profilePicture: profilePicture
      }
      // update profile picture and calling getuser agin
      await this.userRepositoryInstance.updateStudentsProfileImage(postData)
      this.fetchData()
      // this.user.profilePicture =
      this.uploadingLoading = false
    },
    // userProfileTabs Functions
    tabSelect(e) {
      this.selectedTab = e
    },
    selectOption(option) {
      this.showOptions = false;
      this.selectedOption = option;
      this.uploadingLoading = false
      if (option === "1") {
        this.isModalVisible = true;
      } else if (option === "2") {
        this.InternshipsModel = true
      } else if (option === "3") {
        this.JobModel = true
      } else if (option === "4") {
        this.higherStudiesModel = true
      } else if (option === "5") {
        this.EntrepreneurshipModel = true
      } else if (option === "6") {
        this.coActivitiEvents = true
      }
      //  based on the selected option
    },
    selectOnlineOffline(option) {

      this.showOptions = false;
      this.selectedOption = option;
      this.uploadingLoading = false
      if (option === "1") {
        this.isModalVisible = true;
      } else if (option === "2") {
        this.InternshipsModel = true
      } else if (option === "3") {
        this.JobModel = true
      } else if (option === "4") {
        this.higherStudiesModel = true
      } else if (option === "5") {
        this.EntrepreneurshipModel = true
      } else if (option === "6") {
        this.coActivitiEvents = true
      }
      //  based on the selected option
    },
    selectFilterOption(option) {
      this.showfilterOptions = false;
      this.filterOption = option;
      if (option === "1") {
        this.hideCouse = false
        this.hideInternship = true
        this.hideHigherStudies = true
        this.hideJobs = true
        this.hideEnterpreneurship = true
      } else if (option === "2") {
        this.hideCouse = true
        this.hideInternship = false
        this.hideHigherStudies = true
        this.hideJobs = true
        this.hideEnterpreneurship = true
      } else if (option === "3") {
        this.hideCouse = true
        this.hideInternship = true
        this.hideHigherStudies = true
        this.hideJobs = false
        this.hideEnterpreneurship = true
      } else if (option === "4") {
        this.hideCouse = true
        this.hideInternship = true
        this.hideHigherStudies = false
        this.hideJobs = true
        this.hideEnterpreneurship = true
      } else if (option === "5") {
        this.hideCouse = true
        this.hideInternship = true
        this.hideHigherStudies = true
        this.hideJobs = true
        this.hideEnterpreneurship = false
      } else if (option === "6") {
        this.hideCouse = false
        this.hideInternship = false
        this.hideHigherStudies = false
        this.hideJobs = false
        this.hideEnterpreneurship = false
      }
      //  based on the selected option
    },
    closeModal(option) {
      this.uploadingLoading = false
      if (option === 0) {
        this.courseModelFormCount.length = 0
        this.AllCourseModelData = [
          {
            courseName: '',
            courseType: '',
            courseDuration: '',
            creditsReceived: '',
            certificateFiles: [],
            instituteCourse: ''
          }
        ]
        this.isModalVisible = false;
      } else if (option === 1) {

        this.AllInternshipModelData = [
          {
            organizationName: '',
            managerName: '',
            proofFile: '',
            offerLetterFile: '',
            role: '',
            stipend: '',
            certificateFile: ''
          }
        ]
        this.InternshipsModel = false
      } else if (option === 2) {

        this.AllJobModelData = [
          {
            organizationName: '',
            managerName: '',
            offerLetterFile: [],
            appoinMentLetterFile: [],
            role: '',
            package: ''

          }
        ]
        this.JobModel = false
      } else if (option === 3) {

        this.AllHigherStudiesModelData = [
          {
            degreeName: '',
            universityName: '',
            examSeat: '',
            examScoreFile: [],
            appoinMentLetterFile: []

          }
        ]
        this.higherStudiesModel = false
      } else if (option === 4) {
        this.AllEntrepreneurshipModelData = [
          {
            companyName: '',
            domineName: '',
            projectTitle: '',
            projectDocFile: [],
            proofFile: []
          }
        ]
        this.EntrepreneurshipModel = false
      } else if (option === 6) {
        this.AllCoActivitiEventsModelData = [
          {
            eventName: '',
            eventType: '',
            instituteEvent: '',
            stateEvent: '',
            eventDetails: '',
            venueDetails: '',
            dateOfEvent: '',
            participationEventCertificate: [],
            awardReceived: '',
            ceritificates: []
          }
        ]
        this.coActivitiEvents = false
      }
    },
    async uploadCertificates() {
      const updatedCourses = [];

      for (const course of this.AllCourseModelData) {
        const certificateFilePromises = [];
        if (!course.certificateFiles) {
          return
        }
        const certificateFiles = course.certificateFiles?.filter(item => item.name);

        certificateFiles.forEach(certificateFile => {
          certificateFilePromises.push(
            this.getSignedUrl(certificateFile, `${this.userData.uId}/certificates/`)
          );
        });

        const signedUrlsData = await Promise.all(certificateFilePromises);
        const signedUrls = signedUrlsData.map(item => item.signedUrl);

        const uploadPromises = [];
        if (signedUrls.length === certificateFiles.length) {
          certificateFiles.forEach((certificateFile, i) => {
            uploadPromises.push(this.uploadToSpaces(certificateFile, signedUrls[i]));
          });
          const uploadResults = await Promise.all(uploadPromises);

          const allUploadsSuccessful = uploadResults.every(result => result);
          if (allUploadsSuccessful) {
            const updatedCourse = Object.assign({}, course);
            updatedCourse.certificateFiles = signedUrlsData.map((item, i) => ({
              name: certificateFiles[i].name,
              presignedUrl: item.signedUrl
            }));
            updatedCourses.push(updatedCourse);
          } else {
            //  file faild to uplode

          }
        } else {
          updatedCourses.push(course);
        }
      }

      return updatedCourses;
    },
    async uplodeArrayOfFiles(arrayOfFiles) {

      let updatedarrayOfFiles = []
      const certificateFilePromises = [];

      const certificateFiles = arrayOfFiles?.filter(item => item.name);

      certificateFiles.forEach(certificateFile => {
        certificateFilePromises.push(
          this.getSignedUrl(certificateFile, `${this.userData.uId}/certificates/`)
        );
      });

      const signedUrlsData = await Promise.all(certificateFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = signedUrls.map((signedUrl, i) => {
        return this.uploadToSpaces(certificateFiles[i], signedUrl);
      });
      const uploadResults = await Promise.all(uploadPromises);
      const allUploadsSuccessful = uploadResults.every(result => result);
      if (allUploadsSuccessful) {
        updatedarrayOfFiles = signedUrlsData.map((item, i) => ({
          name: certificateFiles[i].name,
          presignedUrl: item.signedUrl
        }));

        return updatedarrayOfFiles;
      }
    },
    async uploadToSpaces(file, signedUrl) {
      try {
        await this.futch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read'
          }
        });
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'get', url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;
        xhr.send(opts.body);
      });
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.CourseRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async saveDetails(option) {
      try {
        if (option === 0) {
          const currentData = this.AllCourseModelData[this.AllCourseModelData.length - 1]

          if (!currentData?.courseName || !currentData?.courseType || !currentData?.courseDuration || !currentData?.instituteCourse) {
            return console.log("empty feald")
          }

          this.uploadingLoading = true
          const updatedCourses = await this.uploadCertificates();

          const postData = {
            userId: this.userData.uId,
            accdamicCouseDetails: updatedCourses
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsAccdamicCouseDetails(postData)
          this.uploadingLoading = false
          // Close modal
          this.isModalVisible = false;
          const newData = {
            courseName: '',
            courseType: '',
            courseDuration: '',
            creditsReceived: '',
            certificateFiles: '',
            instituteCourse: ''
          }
          // saving data to current object then push newobject
          this.AllCourseModelData = [newData]
        } else if (option === 1) {
          const currentData = this.AllInternshipModelData[this.AllInternshipModelData.length - 1]

          if (!currentData?.organizationName || !currentData?.proofFile || !currentData?.offerLetterFile || !currentData?.role) {
            return console.log("empty feald")
          }
          this.uploadingLoading = true
          await Promise.all(this.AllInternshipModelData.map(async (internship, i) => {
            this.AllInternshipModelData[i].proofFile = await this.uplodeArrayOfFiles(internship.proofFile)
            this.AllInternshipModelData[i].offerLetterFile = await this.uplodeArrayOfFiles(internship.offerLetterFile)
            this.AllInternshipModelData[i].certificateFile = await this.uplodeArrayOfFiles(internship.certificateFile)

          }));
          this.uploadingLoading = false
          // this.AllInternshipModelData[0].proofFile = await this.uplodeArrayOfFiles(this.AllInternshipModelData[0].proofFile)
          //

          const postData = {
            userId: this.userData.uId,
            accdamicInternshipDetails: this.AllInternshipModelData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsAccdamicInternshipDetails(postData)

          // this.uploadingLoading = false

          // Close modal
          this.InternshipsModel = false;
          const newData = {
            organizationName: '',
            managerName: '',
            proofFile: '',
            offerLetterFile: '',
            role: '',
            stipend: '',
            certificateFile: '',
            startingdate: '',
            endingdate: ''
          }
          // saving data to current object then push newobject
          this.AllInternshipModelData = [newData]
        } else if (option === 2) {
          const currentData = this.AllJobModelData[this.AllJobModelData.length - 1]

          if (!currentData?.organizationName || !currentData?.offerLetterFile || !currentData?.appoinMentLetterFile || !currentData?.role || !currentData?.package) {
            return console.log("empty feald")
          }

          // const updatedCourses = await this.uploadCertificates();
          this.uploadingLoading = true
          await Promise.all(this.AllJobModelData.map(async (job, i) => {
            this.AllJobModelData[i].offerLetterFile = await this.uplodeArrayOfFiles(job.offerLetterFile)
            this.AllJobModelData[i].appoinMentLetterFile = await this.uplodeArrayOfFiles(job.appoinMentLetterFile)

          }));
          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicJobDetails: this.AllJobModelData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsAccdamicJobDetails(postData)

          // this.uploadingLoading = false

          // Close modal
          this.JobModel = false;
          const newData = {
            organizationName: '',
            managerName: '',
            offerLetterFile: [],
            appoinMentLetterFile: [],
            role: '',
            package: '',
            joiningDate: ''
          }
          // saving data to current object then push newobject
          this.AllJobModelData = [newData]
        } else if (option === 3) {
          const currentData = this.AllHigherStudiesModelData[this.AllHigherStudiesModelData.length - 1]

          if (!currentData?.degreeName) {
            return console.log("empty feald")
          }

          // const updatedCourses = await this.uploadCertificates();
          this.uploadingLoading = true
          await Promise.all(this.AllHigherStudiesModelData.map(async (job, i) => {
            this.AllHigherStudiesModelData[i].examScoreFile = await this.uplodeArrayOfFiles(job.examScoreFile)
            this.AllHigherStudiesModelData[i].appoinMentLetterFile = await this.uplodeArrayOfFiles(job.appoinMentLetterFile)

          }));
          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicHigherStudiesDetails: this.AllHigherStudiesModelData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsAccdamicHigherStudies(postData)
          // Close modal
          this.higherStudiesModel = false;
          // this.uploadingLoading = false
          const newData = {
            degreeName: '',
            universityName: '',
            examSeat: '',
            examScoreFile: [],
            appoinMentLetterFile: [],
            examScore: ""
          }
          // saving data to current object then push newobject
          this.AllHigherStudiesModelData = [newData]
        } else if (option === 4) {
          const currentData = this.AllEntrepreneurshipModelData[this.AllEntrepreneurshipModelData.length - 1]

          if (!currentData?.companyName || !currentData.projectTitle || !currentData.projectDocFile || !currentData.proofFile) {
            return console.log("empty feald")
          }

          // const updatedCourses = await this.uploadCertificates();
          this.uploadingLoading = true
          await Promise.all(this.AllEntrepreneurshipModelData.map(async (ent, i) => {
            this.AllEntrepreneurshipModelData[i].projectDocFile = await this.uplodeArrayOfFiles(ent.projectDocFile)
            this.AllEntrepreneurshipModelData[i].proofFile = await this.uplodeArrayOfFiles(ent.proofFile)

          }));
          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicEntrepreneurshipDetails: this.AllEntrepreneurshipModelData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsAccdamicEntrepreneurshipDetails(postData)
          // Close modal
          this.EntrepreneurshipModel = false;

          // this.uploadingLoading = false
          const newData = {
            companyName: '',
            domineName: '',
            projectTitle: '',
            projectDocFile: [],
            proofFile: []
          }
          // saving data to current object then push newobject
          this.AllEntrepreneurshipModelData = [newData]
        } else if (option === 6) {
          const currentData = this.AllCoActivitiEventsModelData[this.AllCoActivitiEventsModelData.length - 1]

          if (!currentData?.eventName || !currentData.eventType || !currentData.instituteEvent || !currentData.stateEvent || !currentData.eventDetails || !currentData.venueDetails || !currentData.dateOfEvent || !currentData.awardReceived || !currentData.eventOutcome) {
            return console.log("empty feald")
          }

          // const updatedCourses = await this.uploadCertificates();
          this.uploadingLoading = true
          await Promise.all(this.AllCoActivitiEventsModelData.map(async (event, i) => {
            this.AllCoActivitiEventsModelData[i].participationEventCertificate = await this.uplodeArrayOfFiles(event.participationEventCertificate)
            this.AllCoActivitiEventsModelData[i].ceritificates = await this.uplodeArrayOfFiles(event.ceritificates)

          }));
          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            coActivityEventsDetails: this.AllCoActivitiEventsModelData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.saveStudentsCoActivitesEventsDetails(postData)
          // Close modal
          this.coActivitiEvents = false;

          // this.uploadingLoading = false
          const newData = {
            eventName: '',
            eventType: '',
            instituteEvent: '',
            stateEvent: '',
            eventDetails: '',
            venueDetails: '',
            dateOfEvent: '',
            participationEventCertificate: [],
            awardReceived: '',
            eventOutcome: '',
            ceritificates: []
          }
          // saving data to current object then push newobject
          this.AllCoActivitiEventsModelData = [newData]
        }
        // here we use the api to get the extracaricular activites
        this.getAccadamicAndCoActivityDetails()
      } catch (error) {
        console.error(error)
      }
    },
    handleFileUpload(event, i, isEdit = false) {
      if (isEdit) {

        this.editingCouseData.certificateFiles = [...this.editingCouseData.certificateFiles, ...Array.from(event.target.files)]
        return
      }

      // file handling happen
      // const file = Array.from(event.target.files);
      this.certificateFiles =
        this.AllCourseModelData[i].certificateFiles = [...this.AllCourseModelData[i].certificateFiles, ...Array.from(event.target.files)]
    },
    handleInternShipFileUpload(event, i, isEdit = false, feald = "null") {

      if (isEdit) {

        if (feald === "proofFile") {
          this.editingInternshipData.proofFile = Array.from(event.target.files)
        } else if (feald === "certificateFile") {
          this.editingInternshipData.certificateFile = Array.from(event.target.files)
        } else if (feald === 'offerLetterFile') {
          this.editingInternshipData.offerLetterFile = Array.from(event.target.files)
        }
      } else {
        if (feald === "proofFile") {
          this.AllInternshipModelData[i].proofFile = Array.from(event.target.files);

        } else if (feald === "certificateFile") {
          this.AllInternshipModelData[i].certificateFile = Array.from(event.target.files);

        } else if (feald === 'offerLetterFile') {
          this.AllInternshipModelData[i].offerLetterFile = Array.from(event.target.files);

        }
      }
    },
    handleHighterStudiesFileUpload(event, i, isEdit = false, feald = "null") {

      if (isEdit) {
        if (feald === "examScoreFile") {
          this.editingHigherStudiesData.examScoreFile = Array.from(event.target.files)
        } else if (feald === "appoinMentLetterFile") {
          this.editingHigherStudiesData.appoinMentLetterFile = Array.from(event.target.files)
        }
      } else {
        if (feald === "examScoreFile") {
          this.AllHigherStudiesModelData[i].examScoreFile = Array.from(event.target.files);

        } else if (feald === "appoinMentLetterFile") {
          this.AllHigherStudiesModelData[i].appoinMentLetterFile = Array.from(event.target.files);

        }
      }
    },
    handleJobFileUpload(event, i, isEdit = false, feald = "null") {

      if (isEdit) {
        if (feald === "offerLetterFile") {
          this.editingJobData.offerLetterFile = Array.from(event.target.files);
        } else if (feald === "appoinMentLetterFile") {
          this.editingJobData.appoinMentLetterFile = Array.from(event.target.files);
        }
      } else {
        if (feald === "offerLetterFile") {
          this.AllJobModelData[i].offerLetterFile = Array.from(event.target.files);

        } else if (feald === "appoinMentLetterFile") {
          this.AllJobModelData[i].appoinMentLetterFile = Array.from(event.target.files);

        }
      }
    },
    handleEnternshipFileUpload(event, i, isEdit = false, feald = "null") {

      if (isEdit) {
        if (feald === "projectDocFile") {
          this.editingEntrepreneurshipData.projectDocFile = Array.from(event.target.files);
        } else if (feald === "proofFile") {
          this.editingEntrepreneurshipData.proofFile = Array.from(event.target.files);
        }
      } else {
        if (feald === "projectDocFile") {
          this.AllEntrepreneurshipModelData[i].projectDocFile = Array.from(event.target.files);

        } else if (feald === "proofFile") {
          this.AllEntrepreneurshipModelData[i].proofFile = Array.from(event.target.files);

        }
      }
    },
    handleEventFileUpload(event, i, isEdit = false, feald = "null") {

      if (isEdit) {
        if (feald === "participationEventCertificate") {
          this.editingEventData.participationEventCertificate = Array.from(event.target.files);
        } else if (feald === "ceritificates") {
          this.editingEventData.ceritificates = Array.from(event.target.files);
        }
      } else {
        if (feald === "participationEventCertificate") {
          this.AllCoActivitiEventsModelData[i].participationEventCertificate = Array.from(event.target.files);
        } else if (feald === "ceritificates") {
          this.AllCoActivitiEventsModelData[i].ceritificates = Array.from(event.target.files);
        }
      }
    },

    saveForm(option) {
      if (option === 0) {
        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllCourseModelData[this.courseModelFormCount.length - 1]
        if (!currentData?.courseName || !currentData?.courseType || !currentData?.courseDuration || !currentData?.instituteCourse) {
          return console.log("empty feald")
        }
        const newData = {
          courseName: '',
          courseType: '',
          courseDuration: '',
          creditsReceived: '',
          certificateFiles: '',
          instituteCourse: ''
        }
        // saving data to current object then push newobject
        this.AllCourseModelData.push(newData)
        // after done everthign clearn incress the size for forms through adding a new index to it
        this.courseModelFormCount.push(this.courseModelFormCount[this.courseModelFormCount.length - 1] + 1)
      } else if (option === 1) {

        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllInternshipModelData[this.AllInternshipModelData.length - 1]

        if (!currentData?.organizationName || !currentData?.proofFile || !currentData?.offerLetterFile || !currentData?.role) {
          return
        }
        const newData = {
          organizationName: '',
          managerName: '',
          proofFile: '',
          offerLetterFile: '',
          role: '',
          stipend: '',
          certificateFile: '',
          startingdate: '',
          endingdate: ''
        }
        // saving data to current object then push newobject
        this.AllInternshipModelData.push(newData)
        // after done everthign clearn incress the size for forms through adding a new index to it
        // this.courseModelFormCount.push(this.courseModelFormCount[this.courseModelFormCount.length - 1] + 1)
      } else if (option === 2) {

        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllJobModelData[this.AllJobModelData.length - 1]

        if (!currentData?.organizationName || !currentData?.offerLetterFile || !currentData?.appoinMentLetterFile || !currentData?.role || !currentData?.package) {
          return console.log("empty feald")
        }
        const newData = {
          organizationName: '',
          managerName: '',
          offerLetterFile: [],
          appoinMentLetterFile: [],
          role: '',
          package: '',
          joiningDate: ''
        }
        // saving data to current object then push newobject
        this.AllJobModelData.push(newData)
        // after done everthign clearn incress the size for forms through adding a new index to it
      } else if (option === 3) {

        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllHigherStudiesModelData[this.AllHigherStudiesModelData.length - 1]

        if (!currentData?.degreeName || !currentData.universityName) {
          return console.log("empty feald")
        }
        const newData = {
          degreeName: '',
          universityName: '',
          examSeat: '',
          examScoreFile: [],
          appoinMentLetterFile: [],
          examScore: ""
        }
        // saving data to current object then push newobject
        this.AllHigherStudiesModelData.push(newData)
        // after done everthign clearn incress the size for forms through adding a new index to it
      } else if (option === 4) {

        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllEntrepreneurshipModelData[this.AllEntrepreneurshipModelData.length - 1]

        if (!currentData?.companyName || !currentData.projectTitle || !currentData.projectDocFile || !currentData.proofFile) {
          return console.log("empty feald")
        }
        const newData = {
          companyName: '',
          domineName: '',
          projectTitle: '',
          projectDocFile: [],
          proofFile: []
        }
        // saving data to current object then push newobject
        this.AllEntrepreneurshipModelData.push(newData)
        // after done everthign clearn incress the size for forms through adding a new index to it
      } else if (option === 6) {

        // if any feald is empty in the form dont allow to aadd   return  and show err
        const currentData = this.AllCoActivitiEventsModelData[this.AllCoActivitiEventsModelData.length - 1]

        if (!currentData?.eventName || !currentData.eventType || !currentData.instituteEvent || !currentData.stateEvent || !currentData.eventDetails || !currentData.venueDetails || !currentData.dateOfEvent || !currentData.awardReceived || !currentData.eventOutcome) {
          return console.log("empty feald")
        }
        const newData = {
          eventName: '',
          eventType: '',
          instituteEvent: '',
          stateEvent: '',
          eventDetails: '',
          venueDetails: '',
          dateOfEvent: '',
          participationEventCertificate: [],
          awardReceived: '',
          eventOutcome: '',
          ceritificates: []
        }
        // saving data to current object then push newobject
        this.AllCoActivitiEventsModelData.push(newData)
      }
    },
    removeSelectedFile(fileKey, i, isEdit = false, fileFromserver = false) {
      if (isEdit && fileFromserver) {
        const afterFilter = this.editingCouseData.certificateFiles.filter((e) => e?.presignedUrl !== fileKey)
        this.editingCouseData.certificateFiles = afterFilter
        return
      }
      if (isEdit && !fileFromserver) {
        const afterFilter = this.editingCouseData.certificateFiles.filter((e) => e?.lastModified !== fileKey)
        this.editingCouseData.certificateFiles = afterFilter
        return
      }
      // normal case

      const afterFilter = this.AllCourseModelData[i].certificateFiles.filter((e) => e.lastModified !== fileKey)
      this.certificateFiles =
        this.AllCourseModelData[i].certificateFiles = afterFilter
    },
    removeSelectedFileFromInternship(fileKey, i, feald = 'null', isEdit = false, fileFromserver = false) {

      if (feald === 'proofFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingInternshipData.proofFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingInternshipData.proofFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingInternshipData.proofFile.filter((e) => e?.lastModified !== fileKey)
          this.editingInternshipData.proofFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllInternshipModelData[i].proofFile.filter((e) => e.lastModified !== fileKey)
        this.AllInternshipModelData[i].proofFile = afterFilter
      } else if (feald === 'offerLetterFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingInternshipData.offerLetterFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingInternshipData.offerLetterFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingInternshipData.offerLetterFile.filter((e) => e?.lastModified !== fileKey)
          this.editingInternshipData.offerLetterFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllInternshipModelData[i].offerLetterFile.filter((e) => e.lastModified !== fileKey)
        this.AllInternshipModelData[i].offerLetterFile = afterFilter
      } else if (feald === 'certificateFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingInternshipData.certificateFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingInternshipData.certificateFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingInternshipData.certificateFile.filter((e) => e?.lastModified !== fileKey)
          this.editingInternshipData.certificateFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllInternshipModelData[i].certificateFile.filter((e) => e.lastModified !== fileKey)
        this.AllInternshipModelData[i].certificateFile = afterFilter
      }
    },
    removeSelectedFileFromHigherStudies(fileKey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      if (feald === 'examScoreFile') {
        if (isEdit) {
          if (fileFromserver) {
            const afterFilter = this.editingHigherStudiesData.examScoreFile.filter((e) => e?.presignedUrl !== fileKey)
            this.editingHigherStudiesData.examScoreFile = afterFilter
            return
          } else {
            const afterFilter = this.editingHigherStudiesData.examScoreFile.filter((e) => e?.lastModified !== fileKey)
            this.editingHigherStudiesData.examScoreFile = afterFilter
            return
          }
        }
        // normal case

        const afterFilter = this.AllHigherStudiesModelData[i].examScoreFile.filter((e) => e.lastModified !== fileKey)
        this.AllHigherStudiesModelData[i].examScoreFile = afterFilter
      } else if (feald === 'appoinMentLetterFile') {
        if (isEdit) {
          if (fileFromserver) {
            const afterFilter = this.editingHigherStudiesData.appoinMentLetterFile.filter((e) => e?.presignedUrl !== fileKey)
            this.editingHigherStudiesData.appoinMentLetterFile = afterFilter
            return
          } else {
            const afterFilter = this.editingHigherStudiesData.appoinMentLetterFile.filter((e) => e?.lastModified !== fileKey)
            this.editingHigherStudiesData.appoinMentLetterFile = afterFilter
            return
          }
        }
        // normal case

        const afterFilter = this.AllHigherStudiesModelData[i].appoinMentLetterFile.filter((e) => e.lastModified !== fileKey)
        this.AllHigherStudiesModelData[i].appoinMentLetterFile = afterFilter
      }
    },
    removeSelectedFileFromJob(fileKey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      if (feald === 'offerLetterFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingJobData.offerLetterFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingJobData.offerLetterFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingJobData.offerLetterFile.filter((e) => e?.lastModified !== fileKey)
          this.editingJobData.offerLetterFile = afterFilter
          return
        }
        const afterFilter = this.AllJobModelData[i].offerLetterFile.filter((e) => e.lastModified !== fileKey)
        this.AllJobModelData[i].offerLetterFile = afterFilter
      } else if (feald === 'appoinMentLetterFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingJobData.appoinMentLetterFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingJobData.appoinMentLetterFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingJobData.appoinMentLetterFile.filter((e) => e?.lastModified !== fileKey)
          this.editingJobData.appoinMentLetterFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllJobModelData[i].appoinMentLetterFile.filter((e) => e.lastModified !== fileKey)
        this.AllJobModelData[i].appoinMentLetterFile = afterFilter
      }
    },
    removeSelectedFileFromEntrepreneurship(fileKey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      if (feald === 'projectDocFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingEntrepreneurshipData.projectDocFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingEntrepreneurshipData.projectDocFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingEntrepreneurshipData.projectDocFile.filter((e) => e?.lastModified !== fileKey)
          this.editingEntrepreneurshipData.projectDocFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllEntrepreneurshipModelData[i].projectDocFile.filter((e) => e.lastModified !== fileKey)
        this.AllEntrepreneurshipModelData[i].projectDocFile = afterFilter
      } else if (feald === 'proofFile') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingEntrepreneurshipData.proofFile.filter((e) => e?.presignedUrl !== fileKey)
          this.editingEntrepreneurshipData.proofFile = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingEntrepreneurshipData.proofFile.filter((e) => e?.lastModified !== fileKey)
          this.editingEntrepreneurshipData.proofFile = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllEntrepreneurshipModelData[i].proofFile.filter((e) => e.lastModified !== fileKey)
        this.AllEntrepreneurshipModelData[i].proofFile = afterFilter
      }
    },
    removeSelectedFileFromEvent(fileKey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      if (feald === 'participationEventCertificate') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingEventData.participationEventCertificate.filter((e) => e?.presignedUrl !== fileKey)
          this.editingEventData.participationEventCertificate = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingEventData.participationEventCertificate.filter((e) => e?.lastModified !== fileKey)
          this.editingEventData.participationEventCertificate = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllCoActivitiEventsModelData[i].participationEventCertificate.filter((e) => e.lastModified !== fileKey)
        this.AllCoActivitiEventsModelData[i].participationEventCertificate = afterFilter
      } else if (feald === 'ceritificates') {
        if (isEdit && fileFromserver) {
          const afterFilter = this.editingEventData.ceritificates.filter((e) => e?.presignedUrl !== fileKey)
          this.editingEventData.ceritificates = afterFilter
          return
        }
        if (isEdit && !fileFromserver) {
          const afterFilter = this.editingEventData.ceritificates.filter((e) => e?.lastModified !== fileKey)
          this.editingEventData.ceritificates = afterFilter
          return
        }
        // normal case

        const afterFilter = this.AllCoActivitiEventsModelData[i].ceritificates.filter((e) => e.lastModified !== fileKey)
        this.AllCoActivitiEventsModelData[i].ceritificates = afterFilter
      }
    },
    async deleteProfilePhoto() {
      const postData = {
        userId: this.userData.uId,
        profilePicture: {}
      }
      await this.userRepositoryInstance.updateStudentsProfileImage(postData)
      this.fetchData()
      this.deleteProfilePhotoModel = false
    },

    showFilePrevInModel(filekey, i, isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false
      if (isEdit && fileFromserver) {

        if (i.endsWith('.pdf')) {
          this.prevFileTypeisPdf = true
        }

        // as for new removing some qury params from the url of the image
        const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
        urlSearchParams.delete('AWSAccessKeyId');

        const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

        this.filePrevUrl = newUrl

        this.showFilePrev = true
        return
      }
      let selectedFile = ''
      // here we can give any fealds files so using this for show all fealds files
      if (isEdit && !fileFromserver) {
        selectedFile = this.editingCouseData.certificateFiles.find((e) => e?.lastModified === filekey)
      } else {
        selectedFile = this.AllCourseModelData[i].certificateFiles.find((e) => e.lastModified === filekey)
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };

      if (selectedFile.type === "application/pdf") {

        // alert("test")
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevInModelInternship(filekey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false

      let selectedFile = ''
      if (isEdit) {
        if (fileFromserver) {

          if (i.endsWith('.pdf')) {
            this.prevFileTypeisPdf = true
          }
          // as for new removing some qury params from the url of the image
          const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
          urlSearchParams.delete('AWSAccessKeyId');
          const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

          this.filePrevUrl = newUrl
          this.showFilePrev = true
          return
        } else {
          if (feald === 'proofFile') {
            selectedFile = this.editingInternshipData.proofFile.find((e) => e?.lastModified === filekey)
          } else if (feald === 'certificateFile') {
            selectedFile = this.editingInternshipData.certificateFile.find((e) => e?.lastModified === filekey)
          } else if (feald === 'offerLetterFile') {
            selectedFile = this.editingInternshipData.offerLetterFile.find((e) => e?.lastModified === filekey)
          }
        }
      } else {
        if (feald === 'proofFile') {
          selectedFile = this.AllInternshipModelData[i].proofFile.find((e) => e.lastModified === filekey)
        } else if (feald === 'certificateFile') {
          selectedFile = this.AllInternshipModelData[i].certificateFile.find((e) => e.lastModified === filekey)
        } else if (feald === 'offerLetterFile') {
          selectedFile = this.AllInternshipModelData[i].offerLetterFile.find((e) => e.lastModified === filekey)
        }
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };
      if (selectedFile.type === "application/pdf") {
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevInModelJob(filekey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false
      let selectedFile = ''

      if (isEdit) {
        if (fileFromserver) {

          if (i.endsWith('.pdf')) {
            this.prevFileTypeisPdf = true
          }

          // as for new removing some qury params from the url of the image
          const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
          urlSearchParams.delete('AWSAccessKeyId');

          const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

          this.filePrevUrl = newUrl

          this.showFilePrev = true
          return
        } else {
          if (feald === 'offerLetterFile') {
            selectedFile = this.editingJobData.offerLetterFile.find((e) => e?.lastModified === filekey)
          } else if (feald === 'appoinMentLetterFile') {
            selectedFile = this.editingJobData.appoinMentLetterFile.find((e) => e?.lastModified === filekey)
          }
        }
      } else {
        if (feald === 'offerLetterFile') {
          selectedFile = this.AllJobModelData[i].offerLetterFile.find((e) => e.lastModified === filekey)
        } else if (feald === 'appoinMentLetterFile') {
          selectedFile = this.AllJobModelData[i].appoinMentLetterFile.find((e) => e.lastModified === filekey)
        }
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };

      if (selectedFile.type === "application/pdf") {

        // alert("test")
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevInModelHigherStudies(filekey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false
      let selectedFile = ''

      if (isEdit) {
        if (fileFromserver) {
          if (i.endsWith('.pdf')) {
            this.prevFileTypeisPdf = true
          }

          // as for new removing some qury params from the url of the image
          const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
          urlSearchParams.delete('AWSAccessKeyId');

          const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

          this.filePrevUrl = newUrl

          this.showFilePrev = true
          return
        } else {
          if (feald === 'examScoreFile') {
            selectedFile = this.editingHigherStudiesData.examScoreFile.find((e) => e?.lastModified === filekey)
          } else if (feald === 'appoinMentLetterFile') {
            selectedFile = this.editingHigherStudiesData.appoinMentLetterFile.find((e) => e?.lastModified === filekey)
          }
        }
      } else {
        if (feald === 'examScoreFile') {
          selectedFile = this.AllHigherStudiesModelData[i].examScoreFile.find((e) => e.lastModified === filekey)
        } else if (feald === 'appoinMentLetterFile') {
          selectedFile = this.AllHigherStudiesModelData[i].appoinMentLetterFile.find((e) => e.lastModified === filekey)
        }
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };

      if (selectedFile.type === "application/pdf") {

        // alert("test")
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevInEntrepreneurship(filekey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false

      if (isEdit && fileFromserver) {

        if (i.endsWith('.pdf')) {
          this.prevFileTypeisPdf = true
        }

        // as for new removing some qury params from the url of the image
        const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
        urlSearchParams.delete('AWSAccessKeyId');

        const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

        this.filePrevUrl = newUrl

        this.showFilePrev = true
        return
      }
      let selectedFile = ''
      // here we can give any fealds files so using this for show all fealds files
      if (isEdit && !fileFromserver) {
        if (feald === 'projectDocFile') {
          selectedFile = this.editingEntrepreneurshipData.projectDocFile.find((e) => e?.lastModified === filekey)
        } else if (feald === 'proofFile') {
          selectedFile = this.editingEntrepreneurshipData.proofFile.find((e) => e?.lastModified === filekey)
        }
      } else {
        if (feald === 'projectDocFile') {
          selectedFile = this.AllEntrepreneurshipModelData[i].projectDocFile.find((e) => e.lastModified === filekey)
        } else if (feald === 'proofFile') {
          selectedFile = this.AllEntrepreneurshipModelData[i].proofFile.find((e) => e.lastModified === filekey)
        }
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };

      if (selectedFile.type === "application/pdf") {

        // alert("test")
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevEvent(filekey, i, feald = 'null', isEdit = false, fileFromserver = false) {
      this.prevFileTypeisPdf = false

      if (isEdit && fileFromserver) {

        if (i.endsWith('.pdf')) {
          this.prevFileTypeisPdf = true
        }

        // as for new removing some qury params from the url of the image
        const urlSearchParams = new URLSearchParams(filekey.split('?')[1]);
        urlSearchParams.delete('AWSAccessKeyId');

        const newUrl = filekey.split('?')[0] + '?' + urlSearchParams.toString();

        this.filePrevUrl = newUrl

        this.showFilePrev = true
        return
      }
      let selectedFile = ''
      // here we can give any fealds files so using this for show all fealds files
      if (isEdit && !fileFromserver) {
        if (feald === 'ceritificates') {
          selectedFile = this.editingEventData.ceritificates.find((e) => e?.lastModified === filekey)
        } else if (feald === 'participationEventCertificate') {
          selectedFile = this.editingEventData.participationEventCertificate.find((e) => e?.lastModified === filekey)
        }
      } else {
        if (feald === 'ceritificates') {
          selectedFile = this.AllCoActivitiEventsModelData[i].ceritificates.find((e) => e.lastModified === filekey)
        } else if (feald === 'participationEventCertificate') {
          selectedFile = this.AllCoActivitiEventsModelData[i].participationEventCertificate.find((e) => e.lastModified === filekey)
        }
      }
      // setup the fileURl from the fule
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (event) => {
        this.filePrevUrl = event.target.result;
      };

      if (selectedFile.type === "application/pdf") {

        // alert("test")
        this.prevFileTypeisPdf = true
      }
      this.showFilePrev = true
    },
    showFilePrevInProfle(url, fileName = '') {
      this.prevFileTypeisPdf = false
      if (fileName.endsWith('.pdf')) {
        this.prevFileTypeisPdf = true
      }
      // as for now removing some qury params from the url of the image
      const urlSearchParams = new URLSearchParams(url.split('?')[1]);
      urlSearchParams.delete('AWSAccessKeyId');

      const newUrl = url.split('?')[0] + '?' + urlSearchParams.toString();
      this.filePrevUrl = newUrl
      this.showFilePrev = true
    },
    async downloadFile(dataUrl, fileName = "courseDoc") {

      const filename = fileName;
      let blobUrl;

      if (dataUrl.startsWith("data:")) {
        blobUrl = this.dataUrlToBlobUrl(dataUrl);
      } else {
        const response = await fetch(dataUrl);
        // const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        blobUrl = URL.createObjectURL(blob);
      }

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      link.click();

      URL.revokeObjectURL(blobUrl);
    },

    extractFilenameFromUrl(url) {
      const pathname = new URL(url).pathname;
      return pathname.substring(pathname.lastIndexOf('/') + 1);
    },

    dataUrlToBlobUrl(dataUrl) {
      const parts = dataUrl.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const byteString = atob(parts[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: contentType });
      return URL.createObjectURL(blob);
    },

    accadamicAndCoActivitiDataEdit(data, option) {
      this.uploadingLoading = false
      if (option === 'course') {
        this.editingCouseData = data
        this.couseEditingModel = true
      } else if (option === 'internship') {
        this.editingInternshipData = data
        this.internshipEditingModel = true
      } else if (option === 'jobs') {
        this.editingJobData = data
        this.jobsEditingModel = true
      } else if (option === 'studies') {
        this.editingHigherStudiesData = data
        this.higherStudiesEditingModel = true
      } else if (option === 'entrepreneurship') {
        this.editingEntrepreneurshipData = data
        this.enterpreneurshipEditingModel = true
      } else if (option === 'events') {
        this.editingEventData = data
        this.editingEventModel = true
      }
    },
    deleteAccadamicAndCoActivityData(uuid, itemName, option) {

      this.userAccadamicAndCoActiviDeleteteModel = true
      this.deletingObjectId = uuid
      this.deletingObject = itemName
      this.deletetingOption = option
    },
    async couseDeletingAction() {
      //here can call the api accoding to the deleting opiton
      if (this.deletetingOption === 'courses') {
        await this.userRepositoryInstance.deleteStudentsAccdamicCouseDetails({ uuid: this.deletingObjectId, userId: this.userData.uId })
      } else if (this.deletetingOption === 'internship') {
        await this.userRepositoryInstance.deleteStudentsAccdamicInternshipDetails({ uuid: this.deletingObjectId, userId: this.userData.uId })
      } else if (this.deletetingOption === 'jobs') {
        await this.userRepositoryInstance.deleteStudentsAccdamicJobDetails({ uuid: this.deletingObjectId, userId: this.userData.uId })
      } else if (this.deletetingOption === 'studies') {
        await this.userRepositoryInstance.deleteStudentsAccdamicHigherStudiesDetails({ uuid: this.deletingObjectId, userId: this.userData.uId })
      } else if (this.deletetingOption === 'entrepreneurship') {
        await this.userRepositoryInstance.deleteStudentsAccdamicEntrepreneurshipDetails({ uuid: this.deletingObjectId, userId: this.userData.uId })
      } else if (this.deletetingOption === 'events') {
        await this.userRepositoryInstance.deleteStudentsCoActivitesEvents({ uuid: this.deletingObjectId, userId: this.userData.uId })
      }

      this.userAccadamicAndCoActiviDeleteteModel = false
      this.getAccadamicAndCoActivityDetails()
    },
    async saveCouseEdingData() {
      this.uploadingLoading = true
      const certificateFilePromises = [];
      const filesNeedToUploade = this.editingCouseData.certificateFiles.filter((file) => file.lastModified)
      const oldFiles = this.editingCouseData.certificateFiles.filter((file) => !file.lastModified)
      filesNeedToUploade.forEach(certificateFile => {
        certificateFilePromises.push(
          this.getSignedUrl(certificateFile, `${this.userData.uId}/certificates/`)
        );
      });
      const signedUrlsData = await Promise.all(certificateFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === filesNeedToUploade.length) {
        filesNeedToUploade.forEach((certificateFile, i) => {
          uploadPromises.push(this.uploadToSpaces(certificateFile, signedUrls[i]));
        });
        const uploadResults = await Promise.all(uploadPromises);

        const allUploadsSuccessful = uploadResults.every(result => result);
        if (allUploadsSuccessful) {
          const updatedCourse = Object.assign({}, this.editingCouseData);
          updatedCourse.certificateFiles = signedUrlsData.map((item, i) => ({
            name: filesNeedToUploade[i].name,
            presignedUrl: item.signedUrl
          }));
          updatedCourse.certificateFiles = [...oldFiles, ...updatedCourse.certificateFiles]

          // everything ok send to server
          const postData = {
            userId: this.userData.uId,
            accdamicCouseDetaile: updatedCourse
          }
          await this.userRepositoryInstance.updateStudentsAccdamicCouseDetails(postData)

          this.uploadingLoading = true
          this.couseEditingModel = false;
          this.getAccadamicAndCoActivityDetails()
          // updatedCourses.push(updatedCourse);
        } else {
          //  file faild to uplode

        }
      } else {
        // updatedCourses.push(course);

      }
    },
    async saveAccadamicAndCoActivitiEditingData(option) {
      try {
        if (option === 'internship') {
          const currentData = this.editingInternshipData
          if (!currentData?.organizationName || !currentData?.proofFile || !currentData?.offerLetterFile || !currentData?.role) {
            return
          }

          this.uploadingLoading = true
          // uplode filesin all fealds
          // prooofiles
          const neWInternshipproofFiles = await this.uplodeArrayOfFiles(this.editingInternshipData.proofFile.filter((file) => file.lastModified))
          const oldInternshipproofFiles = this.editingInternshipData.proofFile.filter((file) => !file.lastModified)
          this.editingInternshipData.proofFile = [...oldInternshipproofFiles, ...neWInternshipproofFiles]
          // offerLetterFile
          const newInternshipOfferLetterFiles = await this.uplodeArrayOfFiles(this.editingInternshipData.offerLetterFile.filter((file) => file.lastModified))
          const oldInternshipOfferLetterFiles = this.editingInternshipData.offerLetterFile.filter((file) => !file.lastModified)
          this.editingInternshipData.certificateFile = [...oldInternshipOfferLetterFiles, ...newInternshipOfferLetterFiles]
          // certificateFile
          const newInternshipCertificateFiles = await this.uplodeArrayOfFiles(this.editingInternshipData.certificateFile.filter((file) => file.lastModified))
          const oldInternshipCertificateFiles = this.editingInternshipData.certificateFile.filter((file) => !file.lastModified)
          this.editingInternshipData.certificateFile = [...oldInternshipCertificateFiles, ...newInternshipCertificateFiles]

          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicInternshipDetails: this.editingInternshipData
          }
          await this.userRepositoryInstance.updateStudentsAccdamicInternshipDetails(postData)

          this.internshipEditingModel = false;
          this.getAccadamicAndCoActivityDetails()
        } else if (option === 'job') {
          const currentData = this.editingJobData

          if (!currentData?.organizationName || !currentData?.offerLetterFile || !currentData?.appoinMentLetterFile || !currentData?.role || !currentData?.package) {
            return console.log("empty feald")
          }
          this.uploadingLoading = true
          const newfilesOffer = await this.uplodeArrayOfFiles(this.editingJobData.offerLetterFile.filter((file) => file.lastModified))
          const oldfilesOffer = this.editingJobData.offerLetterFile.filter((file) => !file.lastModified)
          this.editingJobData.offerLetterFile = [...oldfilesOffer, ...newfilesOffer]
          // appoinMentLetterFile
          const newappoinentfiles = await this.uplodeArrayOfFiles(this.editingJobData.appoinMentLetterFile.filter((file) => file.lastModified))
          const oldappoinentfiles = this.editingJobData.appoinMentLetterFile.filter((file) => !file.lastModified)
          this.editingJobData.certificateFile = [...oldappoinentfiles, ...newappoinentfiles]

          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicJobDetails: this.editingJobData
          }
          await this.userRepositoryInstance.updateStudentsAccdamicJobDetails(postData)

          this.jobsEditingModel = false
        } else if (option === 'studies') {
          const currentData = this.editingHigherStudiesData

          if (!currentData?.degreeName) {
            return console.log("empty feald")
          }
          this.uploadingLoading = true

          const newexamScoreFile = await this.uplodeArrayOfFiles(this.editingHigherStudiesData.examScoreFile.filter((file) => file.lastModified))
          const oldexamScoreFile = this.editingHigherStudiesData.examScoreFile.filter((file) => !file.lastModified)
          this.editingHigherStudiesData.examScoreFile = [...oldexamScoreFile, ...newexamScoreFile]
          const newappoinMentLetterFile = await this.uplodeArrayOfFiles(this.editingHigherStudiesData.appoinMentLetterFile.filter((file) => file.lastModified))
          const oldappoinMentLetterFile = this.editingHigherStudiesData.appoinMentLetterFile.filter((file) => !file.lastModified)
          this.editingHigherStudiesData.appoinMentLetterFile = [...oldappoinMentLetterFile, ...newappoinMentLetterFile]
          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicHigherStudiesDetails: this.editingHigherStudiesData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.updateStudentsAccdamicHigherStudies(postData)
          // Close modal

          this.higherStudiesEditingModel = false;
        } else if (option === 'entrepreneurship') {
          const currentData = this.editingEntrepreneurshipData

          if (!currentData?.companyName || !currentData.projectTitle || !currentData.projectDocFile || !currentData.proofFile) {
            return console.log("empty feald")
          }
          this.uploadingLoading = true
          const newprojectDocFile = await this.uplodeArrayOfFiles(this.editingEntrepreneurshipData.projectDocFile.filter((file) => file.lastModified))
          const oldprojectDocFile = this.editingEntrepreneurshipData.projectDocFile.filter((file) => !file.lastModified)
          this.editingEntrepreneurshipData.projectDocFile = [...oldprojectDocFile, ...newprojectDocFile]
          const newproofFile = await this.uplodeArrayOfFiles(this.editingEntrepreneurshipData.proofFile.filter((file) => file.lastModified))
          const oldproofFile = this.editingEntrepreneurshipData.proofFile.filter((file) => !file.lastModified)
          this.editingEntrepreneurshipData.proofFile = [...oldproofFile, ...newproofFile]

          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            accdamicEntrepreneurshipDetails: this.editingEntrepreneurshipData
          }

          // Call API to save updated course details

          await this.userRepositoryInstance.updateStudentsAccdamicEntrepreneurshipDetails(postData)
          // Close modal
          this.enterpreneurshipEditingModel = false;

          // this.uploadingLoading = false
        } else if (option === 'events') {
          const currentData = this.editingEventData

          if (!currentData?.eventName || !currentData.eventType || !currentData.instituteEvent || !currentData.stateEvent || !currentData.eventDetails || !currentData.venueDetails || !currentData.dateOfEvent || !currentData.awardReceived || !currentData.eventOutcome) {
            return console.log("empty feald")
          }
          this.uploadingLoading = true
          const newceritificates = await this.uplodeArrayOfFiles(this.editingEventData.ceritificates.filter((file) => file.lastModified))
          const oldceritificates = this.editingEventData.ceritificates.filter((file) => !file.lastModified)
          this.editingEventData.ceritificates = [...oldceritificates, ...newceritificates]
          const newparticiaptionFile = await this.uplodeArrayOfFiles(this.editingEventData.participationEventCertificate.filter((file) => file.lastModified))
          const oldparticiaptionFile = this.editingEventData.participationEventCertificate.filter((file) => !file.lastModified)
          this.editingEventData.participationEventCertificate = [...oldparticiaptionFile, ...newparticiaptionFile]

          this.uploadingLoading = false
          const postData = {
            userId: this.userData.uId,
            coActivityEventsDetails: this.editingEventData
          }
          // Call API to save updated course details
          await this.userRepositoryInstance.updateStudentsCoActivities(postData)
          // Close modal
          this.editingEventModel = false;

          // this.uploadingLoading = false
        }

        this.getAccadamicAndCoActivityDetails()
      } catch (error) {
        console.error(error)
      }
    },
    closeEditModel() {
      this.getAccadamicAndCoActivityDetails()
      this.couseEditingModel = false
    },
    onClickOutside() {
      // this.showfilterOptions = false
      this.showOptions = false
      // console.log("working")
    },
    onClickOutsideFilter() {
      this.showfilterOptions = false
    },
    downloadeAsPdf() {
      const content = this.$refs.printAsPdfErea;
      let options;
      if (this.isMobileView) {
        options = {
          margin: [-0.5, 1.3],
          filename: 'profile.pdf',
          enableLinks: true,
          image: { type: 'jpg', quality: 0.1 },
          html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 1000 },
          jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
        };
      } else {
        options = {
          margin: [0.5, 1],
          filename: 'certificate.pdf',
          enableLinks: true,
          image: { type: 'jpg', quality: 0.1 },
          html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 1000 },
          jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
        }
      }
      html2pdf().set(options).from(content).save()
    },
    async saveExtraUsersDetals() {
      const postData = {
        userId: this.userData.uId,
        extraStudentsDetails: this.extraStudentsDetails
      }
      try {
        await this.userRepositoryInstance.saveExtraStudentsDetails(postData)

      } catch (error) {
        console.log('error', error);
      }
      this.dialogExtraFieldSppu = false
      this.fetchData()
      if (this.isMobileView) {
        this.$router.push({
          name: 'activityMySubjects2'
        })
      } else {
        this.$router.push({
          name: 'activityMySubjects'
        })
      }
    }
  }
}
// for test
</script>
<style src='./activityProfile.css' scoped></style>
