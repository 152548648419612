import Vue from "vue";
import App from "./App.vue";
import { router } from "./Router"; // import router object
import { store } from "./Store/"; // import store (vuex) object
import Vuetify from "vuetify/lib";
import auth from "./Services/Firebase/auth";
import * as firebase from "firebase/app";
import "firebase/messaging";
import OtpInput from "@bachdgvn/vue-otp-input";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueApexCharts from 'vue-apexcharts'
import VueTour from 'vue-tour'
import './vue-tour.css'
// require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
if (firebase.messaging.isSupported()) {
  navigator.serviceWorker
    .register("firebase-messaging-sw.js", {
      scope: "firebase-cloud-messaging-push-scope"
    })
    .then((registration) => {
      const messaging = firebase.messaging();
      messaging.useServiceWorker(registration);
    })
    .catch((err) => {
      console.log(err);
    });
}

Vue.use(Vuetify);
const vuetify = new Vuetify({});
Vue.config.productionTip = false;
Vue.component("v-otp-input", OtpInput);
Vue.use(CKEditor);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  // use router object
  router,

  // use store (vuex) object
  store,
  VueTour,

  vuetify,

  beforeCreate() {
    auth.init(this);
  },

  render: (h) => h(App)
}).$mount("#app");

// if (process.env.NODE_ENV === "production") {
//   require("../disableLogs");
// }

// new Vue({
//   render: h => h(App)
// }).$mount('#app')
