import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamSubjectAndQuestions'

class ExamSubjectDetailsAndQuestions {
  constructor (context) {
    this.context = context
  }

  async createExamSubjectQuestions (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createExamSubjectQuestions, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateExamQuestionsExcel(data) {
    console.log('repo')
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExamQuestionsExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async parseExcel (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.parseExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExamSubjectQuestions (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExamSubjectQuestions, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default ExamSubjectDetailsAndQuestions
