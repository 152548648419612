import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Assignment'

class Assignment {
  constructor (context) {
    this.context = context
  }

  async getAssignmentById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignment
  }

  async getAssignmentsOfADivisionOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsOfADivisionOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }

  async getAssignmentsOfACourseYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssignmentsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignments
  }
}

export default Assignment
