import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/GetInstitutesAndSemesters";
import InstituteApiNames from "../ApiNames/Institute";
import InstituteDataStoreHelper from "../StoreHelpers/InstituteData";

class InstituteData {
  constructor(context) {
    this.context = context;
    this.instituteDataStoreHelper = new InstituteDataStoreHelper(context);
  }

  async getInstitutes(data) {
    let institutes =
      this.context.$store.getters["instituteData/get_applicableInstitutes"];
      console.log('institutes9', institutes);
    if (institutes && institutes.length > 0) {
      return institutes;
    } else {
      console.log(data);
      this.context.$store.commit("liveData/set_loading", true);
      console.log("before getting institutes");
      const response = await networkManager.getRequest(
        ApiNames.getInstitutesOfAUser,
        data
      );
      console.log("gotten institutes", JSON.stringify(response));
      institutes = response.instituteUsers || [];
      var isStudyMaterialDownloadable;
      console.log('instituteskdjskhvv', institutes);
      for (let i = 0; i < institutes.length; i++) {
        if (
          !this.instituteDataStoreHelper.getInstitute(institutes[i].instituteId)
        ) {
        const data = await this.getInstituteBranding(institutes[i].instituteId)
        console.log('dataafrbethta', data);
          institutes[i].instituteName = data.name
          institutes[i].instituteLogoLink = data.logo
          institutes[i].studentAccess = data.studentAccess
          isStudyMaterialDownloadable = data.isStudyMaterialDownloadable
          this.context.$store.commit("instituteData/set_studentAccess", {
            studentAccess: data.studentAccess
          })
          this.context.$store.commit("liveData/set_brandingName", data.instituteBrandingName)
          const isCorporate = data.isCorporateInstitute === undefined ? false : data.isCorporateInstitute
          console.log('isCorporate', isCorporate);
          this.context.$store.commit(
            "liveData/set_isCorporateInstitute",
            isCorporate
          );
        }
      }
      // console.log('data2', data9);
      console.log('isStudyMaterialDownloadable2', isStudyMaterialDownloadable);
      //  this.$store.commit("instituteData/set_studentAccess", {
      //   studentAccess: data9.studentAccess,
      // });
      this.context.$store.commit(
        "instituteData/set_applicableInstitutes",
        institutes
      );
      this.context.$store.commit(
        "instituteData/set_applicableInstitutes",
        institutes
      );
      this.context.$store.commit(
        "liveData/set_isStudyMaterialDownloadable",
        isStudyMaterialDownloadable
      );
      // this.context.$store.commit(
      //   "liveData/set_studentAccess",
      //   isStudyMaterialDownloadable
      // );
      this.context.$store.commit("liveData/set_loading", false);
      return institutes;
    }
  }

  async getInstituteName(data) {
    const response = await networkManager.getRequest(
      ApiNames.getNameOfInstitute,
      data
    );
    return response.instituteName;
  }

  async getInstituteBranding(instituteId) {
    console.log('instituteId', instituteId);
    const data = await networkManager.getRequest(
      InstituteApiNames.getInstituteBranding,
      { instituteId }
    );
    console.log('data8', data);
    const studentAccess = data.studentAccess
    const name =
      data.instituteStudentBrandingName !== undefined &&
        data.instituteStudentBrandingName !== null &&
        data.instituteStudentBrandingName !== ""
        ? data.instituteStudentBrandingName
        : data.instituteBrandingName;
    const logo =
      data.instituteStudentLogoLink !== undefined &&
        data.instituteStudentLogoLink !== null &&
        data.instituteStudentLogoLink !== ""
        ? data.instituteStudentLogoLink
        : data.instituteLogoLink;
    const isStudyMaterialDownloadable =
      data.isStudyMaterialDownloadable !== undefined &&
        data.isStudyMaterialDownloadable !== null &&
        data.isStudyMaterialDownloadable !== ""
        ? data.isStudyMaterialDownloadable
        : data.isStudyMaterialDownloadable;
    const isCorporateInstitute = data.isCorporateInstitute
    const instituteBrandingName = data.instituteBrandingName ?? ""
    return { name, logo, isStudyMaterialDownloadable, studentAccess, isCorporateInstitute, instituteBrandingName }
  }
}

export default InstituteData;
