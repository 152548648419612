import user from '../Store/Modules/user'
export default function validateTravel (to, from, next) {
  console.log(to, from, next)
  if (to.meta.guestOnly) {
    if (user.state.userData) {
      next('/activitySelectInstituteNSemester')
    } else {
      next()
    }
  } else {
    if (to.meta.requireAuth) {
      if (user.state.userData) {
        next()
      } else {
        next('/activityWelcome')
      }
    } else {
      next('/activityWelcome')
    }
  }
};
