import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/InfrastructureReference'

class InfrastructureReference {
  constructor (context) {
    this.context = context
  }

  async getRoomName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRoomName, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomName
  }

  async getRoomNames (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRoomNames, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.roomName
  }
}

export default InfrastructureReference
