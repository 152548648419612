<template>
  <div class="timeSlotCard">
    <v-card-text v-if="!isMobileView">
      <v-row>
        <v-col cols="12">
          <v-card flat style="background-color: transparent">
            <v-card-title>
              <div v-if="afterUtc" style="
                    font-size: 1rem;
                    font-weight: bold;
                    color: rgba(53, 53, 53, 0.8);
                    margin-bottom: 10px;
                  ">
                  <!-- {{ timeSlot }} -->
                {{ timeSlot.startTime }} -
                {{ timeSlot.endTime }}
              </div>
              <div v-else style="
                    font-size: 1rem;
                    font-weight: bold;
                    color: #050992;
                    margin-bottom: 10px;
                  ">
                {{ timeStringConverter(timeSlot.startTime) }} -
                {{ timeStringConverter(timeSlot.endTime) }}
              </div>
              <v-btn fab text small style="margin-top: -10px; margin-left: 10px" v-if="timeSlot.isConducted">
                <v-icon :color="isEdcInstitute? '#158873': 'green'">
                  mdi-checkbox-marked-circle</v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon style="color: green; margin-top: -10px; margin-left: 10px"
                    v-if="timeSlot.redirectionUrl && !timeSlot.isConducted" v-on="on" class="blink">mdi-video-plus
                  </v-icon>
                </template>
                <span v-if="timeSlot.redirectionUrl">Online Lecture is ongoing</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn v-if="isLectureOngoing(timeSlot)" small outlined :color="isEdcInstitute ? '#158873' :'#ff4f1f'" @click="joinLecture(timeSlot)"
                class="join" :loading="isJoiningLecture" :disabled="isJoiningLecture || timeSlot.status === 6"
                style="margin-top: -10px">Join</v-btn>
              <div v-else>
                <v-btn class="join"
                  v-if="(timeSlot.isRecordingEnableForStudents === true || timeSlot.studentFound === true) && timeSlot.status !== 6"
                  small outlined :color="isEdcInstitute ? '#158873' :'#ff4f1f'" @click="getLectureRecording(timeSlot)" :loading="isFetchingRecording"
                  style="margin-top: -10px">Recording</v-btn>
                <v-btn v-else small outlined :color="isEdcInstitute ? '#158873' :'#ff4f1f'" :disabled=true @click="getLectureRecording(timeSlot)"
                  :loading="isFetchingRecording" style="margin-top: -10px">Recording</v-btn>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <v-card style="
              border-radius: 10px;
              box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
            ">
          <div style="height: auto">
            <v-row style="padding: 10px 30px 5px 20px">
              <v-col cols="6">
                <div style="margin-bottom: 10px">
                  <strong v-if="timeSlot.title !== ''" style="font-size: large">{{
                    timeSlot.title
                  }}</strong>
                  <strong  style="font-size: large">{{
                    timeSlot.subjectName
                  }}</strong>
                </div>
                <div>
                  <strong style="font-size: medium">
                    <v-icon>mdi-map-marker</v-icon>{{ timeSlot.roomName }}
                  </strong>
                </div>
              </v-col>
              <v-col cols="6">
                <div style="margin-bottom: 10px">
                  <strong v-if="timeSlot.valueAddedActivityId" style="font-size: medium; color: #050992">{{
                    timeSlot.subjectType
                  }}</strong>
                  <strong v-else style="font-size: medium; color: #a7a7a7">{{
                    timeSlot.subjectType
                  }}</strong>
                </div>
                <div>
                  <!-- <strong v-if="timeSlot.status === 0" style="
                      font-size: medium;
                      color: green;
                      background-color: rgb(0, 128, 0, 0.5);
                      padding-left: 20px;
                      padding-right: 20px;
                      padding-top: 3px;
                      padding-bottom: 3px;
                      border-radius: 5px;
                    ">{{ lectureTypes[timeSlot.status] }}</strong> -->
                  <strong v-if="timeSlot.status === 1" style="
                        font-size: medium;
                        color:red;
                        background-color: white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 2" style="
                        font-size: medium;
                        color: red;
                        background-color: white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 3" style="
                        font-size: medium;
                        color:red;
                        background-color:white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <!-- <strong v-if="timeSlot.status === 4" style="
                      font-size: medium;
                      color: green;
                      background-color: rgb(0, 128, 0, 0.5);
                      padding-left: 20px;
                      padding-right: 20px;
                      padding-top: 3px;
                      padding-bottom: 3px;
                      border-radius: 5px;
                    ">{{ lectureTypes[timeSlot.status] }}</strong> -->
                  <strong v-if="timeSlot.status === 5" style="
                        font-size: medium;
                        color: red;
                        background-color:white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 6" style="
                        font-size: medium;
                        color: red;
                        background-color:white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div style="padding: 10px">
              <p>
                <v-icon>mdi-account-outline</v-icon> {{ timeSlot.name }}
              </p>
              <div v-if="timeSlot.lecturesTopics" style="margintop: 14px">
                <div v-for="lectures in timeSlot.lecturesTopics" :key="lectures.topicId">
                  <p>
                    <v-icon>mdi-clipboard-text-outline</v-icon>
                    {{ lectures.topicName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="timeSlot.status === 0" style="
              background-color: green;
              width: 20px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0 10px 10px 0;
            "></div> -->
          <div v-if="timeSlot.status === 1" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 2" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 3" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <!-- <div v-if="timeSlot.status === 4" style="
              background-color: green;
              width: 20px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0 10px 10px 0;
            "></div> -->
          <div v-if="timeSlot.status === 5" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 6" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
        </v-card>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-col cols="12">
          <v-card flat style="background-color: transparent">
            <v-card-title>
              <div v-if="afterUtc" style="
                    font-size: x-large;
                    font-weight: bold;
                    color: rgba(53, 53, 53, 0.5);
                    margin-bottom: 10px;
                  ">
                {{ timeSlot.startTime }} -
                {{ timeSlot.endTime }}
              </div>
              <div v-else style="
                    font-size: x-large;
                    font-weight: bold;
                    color: #050992;
                    margin-bottom: 10px;
                  ">
                {{ timeStringConverter(timeSlot.startTime) }} -
                {{ timeStringConverter(timeSlot.endTime) }}
              </div>
              <v-btn fab text small style="margin-top: -10px; margin-left: 10px" v-if="timeSlot.isConducted">
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-btn>
              <v-btn fab text small style="margin-top: -10px; margin-left: 10px" v-if="timeSlot.redirectionUrl">
                <v-icon style="color: green" v-on="on" class="blink">mdi-video-plus</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="isLectureOngoing(timeSlot)" small outlined color="#ff4f1f" @click="joinLecture(timeSlot)"
                :loading="isJoiningLecture" :disabled="isJoiningLecture || timeSlot.status === 6"
                style="margin-top: -10px">Join</v-btn>
              <div v-else>
                <v-btn
                  v-if="(timeSlot.isRecordingEnableForStudents === true || timeSlot.studentFound === true) && timeSlot.status !== 6"
                  small outlined color="#ff4f1f" @click="getLectureRecording(timeSlot)" :loading="isFetchingRecording"
                  style="margin-top: -10px">Recording</v-btn>
                <v-btn v-else small outlined color="#ff4f1f" :disabled=true @click="getLectureRecording(timeSlot)"
                  :loading="isFetchingRecording" style="margin-top: -10px">Recording</v-btn>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <v-card style="
              border-radius: 10px;
              box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
            ">
          <div style="height: auto">
            <v-row style="padding: 10px 30px 5px 20px">
              <v-col cols="12" style="padding-bottom: 0">
                <div style="margin-bottom: 10px">
                  <strong v-if="timeSlot.title !== ''" style="font-size: large">{{
                    timeSlot.title
                  }}</strong>
                  <strong style="font-size: large">{{
                    timeSlot.subjectName
                  }}</strong>
                </div>
                <div>
                  <strong style="font-size: medium">
                    <v-icon>mdi-map-marker</v-icon>{{ timeSlot.roomName }}
                  </strong>
                </div>
              </v-col>
              <v-col cols="12">
                <div style="margin-bottom: 10px">
                  <strong style="font-size: medium; color: #a7a7a7">{{
                    timeSlot.subjectType
                  }}</strong>
                </div>
                <div>
                  <!-- <strong v-if="timeSlot.status === 0" style="
                      font-size: medium;
                      color: red;
                      background-color: white;
                      padding-left: 20px;
                      padding-right: 20px;
                      padding-top: 3px;
                      padding-bottom: 3px;
                      border-radius: 5px;
                    ">{{ lectureTypes[timeSlot.status] }}</strong> -->
                  <strong v-if="timeSlot.status === 1" style="
                        font-size: medium;
                        color: red;
                        background-color: white
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 2" style="
                        font-size: medium;
                        color: red;
                        background-color:white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 3" style="
                        font-size: medium;
                        color: red;
                        background-color: white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <!-- <strong v-if="timeSlot.status === 4" style="
                      font-size: medium;
                      color: red;
                      background-color:white;
                      padding-left: 20px;
                      padding-right: 20px;
                      padding-top: 3px;
                      padding-bottom: 3px;
                      border-radius: 5px;
                    ">{{ lectureTypes[timeSlot.status] }}</strong> -->
                  <strong v-if="timeSlot.status === 5" style="
                        font-size: medium;
                        color: red;
                        background-color: white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                  <strong v-if="timeSlot.status === 6" style="
                        font-size: medium;
                        color: red;
                        background-color: white;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        border-radius: 5px;
                      ">{{ lectureTypes[timeSlot.status] }}</strong>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div style="padding: 10px">
              <v-icon>mdi-account-outline</v-icon> {{ timeSlot.name }}
              <div v-if="timeSlot.lecturesTopics">
                <div v-for="lectures in timeSlot.lecturesTopics" :key="lectures.topicId" style="margintop: 12px">
                  <p>
                    <v-icon>mdi-clipboard-text-outline</v-icon>
                    {{ lectures.topicName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="timeSlot.status === 0" style="
              background-color: green;
              width: 20px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0 10px 10px 0;
            "></div> -->
          <div v-if="timeSlot.status === 1" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 2" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 3" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 4" style="
                background-color: white;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 5" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
          <div v-if="timeSlot.status === 6" style="
                background-color: green;
                width: 20px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 10px 10px 0;
              "></div>
        </v-card>
      </div>
    </v-card-text>

    <v-dialog v-model="showRecording" v-if="showRecording" width="100vw">
      <div style="
            margin-left: 25vw;
            margin-top: 30vh;
            position: absolute;
            height: 10px;
            width: 200px;
            opacity: 0.2;
          ">
        <v-list>
          <v-list-item style="margin-top: 10px" class="logo-row">
            <img class="image" v-bind:src="brandingLogo" style="height: 100px; width: 200px" />
          </v-list-item>
          <v-list-item style="margin-top: 5px" class="logo-row">
            <div class="text" style="text-align: center; color: black">
              {{ brandingName }}
            </div>
          </v-list-item>
          <v-list-item style="margin-top: 5px" class="logo-row">
            <div class="text" style="text-align: center; color: black">
              {{ firstName }} {{ middleName }} {{ lastName }}
            </div>
          </v-list-item>
        </v-list>
      </div>
      <iframe :src="recordingLink" height="800" width="500" name="iframe_a" title="Lecture Recording"></iframe>
    </v-dialog>
    <v-dialog v-model="showQrCodeScanner" v-if="showQrCodeScanner == true" width="auto">
      <v-card>
        <v-card-title>
          Scanner
        </v-card-title>

        <v-card-text>
          <div class="con">
            <qrcode-stream class="qr-code-scanner" @decode="onDecode" @init="onInit"></qrcode-stream>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FFFFFF" text @click="showQrCodeScanner = false"
            style="background-color: #FF4F1F; margin: 0 auto; display: block;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showQrAttendanceResult" v-if="showQrAttendanceResult == true" width="390px">
      <v-card style="display: flex; align-items: center;flex-direction: column;">
        <v-card-title>
          QR Code Attendance
        </v-card-title>

        <v-card-text v-if="attendanceResult == true"
          style="text-align: center;font-weight: 500;font-size: 20px;color: #000;">
          <v-icon size="300" color='success'>mdi mdi-check-circle</v-icon>
          {{ socketioServiceInstance.message }}
        </v-card-text>
        <v-card-text v-else style="text-align: center;font-weight: 500;font-size: 20px;color: #000;">
          <v-icon size="300" color='red'>mdi mdi-close-circle</v-icon>
          {{ socketioServiceInstance.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FFFFFF" text @click="showQrAttendanceResult = false"
            style="background-color: #FF4F1F; margin: 0 auto; display: block;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { timeStringConverter, convertUTCDateToLocalDate } from "../../Services/Utils/DateTimeFormatter";
import { lectureTypes } from "../../Constants/Utils/Statics";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import apiV3 from '../../NetworkManager/apiV3'

export default {
  name: "timeSlotCard",
  props: [
    "timeSlot",
    "trigerDialog",
    "brandingName",
    "brandingLogo",
    "firstName",
    "middleName",
    "lastName"
  ],
  data() {
    return {
      lectureTypes: lectureTypes,
      selectedInstitute: '',
      isMobileView: false,
      isEdcInstitute: false,
      afterUtc: false,
      currentTime: new Date(),
      isFetchingRecording: false,
      isJoiningLecture: false,
      userData: null,
      loading: false,
      showRecording: false,
      progressBar: false,
      recordingLink: "",
      hrs: 0
    };
  },
  created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userData = this.$store.getters["user/get_userData"];
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    if (!(this.timeSlot.startTime.split('_').length > 1)) {
      this.afterUtc = true
      this.timeSlot.start = this.timeSlot.startTime
      this.timeSlot.end = this.timeSlot.endTime
      this.timeSlot.startTime = convertUTCDateToLocalDate(new Date(this.timeSlot.startTime))
      this.timeSlot.endTime = convertUTCDateToLocalDate(new Date(this.timeSlot.endTime))
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }

      console.log('isLectureOngoing', this.isLectureOngoing(this.timeSlot));
    }
  },
  methods: {
    timeStringConverter(str) {
      return timeStringConverter(str);
    },
    isLectureOngoing(timeSlot) {
      console.log('timeSlot', timeSlot);
      if (this.afterUtc === true) {
        const lectureEndTime = new Date(timeSlot.end)
        // const lectureStartTime = new Date(timeSlot.start)
        // const lectureDate = new Date(new Date(timeSlot.dateTime))
        const todaysDate = new Date()
        // // To support future lectures
        // // If a lecture is in future, it must show Join button
        // // and not Recording button
        if (lectureEndTime.toISOString() > todaysDate.toISOString()) {
          return true
        } else {
          return false
        }
      } else {
        const lectureEndTime = timeSlot.endTime
        const lectureDate = new Date(timeSlot.dateTime).setHours(0, 0, 0, 0)
        const todaysDate = new Date(this.currentTime).setHours(0, 0, 0, 0)

        const d = this.currentTime;
        const hours = ("0" + d.getHours()).slice(-2);
        const minutes = ("0" + d.getMinutes()).slice(-2);
        const currentTimeParsed = parseFloat([hours, minutes].join("."));
        const lectureEndTimeParsed = parseFloat(
          lectureEndTime.split("_").join(".")
        );

        // To support future lectures
        // If a lecture is in future, it must show Join button
        // and not Recording button
        if (lectureDate > todaysDate) {
          return true
        } else if (lectureDate === todaysDate) {
          if (currentTimeParsed >= lectureEndTimeParsed) {
            return false;
          } else {
            return true;
          }
        } else {
          return false
        }
      }
    },
    async joinLecture(timeSlot) {
      try {
        this.isJoiningLecture = true;
        const { redirectUrl } = await apiV3.getRequest('/meeting/join', {
          lectureId: timeSlot.lectureId,
          fullName: `${this.userData.firstName} ${this.userData.lastName}`,
          userRole: 0,
          uId: this.userData.uId
        })
        window.open(redirectUrl, "_blank")
      } catch (err) {
        if (err.response.status === 404) {
          alert('Lecture not started')
          return
        }
        alert("An error occured, refresh and try again");
      } finally {
        this.isJoiningLecture = false;
      }
    },
    async getLectureRecording(timeSlot) {
      try {
        this.isFetchingRecording = true;
        if (timeSlot.recordingLink) {
          this.recordingLink = timeSlot.recordingLink
          this.showRecording = true;
        } else {
          alert('No lecture recordings found')
        }
      } catch (err) {
        console.log(err);
        alert("An error occured, refresh or try again later")
      } finally {
        this.isFetchingRecording = false;
      }
    }
  }
};
</script>

<style>
.con {
  width: 300px;
  height: 300px;
}

.qr-code-scanner {
  border: 2px solid #42b983;
  border-style: dashed;
  border-radius: 5px;
}
</style>
