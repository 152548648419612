<template src="./activityCourseQuiz.html"></template>

<script>
// import SubjectsRepository from "../../Repository/Subjects";
import moment from "moment";
import 'moment-duration-format';
import CourseRepository from "../../Repository/Course";
export default {
    name: "activityQuizResult",
    props: ["prop_courseDetails", "prop_course", 'prop_subjectInfo', "prop_indexSelectedSubSection", "prop_indexOfSelectedSection", 'prop_subsectionCompletedCount', "prop_subject"],
    data() {
        return {
            isMobileView: false,
            isLoading: false,
            course: {},
            courseSections: [],
            studentAns: 0,
            secondCount: 0,
            counter: 0,
            indexSelectedSubSection: this.prop_indexSelectedSubSection,
            indexOfSelectedSection: this.prop_indexOfSelectedSection,
            timerCount: this.prop_course.durationOfquiz * 60000,
            timeTaken: 0,
            option: '',
            countdown: '',
            formatedCount: ''
        }
    },
    created() {
        window.scroll(0, 0)
        this.CourseRepositoryInstance = new CourseRepository(this);
        this.course = this.prop_course;
        console.log('this.course1', this.course);
        this.courseName = this.prop_course.courseName
        this.$store.commit("liveData/set_selectedActivityName", this.prop_course.courseName);
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.courseSections = this.prop_courseDetails.sections
        this.countdown = Number(this.course.durationOfquiz) * 60
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    },
    mounted() {
        this.stopCountdown = setInterval(() => {
            this.countdown -= 1;
            this.formatedCount = moment.duration(this.countdown, "seconds").format("m:ss")
            if (!this.countdown) {
                clearInterval(this.stopCountdown);
                this.countdown = 0
            }
            if (this.formatedCount === '0') {
                this.submitQuizByTime()
            }
        }, 1000);
    },
    computed: {
        formatedCountdown() {
            return moment.duration(this.countdown, "seconds").format("m:ss");
        }
    },
    methods: {
        async submitQuiz(type) {
            let newSubsectionCompletedCount = this.prop_subsectionCompletedCount;
            // console.log('this.prop_course', this.prop_subjectInfo, this.indexOfSelectedSection, this.indexSelectedSubSection, this.prop_courseDetails, this.prop_subject)
            if (this.prop_course.studentAnswers.length === 0) {
                newSubsectionCompletedCount = newSubsectionCompletedCount + 1;
            }
            this.course.quizMarks = 0
            this.course.studentMarks = 0
            this.course.isSubsectionCompleted = true
            const tempAns = []
            let totalMarks = 0
            let timeTaken = 0
            clearInterval(this.stopCountdown);
            this.countdown = 0
            this.course.questions.map((obj) => {
                if (obj.studentTempAnswers === obj.answer && !isNaN(parseInt(obj.marks))) {
                    this.course.studentMarks += parseInt(parseInt(obj.marks))
                }
                if (!isNaN(parseInt(obj.marks))) totalMarks = parseInt(totalMarks) + parseInt(obj.marks)
                timeTaken = ((this.prop_course.durationOfquiz * 60) - ((this.countdown)))
                tempAns.push(obj)
            })
            const studPercentage = parseInt(this.course.studentMarks) / parseInt(totalMarks) * 100
            if (studPercentage >= this.course.passingPercentage) {
                this.course.subsectionStudentCredit = this.course.subsectionCredit
            }
            this.course.studentAnswers.push({
                studentMarks: this.course.studentMarks,
                timeTaken: timeTaken,
                studPercentage: studPercentage,
                answers: tempAns
            })
            let text = "";
            if (type === "submit") {
                text = "Are you sure?    you want to submit the Quiz"
            } else {
                text = "Going back will submit the current test ? Are you sure you want to continue ?"
            }
            this.courseSections[this.indexOfSelectedSection].subSections[this.indexSelectedSubSection] = this.course
            const confirmation = await this.$confirm(text);
            this.courseSections.forEach((section) => {
                section.sectionId = section._id;
            })
            if (confirmation) {
                try {
                    const objToPush = {
                        instituteId: this.prop_courseDetails.instituteId,
                        semId: this.prop_courseDetails.semId,
                        department: this.prop_courseDetails.department,
                        courseYear: this.prop_courseDetails.courseYear,
                        courseId: this.prop_courseDetails.courseId,
                        uId: this.selectedSemester.uId,
                        sections: this.courseSections,
                        completedSubsections: newSubsectionCompletedCount
                    }
                    await this.CourseRepositoryInstance.createStudentCourse(objToPush);
                    if (this.isMobileView) {
                        this.$router.push({
                            name: "activityCourseMaterialRevamped2",
                            params: {
                                prop_course: this.prop_subjectInfo,
                                prop_indexOfSelectedSection: this.indexOfSelectedSection,
                                prop_indexSelectedSubSection: this.indexSelectedSubSection,
                                prop_courseDetails: this.prop_courseDetails,
                                prop_subject: this.prop_subject
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "activityCourseMaterialRevamped",
                            params: {
                                prop_course: this.prop_subjectInfo,
                                prop_indexOfSelectedSection: this.indexOfSelectedSection,
                                prop_indexSelectedSubSection: this.indexSelectedSubSection,
                                prop_courseDetails: this.prop_courseDetails,
                                prop_subject: this.prop_subject
                            }
                        });
                    }
                    console.log(this.courseSections);
                } catch (e) {
                    console.log(e)
                }
            }
        },
        async submitQuizByTime() {
            let newSubsectionCompletedCount = this.prop_subsectionCompletedCount;
            if (this.prop_course.solutionFile.length === 0) {
                newSubsectionCompletedCount = newSubsectionCompletedCount + 1;
            }
            this.course.quizMarks = 0
            this.course.studentMarks = 0
            this.course.isSubsectionCompleted = true
            const tempAns = []
            let totalMarks = 0
            let timeTaken = 0
            this.course.questions.map((obj) => {
                if (obj.studentTempAnswers === obj.answer) {
                    this.course.studentMarks += parseInt(parseInt(obj.marks))
                }
                totalMarks = parseInt(totalMarks) + parseInt(parseInt(obj.marks))
                timeTaken = ((this.prop_course.durationOfquiz * 60000) - ((this.timerCount)))
                tempAns.push(obj)
            })
            const studPercentage = parseInt(this.course.studentMarks) / parseInt(totalMarks) * 100
            if (studPercentage >= this.course.passingPercentage) {
                this.course.subsectionStudentCredit = this.course.subsectionCredit
            }
            this.course.studentAnswers.push({
                studentMarks: this.course.studentMarks,
                timeTaken: timeTaken,
                studPercentage: studPercentage,
                answers: tempAns
            })
            this.courseSections[this.indexOfSelectedSection].subSections[this.indexSelectedSubSection] = this.course
            try {
                    const objToPush = {
                        instituteId: this.prop_courseDetails.instituteId,
                        semId: this.prop_courseDetails.semId,
                        department: this.prop_courseDetails.department,
                        courseYear: this.prop_courseDetails.courseYear,
                        courseId: this.prop_courseDetails.courseId,
                        uId: this.selectedSemester.uId,
                        sections: this.courseSections,
                        completedSubsections: newSubsectionCompletedCount
                    }
                    await this.CourseRepositoryInstance.createStudentCourse(objToPush);
                    if (this.isMobileView) {
                        this.$router.push({
                            name: "activityCourseMaterialRevamped2",
                            params: {
                                prop_course: this.prop_subjectInfo,
                                prop_indexOfSelectedSection: this.indexOfSelectedSection,
                                prop_indexSelectedSubSection: this.indexSelectedSubSection,
                                prop_courseDetails: this.prop_courseDetails,
                                prop_subject: this.prop_subject
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "activityCourseMaterialRevamped",
                            params: {
                                prop_course: this.prop_subjectInfo,
                                prop_indexOfSelectedSection: this.indexOfSelectedSection,
                                prop_indexSelectedSubSection: this.indexSelectedSubSection,
                                prop_courseDetails: this.prop_courseDetails,
                                prop_subject: this.prop_subject
                            }
                        });
                    }
                    console.log(this.courseSections);
                } catch (e) {
                    console.log(e)
                }
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseMaterialRevamped2",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails,
                        quizeSubmitted: true
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseMaterialRevamped",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails,
                        quizeSubmitted: true
                    }
                });
            }
        },
        getCounter(time) {
            const tempTime = time / 60000
            const t = tempTime.toString()
            if (time === 0) {
                this.submitQuiz()
            }
            return t.split('.')[0] + ":" + this.secondCount
        },
        goBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseMaterialRevamped2",
                    params: {
                        prop_courseDetails: this.prop_courseDetails
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseMaterialRevamped",
                    params: {
                        prop_courseDetails: this.prop_courseDetails
                    }
                });
            }
        }
    }
}

</script>

<style scoped src="./activityCourseQuiz.css"></style>
