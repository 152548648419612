class InstituteData {
  constructor(context) {
    this.context = context
    this.namespace = 'instituteData/'
  }

  getSelectedInstitute() {
    return this.context.$store.getters[this.namespace + 'get_selectedInstitute']
  }

  getSelectedSemester() {
    return this.context.$store.getters[this.namespace + 'get_selectedSemester']
  }

  getApplicableInstitutes() {
    return this.context.$store.getters[this.namespace + 'get_applicableInstitutes']
  }

  getApplicableSemesters() {
    return this.context.$store.getters[this.namespace + 'get_applicableSemesters']
  }

  getInstitute(instituteId) {
    const applicableInstitutes = this.context.$store.getters[this.namespace + 'get_applicableInstitutes']
    const institute = applicableInstitutes.filter((institute) => institute.instituteId === instituteId)
    return institute.length > 0 ? institute[0] : undefined
  }

  getExamApiKey() {
    return this.context.$store.getters[this.namespace + 'get_examApiKey']
  }

  getSelectedInstituteData() {
    return this.context.$store.getters[this.namespace + 'selectedInstitute']
  }

  setSelectedInstitute(selectedInstitute) {
    this.context.$store.dispatch(this.namespace + 'set_selectedInstitute', selectedInstitute)
  }

  setSelectedSemester(selectedSemester) {
    this.context.$store.dispatch(this.namespace + 'set_selectedSemester', selectedSemester)
  }

  setApplicableInstitutes(applicableInstitutes) {
    this.context.$store.dispatch(this.namespace + 'set_applicableInstitutes', applicableInstitutes)
  }

  setApplicableSemesters(applicableSemesters) {
    this.context.$store.dispatch(this.namespace + 'set_applicableSemesters', applicableSemesters)
  }

  setExamApiKey(examApiKey) {
    this.context.$store.dispatch(this.namespace + 'set_examApiKey', examApiKey)
  }

  setSelectedInstituteData(selectedInstitute) {
    this.context.$store.dispatch(this.namespace + 'selectInstitute', selectedInstitute)
  }

  reset() {
    this.context.$store.commit(this.namespace + 'reset')
  }
}

export default InstituteData
