export default {
  login: "/auth/login",
  getUserProfile: "/users/getUser",
  getUserAuthentication: "/auth/getUserAuthenticationsOfAUser",
  deleteRegistrationToken: "/auth/deleteRegistrationToken",
  getFullNameOfUser: "/users/getFullNameOfUser",
  getFullNameOfUsers: "/users/getFullNameOfUsers",
  updateFirstName: "/users/updateFirstName",
  updateMiddleName: "/users/updateMiddleName",
  updateLastName: "/users/updateLastName",
  updateDateOfBirth: "/users/updateDateOfBirth",
  updateGender: "/users/updateGender",
  updateEmail: "/auth/updateEmail",
  updatePhone: "/auth/updatePhone",
  addPhone: "/auth/createUserAuthenticationWithPhone",
  deleteEmail: "/auth/deletUserAuthenticationUsingEmail",
  deletePhone: "/auth/deletUserAuthenticationUsingPhone",
  loginWithUserNameAndPassword: "/auth/loginWithUserNameAndPassword",
  passwordUpdate: "/auth/passwordUpdate",
  forgotPassword: "/auth/loginPasswordByUserName",
  saveStudentsAccdamicCouseDetails: "/users/saveStudentsAccdamicCouseDetails",
  getAccadamicAndCoActivityDetails: "/users/getAccadamicAndCoActivityDetails",
  deleteStudentsAccdamicCouseDetails: "/users/deleteStudentsAccdamicCouseDetails",
  deleteStudentsAccdamicInternshipDetails: "/users/deleteStudentsAccdamicInternshipDetails",
  deleteStudentsAccdamicJobDetails: "/users/deleteStudentsAccdamicJobDetails",
  deleteStudentsAccdamicHigherStudies: "/users/deleteStudentsAccdamicHigherStudiesDetails",
  deleteStudentsAccdamicEntrepreneurshipDetails: "/users/deleteStudentsAccdamicEntrepreneurshipDetails",
  deleteStudentsCoActivitesEvents: "/users/deleteStudentsCoActivitesEvents",
  updateStudentsAccdamicCouseDetails: "/users/updateStudentsAccdamicCouseDetails",
  updateStudentsAccdamicInternshipDetails: "/users/updateStudentsAccdamicInternshipDetails",
  updateStudentsAccdamicJobDetails: "/users/updateStudentsAccdamicJobDetails",
  updateStudentsAccdamicHigherStudies: "/users/updateStudentsAccdamicHigherStudiesDetails",
  updateStudentsAccdamicEntrepreneurshipDetails: "/users/updateStudentsAccdamicEntrepreneurshipDetails",
  updateStudentsCoActivities: "/users/updateStudentsCoActivities",
  saveStudentsAccdamicInternshipDetails: "/users/saveStudentsAccdamicInternshipDetails",
  saveStudentsAccdamicJobDetails: "/users/saveStudentsAccdamicJobDetails",
  saveStudentsAccdamicHigherStudies: "/users/saveStudentsAccdamicHigherStudiesDetails",
  saveStudentsAccdamicEntrepreneurshipDetails: "/users/saveStudentsAccdamicEntrepreneurshipDetails",
  saveStudentsCoActivitesEventsDetails: "/users/saveStudentsCoActivitesEventsDetails",
updateStudentsProfilePhoto: "/users/updateStudentsProfilePhoto",
saveExtraStudentsDetails: "/users/saveExtraStudentsDetails"
};
