const arrayOps = {
  sortByDates (array) {
    console.log(array)
    array.sort((a, b) => this.dateStringToDateObject(a) - this.dateStringToDateObject(b))
    return array
  },

  dateStringToDateObject (obj) {
    const D = new Date(parseInt(obj.date.split('/')[2]), (parseInt(obj.date.split('/')[1]) - 1), parseInt(obj.date.split('/')[0]), parseInt(obj.time.split('_')[0]), parseInt(obj.time.split('_')[1]))
    return D
  },

  removeFromArray (array, n) {
    if (n > -1) {
      array.splice(n, 1)
    }
    return array
  },

  compareArrays (array1, array2) {
    array1.sort()
    array2.sort()
    return array1.length === array2.length && array1.every((element, index) => element === array2[index])
  },

  sortByProperty(array, property, order) { // sortByProperty(students, 'name', 'asc')
    let orderBy = "asc";
    if (order) orderBy = order
    if (property) {
      return array.sort((a, b) => {
        const valueA = a[property];
        const valueB = b[property];
        if (typeof valueA === "number" && typeof valueB === "number") {
          return orderBy === "asc" ? valueA - valueB : valueB - valueA;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          return orderBy === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          // Handle alphanumeric values
          const stringValueA = String(valueA);
          const stringValueB = String(valueB);
          return orderBy === "asc"
            ? stringValueA.localeCompare(stringValueB)
            : stringValueB.localeCompare(stringValueA);
        }
      });
    } else {
      return [];
    }
  }
}

export default arrayOps
