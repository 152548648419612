import { render, staticRenderFns } from "./activityCourseSection.html?vue&type=template&id=6868b871&scoped=true&external"
import script from "./activityCourseSection.vue?vue&type=script&lang=js"
export * from "./activityCourseSection.vue?vue&type=script&lang=js"
import style0 from "./activityCourseSection.css?vue&type=style&index=0&id=6868b871&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6868b871",
  null
  
)

export default component.exports