import { render, staticRenderFns } from "./activityCorporateDashboard.html?vue&type=template&id=639b2bda&scoped=true&external"
import script from "./activityCorporateDashboard.vue?vue&type=script&lang=js"
export * from "./activityCorporateDashboard.vue?vue&type=script&lang=js"
import style0 from "./activityCorporateDashboard.css?vue&type=style&index=0&id=639b2bda&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639b2bda",
  null
  
)

export default component.exports