// this is the main class of the router which will contain all the routes

// Import statements to get all required libraries
import Vue from "vue";
import Router from "vue-router"; // library for creating Router object
import validateTravel from "./routerGuard";

// Import statements to get all required components for routing
import activityWelcome from "../Activities/activityWelcome/activityWelcome.vue";
import activityHome from "../Activities/activityHome/activityHome";
import activitySetup from "../Activities/activitySetup/activitySetup";
import activityProfile from "../Activities/activityProfile/activityProfile";
import activitySelectInstituteNSemester from "../Activities/activitySelectInstituteNSemester/activitySelectInstituteNSemester";
import activityDirections from "../Activities/activityDirections/activityDirections";
import activityCreateInstituteOrUser from "../Activities/activityCreateInstituteOrUser/activityCreateInstituteOrUser";
import activityMySubjects from "../Activities/activityMySubjects/activityMySubjects";
import activityMyTimetable from "../Activities/activityMyTimetable/activityMyTimetable";
import activityTestHome from "../Activities/activityTestHome/activityTestHome";
import activityTimeline from "../Activities/activityTimeline/activityTimeline";
import activityDiscussion from "../Activities/activityDiscussion/activityDiscussion";
import activityAssignments from "../Activities/activityAssignments/activityAssignments";
import activityAttendanceAnalysis from "../Activities/activityAttendanceAnalysis/activityAttendanceAnalysis";
import activityExams from "../Activities/activityExams/activityExams";
import activityAppearForExam from "../Activities/activityAppearForExam/activityAppearForExam";
import activityNotifications from "../Activities/activityNotifications/activityNotifications";
import activityChangeSemester from "../Activities/activityChangeSemester/activityChangeSemester";
import activityTopicFeedback from "../Activities/activityTopicFeedback/activityTopicFeedback";
import activityExamAnalysis from "../Activities/activityExamAnalysis/activityExamAnalysis";
import activityStudyMaterial from "../Activities/activityStudyMaterial/activityStudyMaterial";
import activityMyDashboard from "../Activities/activityMyDashboard/activityMyDashboard.vue";
import activityCourseMaterial from "../Activities/activityCourseMaterial/activityCourseMaterial.vue";
import activityCourseMaterialRevamped from "../Activities/activityCourseMaterialRevamped/activityCourseMaterialRevamped.vue";
import activityCourseSection from "../Activities/activityCourseSection/activityCourseSection.vue";
import activityCourseQuiz from "../Activities/activityCourseQuiz/activityCourseQuiz.vue";
import activityQuizResult from "../Activities/activityCourseQuiz/activityQuizResult/activityQuizResult.vue";
import activityCourseCertificate from "../Activities/activityCourseCertificate/activityCourseCertificate.vue";
import activityCourseFeedback from "../Activities/activityCourseFeedback/activityCourseFeedback.vue";
import activityAssignmentResult from "../Activities/activityCourseSection/activityAssignmentResult/activityAssignmentResult.vue";
import activitySubmitAssessment from "../Activities/activitySubmitAssessment/activitySubmitAssessment.vue";
import activityFeedbackList from "../Activities/feedbackActivities/activityFeedbackList/activityFeedbackList.vue";
import activityFillFeedbackForm from "../Activities/feedbackActivities/activityFillFeedbackForm/activityFillFeedbackForm.vue";
import activityExamForm from "../Activities/activityExamForm/activityExamForm.vue";
import activityCorporateDashboard from "../Activities/Corporate/activityCorporateDashboard/activityCorporateDashboard.vue";
Vue.use(Router); // Using Router object in Vue
//  const dummy = []
//  console.log('dummy', dummy);
 export const visitedRouteName = []
//  console.log('visitedRouteName', visitedRouteName);
export const router = new Router({
  // Exporting a constant object 'router' which is of Router type
  mode: "history", // using history mode to maintain history stack of routes
  base: process.env.BASE_URL, // using 'base' variable as a baseURL
  routes: [
    // this is the array which contains all the paths/routes
    {
      // this is the default route which redirects to route of 'redirect' property
      path: "/",
      redirect: "/activityWelcome"
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: "/activityWelcome", // route of component
      name: "activityWelcome", // name of component
      component: activityWelcome, // object of component
      meta: { guestOnly: true }
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: "/activityWelcomes/:userName/:password", // route of component
      name: "activityWelcomes", // name of component
      component: activityWelcome, // object of component
      meta: { guestOnly: true }
    },
    {
      path: "/activitySetup",
      name: "activitySetup",
      component: activitySetup,
      meta: { guestOnly: true }
    },
    {
      path: "/activitySelectInstituteNSemester",
      name: "activitySelectInstituteNSemester",
      component: activitySelectInstituteNSemester,
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: "/activityUser",
      name: "activityUser",
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true }
    },
    {
      path: "/activityCreateInstitute",
      name: "acitivityCreateInstitute",
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true }
    },
    {
      path: "/activityTestHome",
      name: "activityTestHome",
      component: activityTestHome,
      meta: { requireAuth: true },
      children: [
        {
          path: "/",
          redirect: "/activityMySubjects"
        },
        {
          path: "/activityNotifications",
          name: "activityNotifications",
          component: activityNotifications,
          meta: { requireAuth: true }
        },
        {
          path: "/activityTimeline",
          name: "activityTimeline",
          component: activityTimeline,
          meta: { requireAuth: true }
        },
        {
          path: "/activityDiscussion",
          name: "activityDiscussion",
          component: activityDiscussion,
          meta: { requireAuth: true }
        },
        {
          path: "/activityDirections",
          name: "activityDirections",
          component: activityDirections,
          meta: { requireAuth: true }
        },
        {
          path: "/activityProfile",
          name: "activityProfile",
          component: activityProfile,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMySubjects",
          name: "activityMySubjects",
          component: activityMySubjects,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMyTimetable",
          name: "activityMyTimetable",
          component: activityMyTimetable,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAssignments",
          name: "activityAssignments",
          component: activityAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAttendanceAnalysis",
          name: "activityAttendanceAnalysis",
          component: activityAttendanceAnalysis,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExams",
          name: "activityExams",
          component: activityExams,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExamForm",
          name: "activityExamForm",
          component: activityExamForm,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAppearForExam",
          name: "activityAppearForExam",
          component: activityAppearForExam,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityChangeSemester",
          name: "activityChangeSemester",
          component: activityChangeSemester,
          meta: { requireAuth: true }
        },
        {
          path: "/activityTopicFeedback",
          name: "activityTopicFeedback",
          component: activityTopicFeedback,
          meta: { requireAuth: true }
        },
        {
          path: "/activityStudyMaterial",
          name: "activityStudyMaterial",
          component: activityStudyMaterial,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMyDashboard",
          name: "activityMyDashboard",
          component: activityMyDashboard,
          props: true,
          meta: { requireAuth: true }
        },
        // {
        //   path: "/activityCourseMaterial",
        //   name: "activityCourseMaterial",
        //   component: activityCourseMaterial,
        //   props: true,
        //   meta: { requireAuth: true }
        // },
        {
          path: "/activityCourseMaterial",
          name: "activityCourseMaterialRevamped",
          component: activityCourseMaterialRevamped,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseSection",
          name: "activityCourseSection",
          component: activityCourseSection,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAssignmentResult",
          name: "activityAssignmentResult",
          component: activityAssignmentResult,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityQuizResult",
          name: "activityQuizResult",
          component: activityQuizResult,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseQuiz",
          name: "activityCourseQuiz",
          component: activityCourseQuiz,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseCertificate",
          name: "activityCourseCertificate",
          component: activityCourseCertificate,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseFeedback",
          name: "activityCourseFeedback",
          component: activityCourseFeedback,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activitySubmitAssessment",
          name: "activitySubmitAssessment",
          component: activitySubmitAssessment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityFeedbackList",
          name: "activityFeedbackList",
          component: activityFeedbackList,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityFillFeedbackForm",
          name: "activityFillFeedbackForm",
          component: activityFillFeedbackForm,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExamAnalysis",
          name: "activityExamAnalysis",
          component: activityExamAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCorporateDashboard",
          name: "activityCorporateDashboard",
          component: activityCorporateDashboard,
          props: true,
          meta: { requireAuth: true }
        }
      ]
    },
    {
      path: "/activityHome",
      name: "activityHome",
      component: activityHome,
      meta: { requireAuth: true },
      children: [
        {
          path: "/",
          redirect: "/activityMySubjects2"
        },
        {
          path: "/activityProfile2",
          name: "activityProfile2",
          component: activityProfile,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMySubjects2",
          name: "activityMySubjects2",
          component: activityMySubjects,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMyTimetable2",
          name: "activityMyTimetable2",
          component: activityMyTimetable,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAssignments2",
          name: "activityAssignments2",
          component: activityAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAttendanceAnalysis2",
          name: "activityAttendanceAnalysis2",
          component: activityAttendanceAnalysis,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseMaterialRevamped2",
          name: "activityCourseMaterialRevamped2",
          component: activityCourseMaterialRevamped,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityTimeline2",
          name: "activityTimeline2",
          component: activityTimeline,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExams2",
          name: "activityExams2",
          component: activityExams,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExamForm2",
          name: "activityExamForm2",
          component: activityExamForm,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAppearForExam2",
          name: "activityAppearForExam2",
          component: activityAppearForExam,
          meta: { requireAuth: true }
        },
        {
          path: "/activityTopicFeedback2",
          name: "activityTopicFeedback2",
          component: activityTopicFeedback,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityChangeSemester2",
          name: "activityChangeSemester2",
          component: activityChangeSemester,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAssignments2",
          name: "activityAssignments2",
          component: activityAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityExamAnalysis2",
          name: "activityExamAnalysis2",
          component: activityExamAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityStudyMaterial2",
          name: "activityStudyMaterial2",
          component: activityStudyMaterial,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityMyDashboard2",
          name: "activityMyDashboard2",
          component: activityMyDashboard,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseMaterial2",
          name: "activityCourseMaterial2",
          component: activityCourseMaterial,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activitySubmitAssessment2",
          name: "activitySubmitAssessment2",
          component: activitySubmitAssessment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseSection2",
          name: "activityCourseSection2",
          component: activityCourseSection,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityAssignmentResult2",
          name: "activityAssignmentResult2",
          component: activityAssignmentResult,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseQuiz2",
          name: "activityCourseQuiz2",
          component: activityCourseQuiz,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityQuizResult2",
          name: "activityQuizResult2",
          component: activityQuizResult,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseCertificate2",
          name: "activityCourseCertificate2",
          component: activityCourseCertificate,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityCourseFeedback2",
          name: "activityCourseFeedback2",
          component: activityCourseFeedback,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityFeedbackList2",
          name: "activityFeedbackList2",
          component: activityFeedbackList,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityFillFeedbackForm2",
          name: "activityFillFeedbackForm2",
          component: activityFillFeedbackForm,
          props: true,
          meta: { requireAuth: true }
        }

      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  validateTravel(to, from, next);
});
// router.afterEach((to) => {
//   // console.log('to', to.name);
//  dummy.push(`${to.name}`)
//   visitedRouteName = [...new Set(dummy)]
// //  console.log('dummy', dummy);
// //  console.log('visitedRouteName', visitedRouteName);
// })
