<template src='./activityMyTimetable.html'></template>

<script>
import timeSlotCard from './timeSlotCard'
import ZoomMeetingRepository from '../../Repository/zoomMeeting'
import inputContainer from '../../Components/inputContainer'
import { timeStringConverter, convertFromYYYYMMDDToDate } from '../../Services/Utils/DateTimeFormatter'
import { days } from '../../Constants/Utils/Statics'
import SubjectsRepository from '../../Repository/Subjects'
import UserRepository from '../../Repository/User'
import LectureRepository from '../../Repository/lectures'
import showStatus from '../../NetworkManager/showStatus'
import OnlineLectureRepository from '../../Repository/OnlineLecture'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import ValueAddedActivityRepository from '../../Repository/valueAddedActivity'
import auth from '../../Services/Firebase/auth'
import Vue from 'vue'
import * as firebase from 'firebase/app'
import 'firebase/messaging'
// import { visitedRouteName } from '../../Router/index'
import UserAuthenticationRepository from "../../Repository/UserAuthentication";

const bus = new Vue()

export default {
  name: 'activityMyTimetable',
  components: {
    inputContainer,
    timeSlotCard
  },
  data() {
    return {
      timeReq: false,
      isEdcInstitute: false,
      isCorporate: false,
      allValueAddedLecture: [],
      visitedRoutesDummy: [],
      studentInformation: [],
      date: new Date().toISOString().substr(0, 10),
      selectedDate: new Date().toISOString().substr(0, 10),
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      timetable: [],
      loading: false,
      timetable2: [],
      headers: [
        {
          text: '',
          value: 'statusDot'
        },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Subject',
          value: 'subjectName'
        },
        {
          text: 'Location',
          value: 'roomName'
        },
        {
          text: 'Lecture Type',
          value: 'lectureType'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      subjectsMapping: {},
      today: '',
      todate: '',
      todayCount: 0,
      yesterday: '',
      yesterdate: '',
      yesterdayCount: 0,
      tomorrow: '',
      tomorrowdate: '',
      tomorrowCount: 0,
      dayAfterTomorrow: '',
      dayAfterTomorrowdate: '',
      dayAfterTomorrowCount: 0,
      progressBar: false,
      showOnlineLectureDialog: false,
      redirectionUrl: '',
      isMobileView: false,
      showOnlineLecture: false,
      selectedLecture: {},
      showDownloadVideo: false,
      showRecording: false,
      tempLink: '',
      brandingLogo: '',
      brandingName: '',
      steps: [
        {
          target: '#date',
          header: {
            title: 'How to join lecture'
          },
          content: `Select date first`,
          params: {
            placement: 'right',
            enableScrolling: false
            // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#schedule',
          content: 'Check schedule here',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '.join',
          content: 'Join lecture/Get recording',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ]
    }
  },
  created() {
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.zoomMeetingInstance = new ZoomMeetingRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)
    this.ValueAddedActivityRepositoryInstance = new ValueAddedActivityRepository(this)
    this.$store.commit('liveData/set_selectedActivityName', 'Schedule')
    this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
    this.userData = this.$store.getters['user/get_userData']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    const data = this.$store.getters['instituteData/selectedInstitute']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.isCorporate = this.$store.getters["liveData/get_isCorporateInstitute"];
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }
    console.log('this.isEdcInstitute', this.isEdcInstitute);
    this.brandingName = data.name
    this.brandingLogo = data.logo
    this.fetchData()
    this.startSiteTour()
    bus.$on('refresh-data', this.fetchData)
  },

  methods: {

    async startSiteTour() {
      this.studentInformation = [];
      this.routeName = this.$route.name
      const objToPush = {
        uId: this.userData.uId
      }
      this.studentInformation = await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(objToPush)
      if (!this.studentInformation.userAuthentications[0].visitedRoutes.includes(this.routeName)) {
        setTimeout(() => {
          // console.log('hiii');
          this.$tours[this.routeName].start()
        }, 1);
        this.studentInformation.userAuthentications[0].visitedRoutes.push(this.routeName)
        this.visitedRoutesDummy = [...this.studentInformation.userAuthentications[0].visitedRoutes]
        console.log('this.studentInformation.userAuthentications[0].visitedRoutes', this.studentInformation.userAuthentications[0]);
        // console.log('this.visitedRoutesDummy', this.visitedRoutesDummy);
        const objToPush1 = {
          uId: this.userData.uId,
          visitedRoutes: this.visitedRoutesDummy
        }
        // console.log('objToPush1', objToPush1);
        await this.UserAuthenticationRepositoryInstance.updateVisitedRoutes(objToPush1)
      }
    },
    async fetchData() {
      this.timeReq = false
      this.timetable = []
      this.progressBar = true
      this.timetable.isTimetableAvailable = true
      console.log('this.selectedDate2', this.selectedDate.split('-'));
      // Call API to get data for yesterday, today, tomorrow, day after tomorrow
      const date = new Date(this.selectedDate.split('-'))
      console.log('date', date);
      this.today = days[date.getDay()]
      console.log('today', this.today);
      this.todate = date.getDate()
      this.todayCount = 0

      console.log('this.selectedDate1', new Date(this.selectedDate));
      this.timetable2 = []

      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          multiDivision: this.userData.division,
          batch: this.userData.batch,
          today: this.today,
          dateOfLecturesNeeded: this.convertDateToUTC(new Date(this.selectedDate))
        }
        console.log('this.userData', objToPush)
        const lectrerUids = []
        const roomIds = []
        try {
          this.timetable = await this.lectureRepositoryInstance.getLecturesForADayForAStudent(objToPush);
        } catch (error) {
          this.timetable = []
        }
        console.log('this.timetable', this.timetable)
        const objToPush2 = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          subjectIds: this.userData.subjects,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          batch: this.userData.batch,
          today: this.today,
          dateOfLecturesNeeded: this.convertDateToUTC(new Date(this.selectedDate))
        }
        if (this.userData.assignedGroupForSubject.length > 0) {
        const allLectures = await this.lectureRepositoryInstance.getGroupLecturesForADayForAStudent(objToPush2)
        console.log('allLectures', allLectures)
        for (let i = 0; i < this.userData.assignedGroupForSubject.length; i++) {
          for (let j = 0; j < allLectures.length; j++) {
            for (let k = 0; k < allLectures[j].groupForSubject.length; k++) {
              if (this.userData.assignedGroupForSubject[i].groupId === allLectures[j].groupForSubject[k].groupId) {
                this.timetable.push(allLectures[j])
              }
            }
          }
        }
        }
        console.log('this.timetable', this.timetable);
        this.todayCount = this.timetable.length
        if (this.todayCount >= 0) {
          this.timetable.isTimetableAvailable = true
        } else {
          this.timetable.isTimetableAvailable = false
          this.progressBar = false
        }
        this.timetable.map((obj) => {
          if (this.subjectsMapping[obj.subjectId] !== undefined) {
            lectrerUids.push(obj.uId)
            if (obj.roomId !== '') {
              roomIds.push(obj.roomId)
            }
            obj.subjectName = this.subjectsMapping[obj.subjectId].subjectName
            obj.subjectType = this.subjectsMapping[obj.subjectId].subjectType
            obj.wasPresent = obj.present.includes(this.userData.uId)
            if (
              Array.isArray(obj.studentsListForRecordingEnable) &&
              obj.studentsListForRecordingEnable.includes(this.userData.uId)
            ) {
              obj.studentFound = true;
            } else {
              obj.studentFound = false;
            }
          }
        })
        let roomNames = []
        let lecturerNames = []
        if (lectrerUids.length > 0) {
          lecturerNames = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: lectrerUids })
        }
        if (roomIds.length > 0) {
          roomNames = await this.infrastructureReferenceRepositoryInstance.getRoomNames({ instituteId: this.selectedInstitute, roomIds: roomIds })
        }
        this.timetable.map((obj) => {
          if (lecturerNames.length > 0) {
            lecturerNames.map((lecturer) => {
              if (obj.uId === lecturer.uId) {
                obj.name = lecturer.fullName
              }
            }
            )
          }
          if (roomNames.length > 0) {
            roomNames.map((room) => {
              if (obj.roomId === room.roomId) {
                obj.roomName = room.roomName
              }
            })
          }
        })
      } catch (err) {
        console.log(err)
        this.timetable = []
        this.timetable2 = []
      }
      try {
        const res = await this.lectureRepositoryInstance.getAllLectureOfValueAddedActivity({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department
        })
        // console.log('this.allValueAddedLecture', this.allValueAddedLecture);
        this.allValueAddedLecture = []
        const leng = res.length - 1
        console.log('leng', leng);
        let promise = []
        promise = res.map(async (data, i) => {
          console.log('i', i);
          data['title'] = ''
          const [a, b] = data.dateTime.split('T')
          console.log('b', b);
          if (a === this.selectedDate) {
            data['subjectType'] = 'Value Added Activity'
            if (data.roomId !== '') {
              const roomNames = await this.infrastructureReferenceRepositoryInstance.getRoomNames({ instituteId: this.selectedInstitute, roomIds: data.roomId })
              data['roomName'] = roomNames[0].roomName
            }
            if (data.uId !== '') {
              const lecturerName = await this.userRepositoryInstance.getFullNameOfUser({ uId: data.uId })
              data['name'] = lecturerName

            }
            data['isTimetableAvailable'] = true

            const nameOfActivity = await this.ValueAddedActivityRepositoryInstance.getValueAddedActivity({
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              valueAddedActivityId: data.valueAddedActivityId
            })
            data['title'] = nameOfActivity[0].title
            this.allValueAddedLecture.push(data)
          }
        })
        this.todayCount += this.allValueAddedLecture.length
        const allSettledPromises = await Promise.allSettled(promise);
        console.log('allSettledPromises', allSettledPromises);
        this.timetable2 = [...this.timetable, ...this.allValueAddedLecture]
        console.log('this.timetable2', this.timetable2);
        this.timetable2 = this.timetable2.slice()
        console.log('this.timetable2', this.timetable2)
        this.progressBar = false

      } catch (error) {
        this.progressBar = false
        console.log(error)
        this.timetable2 = this.timetable
        this.timetable2 = this.timetable2.slice()
      }

    },
    convertDateToUTC(date) {
      return new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds())
    },
    async getCountForDay(date, dateCount) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          batch: this.userData.batch,
          dateOfLecturesNeeded: date
        }
        const timetable = await this.lectureRepositoryInstance.getLecturesForADayForAStudent(objToPush)
        dateCount = timetable.length
      } catch (err) {
        console.log(err)
        dateCount = 0
      }
    },
    async getDetailsOfALecture(lectureObject) {
      try {
        if (!lectureObject.name) {
          lectureObject.name = await this.userRepositoryInstance.getFullNameOfUser({ uId: lectureObject.uId })
        }
      } catch (err) {
        console.log(err)
      }

      try {
        if (!lectureObject.roomName && lectureObject.roomId) {
          lectureObject.roomName = await this.infrastructureReferenceRepositoryInstance.getRoomName({ instituteId: this.selectedInstitute, roomId: lectureObject.roomId })
        }
      } catch (err) {
        console.log(err)
      }

      lectureObject.redirectionUrl = ''
      return lectureObject
    },
    async getDetailsOfALecture2(lectureObject) {
      try {
        const objToPush = {
          instituteId: lectureObject.instituteId,
          semId: lectureObject.semId,
          lectureId: lectureObject.lectureId,
          uId: this.userData.uId
        }
        //   const response = await this.zoomMeetingInstance.joinZoomMeeting(objToPush)
        //   console.log('this is the redirection url for student ', response.joinUrl)
        //   lectureObject.redirectionUrl = response.joinUrl
        try {
          lectureObject.redirectionUrl = await this.onlineLectureRepositoryInstance.getRedirectionUrl(objToPush)
        } catch (err) {
          console.log(err)
          lectureObject.redirectionUrl = await this.onlineLectureRepositoryInstance.getLinkOfOnlineLecture(objToPush)
        }
      } catch (err) {
        console.log(err)
      }
      try {
        /* const objToPush = { */
        /*   instituteId: this.selectedInstitute, */
        /*   semId: lectureObject.semId, */
        /*   lectureId: lectureObject.lectureId */
        /* } */
        /* lectureObject.recordingLink = await this.onlineLectureRepositoryInstance.getRecordingOfAnOnlineLecture(objToPush) */
        // const objToPushForRecordingLink = {
        //   instituteId: this.selectedInstitute,
        //   semId: lectureObject.semId,
        //   lectureId: lectureObject.lectureId
        // }
        // console.log('this is to get the lecture recording link', objToPushForRecordingLink)
        // const recordingTempLink = await this.zoomMeetingInstance.getrecordingLink(objToPushForRecordingLink)
        // if (recordingTempLink.recordingLink !== '') {
        //   console.log('this is recording link')
        //   console.log('this is recordingLink Inside')
        //   lectureObject.recordingLink = recordingTempLink.recordingLink
        // }
      } catch (err) {
        console.log(err)
      }
      return lectureObject
    },
    async joinOnlineLecture(link) {
      this.showOnlineLectureDialog = true
      try {
        showStatus('Redirecting to online lecture', 1000, 'success', this)
        console.log(link)
        window.open(link, '_blank')
      } catch (err) {
        console.log(err)
        this.showOnlineLectureDialog = false
        showStatus('Error fetching link for online lecture.', 1000, 'error', this)
      }
    },
    timeStringConverter(time) {
      return timeStringConverter(time)
    },
    convertToDateObject(dateString) {
      console.log('dateString', dateString)
      return convertFromYYYYMMDDToDate(dateString)
    },
    convertToISOString(date) {
      const convertedDate = new Date(date.valueOf())
      convertedDate.setHours(5, 30, 0, 0)
      return convertedDate.toISOString()
    },
    async trigerDialog(timeSlot) {
      const detailsOfLecture = await this.getDetailsOfALecture2(timeSlot)
      console.log('this is lecture object', detailsOfLecture)
      this.selectedLecture = detailsOfLecture
      this.showOnlineLecture = true
    },
    canJoinOnlineLecture(item) {
      const currentTime = new Date()
      const date = item.dateTime.slice(0, 10).split('-')
      const time = item.endTime.split('_')
      const parsedEndTimeOfLecture = new Date(date[0], (parseInt(date[1]) - 1), date[2], time[0], time[1])
      console.log(parsedEndTimeOfLecture > currentTime)
      return (currentTime <= parsedEndTimeOfLecture)
    },
    canGetRecordedLectureLink(item) {
      const currentTime = new Date()
      const date = item.dateTime.slice(0, 10).split('-')
      const time = item.endTime.split('_')
      const parsedEndTimeOfLecture = new Date(date[0], (parseInt(date[1]) - 1), date[2], time[0], time[1])
      console.log(parsedEndTimeOfLecture > currentTime)
      return (currentTime - parsedEndTimeOfLecture >= 60 * 60 * 1000)
    },

    goToRecordingLink(link) {
      if (link) {
        console.log(link)
        this.tempLink = link
        this.showRecording = true
      }
    },
    openDownloadVideoDialog() {
      this.showDownloadVideo = true
      this.showOnlineLecture = false
    },
    printsomething() {
      console.log('Hello')
    }
  }
}
if (firebase.messaging.isSupported()) {
  auth.getMessagingObject().onMessage((payload) => {
    console.log('Message received. ', payload)
    const title = payload.notification.title
    const options = {
      body: payload.notification.body,
      icon: './favicon.ico'
    }
    // console.log($data.date)
    // if (!('Notification' in window)) {
    //   console.log('This browser does not support system notifications')
    // }
    // Let's check whether notification permissions have already been granted
    if (Notification.permission === 'granted') {
      navigator.serviceWorker.register('firebase-messaging-sw.js', { scope: 'firebase-cloud-messaging-push-scope' })
        .then((registration) => {
          registration.showNotification(title, options)
        })
      // If it's okay let's create a notification
      // var notification = new Notification(title, options)
      // notification.onclick = function (event) {
      //   event.preventDefault() // prevent the browser from focusing the Notification's tab
      //   window.open(payload.notification.click_action, '_blank')
      //   notification.close()
      // }
    }
    bus.$emit('refresh-data')
    // ...
  })
}
</script>

<style src='./activityMyTimetable.css'></style>
