<template src="./activityCourseMaterialRevamped.html"></template>
<script>
import SubjectsRepository from "../../Repository/Subjects";
import CourseRepository from "../../Repository/Course";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import CoursesData from "../../Repository/mediaLibrary";
// eslint-disable-next-line no-unused-vars
import showStatus from "../../NetworkManager/showStatus"
export default {
    name: "activityCourseMaterialRevamped",
    props: ["prop_subject", "prop_indexSelectedSubSection", "prop_indexOfSelectedSection"],
    data() {
        return {
            courseDetails: {

            },
            studentCourse: {},
            editCourseSubsections: {

            },
            isCourseCompleted: false,
            totalCreditsRecieved: 0,
            totalCredit: 0,
            progressPercentage: 0,
            subsectionCompletedCount: 0,
            subsectinsCount: 0,
            activeModule: 0,
            activeSubsection: -1,
            disableNextSectionModal: false,
            isMobileView: false,
            showContentNotFoundModal: false,
            //Mobile states
            isDocModalOpen: false,
            docLink: "",
            //loading
            isLoading: true,
            sectionCount: 0,
            confirmNextSubSectionModule: false
        }
    },
    mounted() {
        window.addEventListener('contextmenu', (e) => e.preventDefault());
        function ctrlShiftKey(e, keyCode) {
            return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
        }
        document.onkeydown = (e) => {
            // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
            if (
                event.keyCode === 123 ||
                ctrlShiftKey(e, 'I') ||
                ctrlShiftKey(e, 'J') ||
                ctrlShiftKey(e, 'C') ||
                (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
            ) { return false; }
        };
    },
    async created() {
        if (this.prop_subject) {
            this.$store.commit("liveData/set_selectedCourse", {
                selectedCourse: this.prop_subject
            });
        }
        this.selectedSubject = this.$store.getters["liveData/get_selectedCourse"];
        this.$store.commit("liveData/set_selectedActivityName", this.selectedSubject.subjectName);
        this.courseName = this.selectedSubject.subjectName
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        // this.isCorporateInstitute = true
        this.subjectsRepositoryInstance = new SubjectsRepository(this);
        this.CourseRepositoryInstance = new CourseRepository(this);
        this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)
        this.mediaLibraryInstance = new CoursesData(this);
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.userData = this.$store.getters["user/get_userData"];
        if (this.isMobileView) this.activeModule = -1;
        //const p1 = this.fetchCoursesData();
        //const p2 = this.fetchStudentData();
        //await Promise.all([p1, p2]);
        //this.syncCourseData()
        await this.fetchData()
        if (this.prop_indexOfSelectedSection) {
            this.handleModuleChange(this.prop_indexOfSelectedSection)
        }
    },
    methods: {
        async fetchData() {
            this.isLoading = true
            try {
                const objToPush = {
                    instituteId: this.selectedSemester.instituteId,
                    semId: this.selectedSemester.semId,
                    department: this.selectedSubject.department,
                    courseYear: this.selectedSubject.courseYear,
                    subjectId: this.selectedSubject.subjectId,
                    courseName: this.selectedSubject.subjectName,
                    isCoursePublished: true,
                    uId: this.selectedSemester.uId
                }
                const { data } = await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(objToPush);
                if (data.isCourseFound) {
                    this.sectionCount = data.courseDetails.sections.length
                    this.courseDetails = data.courseDetails;
                    this.sectionCompletedCount = data.sectionCompletedCount;
                    this.totalCredit = data.totalCredit;
                    this.totalCreditsRecieved = data.totalCreditsRecieved;
                    this.subsectionCompletedCount = data.subsectionCompletedCount;
                    this.progressPercentage = data.progressPercentage;
                    this.studentCourse = data.studentCourse;
                    this.subsectinsCount = data.subsectinsCount;
                    this.isCourseCompleted = this.sectionCompletedCount === this.courseDetails.sections.length
                    this.editCourseSubsections = JSON.parse(JSON.stringify(this.courseDetails));

                    const tmpSections = [];
                    this.editCourseSubsections.sections.forEach(section => {
                        if (section.isSectionPublished) {
                            const tempSubsections = [];
                            if (section.subSections) {
                                section.subSections.forEach(subsec => {
                                    if (subsec.isSubsectionPublished) tempSubsections.push(subsec);
                                })
                            }
                            section.subSections = tempSubsections;
                            tmpSections.push(section);
                        }
                    })
                    this.editCourseSubsections.sections = tmpSections;
                    let tempCourseCompleted = true;
                    for (let i = 0; i < this.courseDetails.sections.length; i++) {
                        if (!this.courseDetails.sections[i].isSectionCompleted) {
                            tempCourseCompleted = false;
                            break;
                        }
                    }
                    this.isCourseCompleted = tempCourseCompleted
                    console.log('this.courseDetails>>>>>', data);

                }
            } catch (err) {
                console.log(err);
                this.showContentNotFoundModal = true
            } finally {
                this.isLoading = false
            }
        },
        jumpToPreviousSubsection() {
            this.activeModule--
        },
        jumpToNextSubsectionDialog() {
            // this.confirmNextSubSectionModule = false
            let result = true
            if (!this.courseDetails.jumpBetweenSubsection) {
                result = this.editCourseSubsections.sections[this.activeModule].subSections.every(this.checkIfSubSectionCompleted)
            }
            if (result) {
                this.confirmNextSubSectionModule = true
            } else {
                this.disableNextSectionModal = true
            }
        },
        gotoSubjects() {
            this.$router.push({
                name: "activityMySubjects"
            });
        },
        jumpToNextSubsection() {
            this.confirmNextSubSectionModule = false
            this.activeModule++
        },
        checkIfSubSectionCompleted(subSec) {
            return subSec.isSubsectionCompleted
        },
        disableRightClick(event) {
            event.preventDefault();
        },
        getPdfDocLink(url) {
            if (!this.courseDetails.pdfDownload) {
                return url + "#toolbar=0";
            } else return url;
        },

        toggleFullScreen() {
            const iframe = this.$refs.myIframe[0];

            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) { // Firefox
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) { // IE/Edge
                iframe.msRequestFullscreen();
            }
            // Access the iframe element
            // const iframe2 = this.$refs.myIframe[0];
            // Add an event listener to the iframe's load event
            // Access the iframe's document
            // const iframe2 = this.$refs.myIframe[0]
            // console.log("iframe", iframe2);

            // console.log("iframe2", iframe2.addEventListener('message', function () {
            //     console.log("lolol ")
            // }));
            // console.log("iframe2", iframe2.addEventListener('contextmenu',
            //     (e) => {
            //         console.log("here")
            //         return e.preventDefault()
            //     }
            // )
            // );
            // iframe2.addEventListener('contextmenu', (e) => e.preventDefault());
            // const iframeDocument = iframe2.contentDocument || iframe2.contentWindow.document;
            // console.log("iframe doc", iframeDocument)
            // // Prevent the contextmenu event within the iframe's document
            // iframeDocument.addEventListener("contextmenu", (e) => {
            //     e.preventDefault();
            // });
        },
        // onSubjectExpansionPanelClick(e, sub) {
        //     if (
        //         !e.currentTarget.classList.contains("v-expansion-panel-header--active")
        //     ) {
        //         this.activeSubsection = sub;
        //      } else this.activeSubsection = -1;
        // },
        handleTimeUpdate(e, subsection, test) {
            const check = (test === "video") ? this.courseDetails.videoTimeline : this.courseDetails.audioTimeline;
            const video = e.target;
            if (!check) {
                if (!video.seeking) {
                    subsection.supposedCurrentTime = video.currentTime;
                }
            }

            if (test === "video") {
                subsection.watchTime = video.currentTime;
                // if (subsection.watchTime >= (0.95 * video.duration)) subsection.isSubsectionCompleted = true;
            } else {
                subsection.listenTime = video.currentTime;
                if (subsection.listenTime >= (0.95 * video.duration)) subsection.isSubsectionCompleted = true;
            }
        },
        async saveWatchListenTime(subsection, duration, currentTime) {
            if (subsection.isSubsectionCompleted) return;
            if (this.studentCourse.sections === undefined) {
                this.studentCourse.sections = [];
            }
            const sections = JSON.parse(JSON.stringify(this.studentCourse.sections));
            if (subsection.contentType === "Audio") {
                if (currentTime >= (0.95 * duration)) {
                    subsection.isSubsectionCompleted = true;
                }
                subsection.audLength = duration;
                subsection.listenTime = currentTime;
            } else if (subsection.contentType === "Video") {
                if (currentTime >= (0.95 * duration)) {
                    subsection.isSubsectionCompleted = true;
                }
                subsection.vidLength = duration;
                subsection.watchTime = currentTime;
            }

            let isSectionFound = false;
            sections.forEach(section => {
                /* eslint-disable eqeqeq */
                if (section.sectionId == this.editCourseSubsections.sections[this.activeModule]._id) {
                    isSectionFound = true;
                    let isSubsectionFound = false;
                    section.subSections.forEach(targetSubsec => {
                        if (targetSubsec.subSectionId === subsection.subSectionId) {
                            isSubsectionFound = true;
                            Object.assign(targetSubsec, subsection);
                        }
                    })
                    if (!isSubsectionFound) {
                        section.subSections.push(subsection);
                    }
                }
            })
            if (!isSectionFound) {
                sections.push({
                    creditsRecieved: "0",
                    isSectionCompleted: false,
                    sectionId: this.editCourseSubsections.sections[this.activeModule]._id,
                    sectionTitle: this.editCourseSubsections.sections[this.activeModule].sectionTitle,
                    subSections: [],
                    _id: this.editCourseSubsections.sections[this.activeModule]._id
                });
                sections[0].subSections.push(subsection);
            }

            try {
                const objToPush = {
                    instituteId: this.selectedSemester.instituteId,
                    semId: this.selectedSemester.semId,
                    department: this.selectedSemester.department,
                    courseYear: this.selectedSemester.courseYear,
                    courseId: this.selectedSubject.subjectId,
                    uId: this.selectedSemester.uId,
                    sections: sections,
                    completedSubsections: subsection.completedSubsections + 1
                }
                await this.CourseRepositoryInstance.createStudentCourse(objToPush);
                // await this.fetchData()
            } catch (e) {
                console.log(e)
            }
        },
        handleSeeking(e, subsection, test) {
            const check = (test === "video") ? this.courseDetails.videoTimeline : this.courseDetails.audioTimeline;
            if (!check && !subsection.isSubsectionCompleted) {
                const video = e.target;
                const delta = video.currentTime - subsection.supposedCurrentTime;
                if (Math.abs(delta) > 0.01) {
                    video.currentTime = subsection.supposedCurrentTime;
                }
            }
        },
        updateTime(subsection, e, target) {
            subsection[target] = e.target.duration

            if (subsection.contentType === "Audio") {
                e.target.currentTime = subsection.listenTime;
            } else {
                e.target.currentTime = subsection.watchTime;
            }
        },
        gotoStartQuiz(subsection, i) {
            this.$confirm("You want to start the Quiz?").then(() => {
                if (this.isMobileView) {
                    this.$router.push({
                        name: "activityCourseQuiz2",
                        params: {
                            prop_course: subsection,
                            prop_subjectInfo: this.courseDetails.sections[this.activeModule],
                            prop_indexSelectedSubSection: i,
                            prop_indexOfSelectedSection: this.activeModule,
                            prop_courseDetails: this.courseDetails,
                            prop_subsectionCompletedCount: this.subsectionCompletedCount,
                            prop_subject: this.prop_subject
                        }
                    });
                } else {
                    this.$router.push({
                        name: "activityCourseQuiz",
                        params: {
                            prop_course: subsection,
                            prop_subjectInfo: this.courseDetails.sections[this.activeModule],
                            prop_indexSelectedSubSection: i,
                            prop_indexOfSelectedSection: this.activeModule,
                            prop_courseDetails: this.courseDetails,
                            prop_subsectionCompletedCount: this.subsectionCompletedCount,
                            prop_subject: this.prop_subject
                        }
                    });
                }
            });
        },
        async gotoQuizResult(subsection, i) {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityQuizResult2",
                    params: {
                        prop_course: subsection,
                        prop_subjectInfo: this.prop_course,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: i,
                        prop_courseDetails: this.prop_courseDetails,
                        prop_subject: this.prop_subject
                    }
                });
            } else {
                this.$router.push({
                    name: "activityQuizResult",
                    params: {
                        prop_course: subsection,
                        prop_subjectInfo: this.prop_course,
                        prop_indexOfSelectedSection: this.activeModule,
                        prop_indexSelectedSubSection: i,
                        prop_courseDetails: this.courseDetails,
                        prop_subject: this.prop_subject
                    }
                });
            }
        },
        async getSignedUrlForVideo(file, path = "") {
            const fileName = file.name;
            const body = {
                fileName: fileName,
                fileType: file.type,
                instituteId: this.selectedSubject.instituteId
            };
            const signedUrl = await this.mediaLibraryInstance.getPresignedUrlForR2(body);
            return signedUrl.url;
        },
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(
                signedUrl,
                {
                    method: "PUT",
                    body: file,
                    headers: {
                        "Content-Type": file.type,
                        "x-amz-acl": "public-read"
                    }
                },
                event => {
                    const progress = parseInt((event.loaded / event.total) * 100);
                    setTimeout(() => {
                        this.currentUploadProgress = progress;
                        if (progress > 99) {
                            this.currentlyUploadingNumber++;
                        }
                    }, 200);
                }
            );
            if (this.currentUploadProgress === 100) {
                setTimeout(() => {
                    this.loading = false
                }, 1500)
            }
            return res;
        },
        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = e => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        async getSignedUrlForDocs(file, path = "") {
            const fileName = path + file.name;
            const body = {
                fileName,
                fileType: file.type
            };
            const signedUrl = await this.CourseRepositoryInstance.getSignedUrl(
                body
            );
            return signedUrl;
        },
        async handleAssignmentFileChange(e, subsection) {
            if (e === null) {
                return;
            }
            let link = "";
            const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.wmv', '.flv', '.webm', '.ogg', '.ogv', 'video/mp4'];
            let signedUrlsData;
            if (videoExtensions.includes(e.type.toLowerCase())) {
                signedUrlsData = await this.getSignedUrlForVideo(e, `${this.userData.uId}/documents/`);
                link = signedUrlsData
                await this.uploadToSpaces(e, signedUrlsData)
            } else {
                signedUrlsData = await this.getSignedUrlForDocs(e, `${this.userData.uId}/documents/`);
                const modifiedName = this.extractFileNameFromUrl(signedUrlsData.signedUrl)
                link = modifiedName
                await this.uploadToSpaces(e, signedUrlsData.signedUrl)
            }
            subsection.assignmentFileUrl = link
        },
        extractFileNameFromUrl(url) {
            // const parts = url.split('/');
            // const fileNameWithToken = parts[parts.length - 1];
            // const fileNameDecoded = decodeURIComponent(fileNameWithToken);
            const fileName = url.split('?')[0];
            return fileName;
        },
        getAllowedFileTypes(allowedFileTypeStudents) {
            if (allowedFileTypeStudents.includes("Any")) return "*";

            let res = "";

            if (allowedFileTypeStudents.includes("PDF")) res += ".pdf";
            if (allowedFileTypeStudents.includes("Audio")) res += ",audio/*";
            if (allowedFileTypeStudents.includes("Video")) res += ",video/*";
            if (allowedFileTypeStudents.includes("Excel")) res += ",.doc, .docx, .xlsx";
            if (allowedFileTypeStudents.includes("Image")) res += ",image/*";

            return res;
        },
        async handleSubmitSubsection(subsection) {
            if (this.studentCourse.sections === undefined) {
                this.studentCourse.sections = [];
            }
            if (subsection.isSubsectionCompleted) return;
            const sections = JSON.parse(JSON.stringify(this.studentCourse.sections));
            if (subsection.contentType === "Assignment" || subsection.contentType === "Document" || subsection.contentType === "Feedback") {
                // if (subsection.assignmentFileUrl === "") {
                //     showStatus("Please add file before you submit", 3000, "error", this);
                //     return;
                // }
                let confirmation;
                if (subsection.contentType === "Assignment") confirmation = await this.$confirm("Are you sure? Once Submitted, then it cannot be changed");
                else confirmation = true;
                if (confirmation) {
                    subsection.isSubsectionCompleted = true;
                    let isSectionFound = false;
                    sections.forEach(section => {
                        /* eslint-disable eqeqeq */
                        if (section.sectionId == this.editCourseSubsections.sections[this.activeModule]._id) {
                            isSectionFound = true;
                            let isSubsectionFound = false;
                            section.subSections.forEach(targetSubsec => {
                                if (targetSubsec.subSectionId === subsection.subSectionId) {
                                    isSubsectionFound = true;
                                    Object.assign(targetSubsec, subsection);
                                }
                            })
                            if (!isSubsectionFound) {
                                section.subSections.push(subsection);
                            }
                        }
                    })

                    if (!isSectionFound) {
                        sections.push({
                            creditsRecieved: "0",
                            isSectionCompleted: false,
                            sectionId: this.editCourseSubsections.sections[this.activeModule]._id,
                            sectionTitle: this.editCourseSubsections.sections[this.activeModule].sectionTitle,
                            subSections: [subsection],
                            _id: this.editCourseSubsections.sections[this.activeModule]._id
                        });
                    }
                }
            }
            // const status = this.getCourseCompletionStatus()
            try {
                // eslint-disable-next-line no-unused-vars
                const objToPush = {
                    instituteId: this.selectedSemester.instituteId,
                    semId: this.selectedSemester.semId,
                    department: this.selectedSemester.department,
                    courseYear: this.selectedSemester.courseYear,
                    courseId: this.selectedSubject.subjectId,
                    uId: this.selectedSemester.uId,
                    sections: sections,
                    completedSubsections: subsection.completedSubsections + 1
                }
                await this.CourseRepositoryInstance.createStudentCourse(objToPush);
                await this.fetchData()
            } catch (e) {
                console.log(e)
            }
        },

        handleSubSectionPanelChange(subsection, targetIndex) {
            if (!this.courseDetails.jumpBetweenSubsection) {
                if (targetIndex === this.activeSubsection) {
                    this.activeSubsection = -1;
                    return;
                }
                if (targetIndex === 0) {
                    this.activeSubsection = targetIndex;
                    return;
                }
                if (subsection.isSubsectionCompleted || this.editCourseSubsections.sections[this.activeModule].subSections[targetIndex - 1].isSubsectionCompleted) {
                    this.activeSubsection = targetIndex;
                } else this.disableNextSectionModal = true;

            } else {
                if (targetIndex === this.activeSubsection) {
                    this.activeSubsection = -1;
                    return;
                }
                this.activeSubsection = targetIndex;
            }
        },
        async handleSubsectionNextBtn(subsection) {
            if (subsection.contentType === "Document") await this.handleSubmitSubsection(subsection);
            if (subsection.isSubsectionCompleted) {
                this.activeSubsection = this.activeSubsection + 1;
            } else this.disableNextSectionModal = true;
        },
        async handleModuleChange(target) {
            if (!this.courseDetails.jumpBetweenSubsection) {
                if (target === 0 || this.courseDetails.sections[target].isSectionCompleted || this.courseDetails.sections[target - 1].isSectionCompleted) {
                    this.activeModule = target;
                    this.activeSubsection = -1;
                } else {
                    this.disableNextSectionModal = true;
                }
            } else {
                this.activeModule = target;
                this.activeSubsection = -1;
            }
        },
        openFile(attachment) {
            try {
                const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
                PDF_VIEWER.searchParams.set('pdf', attachment)
                this.docLink = PDF_VIEWER
                this.isDocModalOpen = true
            } catch (e) {
                console.log('e', e);
            }
        },
        goToFeedback() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseFeedback2",
                    params: {
                        prop_courseDetails: this.courseDetails,
                        prop_subject: this.prop_subject,
                        prop_studentCourse: this.studentCourse
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseFeedback",
                    params: {
                        prop_courseDetails: this.courseDetails,
                        prop_subject: this.prop_subject,
                        prop_studentCourse: this.studentCourse
                    }
                });
            }
        },
        getQuizDisablity(subsection) {
            let test = false;
            if (subsection.studentAnswers.length !== 0) {
                if (subsection.isreAttemptAllowed) {
                    if (subsection.studentAnswers.length >= 3) test = true;
                    else test = false;
                } else test = true;
            } else test = false;
            return test;
        },
        goToCertificate() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseCertificate2",
                    params: {
                        prop_course: this.courseDetails
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseCertificate",
                    params: {
                        prop_course: this.courseDetails
                    }
                });
            }
        },
        goBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityMySubjects2"
                });
            } else {
                this.$router.push({
                    name: "activityMySubjects"
                });
            }
            // history.back()
        }
    }
}
</script>

<style scoped src="./activityCourseMaterialRevamped.css"></style>
