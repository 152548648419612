<template src="./activityWelcome.html"></template>
<script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
<script>
import auth from "../../Services/Firebase/auth";
import UserRepository from "../../Repository/User";
import showStatus from "../../NetworkManager/showStatus";
import firebase from "firebase";
export default {
  name: "activityWelcome",
  data() {
    return {
      sppuWelcomePageChanges: '',
      CareerAvenues: false,
      needForCourses: false,
      StrongPresence: false,
      contentOverview: false,
      signInWithPhone: false,
      phoneAuthStep: 0, // 0 -> enter phoneNum, 1 -> enter otp
      phoneNum: null,
      phoneOtp: null,
      isSendingRequest: false,
      username: "",
      password: "",
      usernameRules: [(v) => !!v || "Username is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      confirmationResultFunc: null,
      verificationCode: null,
      resetPassword: false,
      isMobileView: false,
      currentPassword: "",
      showCurrentPassword: false,
      newPassword: "",
      showNewPassword: false,
      confirmPassword: "",
      showConfirmPassword: false,
      confirmPasswordRules: [
        (v) => v === this.newPassword || "New passwords do not match",
      ],
      showLoginPassword: false,
      uId: "",
      forgotPassword: false,
      email: "",
      //Thanks stackoverflow: https://stackoverflow.com/a/59598452
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      isRedirectedFromAdmin: false,
      redirectedUId: '',
      redirectedUuId: '',
      isSecure: false
    };
  },

  methods: {
    /* loginType -> google | phone | username */
    login(loginType) {
      switch (loginType) {
        case "microsoft":
          auth.loginWithMicrosoft();
          break;
        case "google":
          auth.login();
          break;
        case "phone":
          this.confirmOtp();
          break;
        case "username":
          this.loginWithUserNameAndPassword();
          break;
        default:
          throw new Error("Unhandled Case in login");
      }
    },
    async loginWithUserNameAndPassword() {
      try {
        let fcmToken = "";
        this.isSendingRequest = true;
        this.userRepositoryInstance = new UserRepository(this);
        //get fcm token
        if (firebase.messaging.isSupported()) {
          try {
            await firebase.messaging().requestPermission();
            fcmToken = await firebase.messaging().getToken();
          } catch (e) {
            if (e.code !== "messaging/permission-default") {
              console.error(e);
            }
          }
        }
        this.isSecure = await auth.getPortalNavigationSecurity({
          reAuthenticationId: this.redirectedUuId,
          adminUId: this.redirectedAdminUId
        });

        if (this.isRedirectedFromAdmin === 'true' && this.isSecure) {
          const response = { uId: this.redirectedUId };
          if (response.uId !== undefined) {
            this.$store.commit("user/set_userData", { uId: response.uId });
            window.localStorage.setItem("userKey", JSON.stringify({ uId: response.uId }));
            showStatus("Login Successful!", 1000, "success", this);
            this.$router.push("/activitySelectInstituteNSemester");
          } else {
            alert("Login failed");
          }
        } else {
          const response =
          await this.userRepositoryInstance.loginWithUserNameAndPassword({
            userName: this.username.toLowerCase().trim(),
            password: this.password.trim(),
            registrationToken: fcmToken,
            portal: 0
          });
          if (response.uId === undefined) {
            if (response.status === 200 && response.message === 'User not allowed') {
              alert("You don't have access to this portal");
            } else {
              alert("Wrong password");
            }
          } else {
            if (response.isPasswordReset !== 0) {
              this.resetPassword = true;
              this.username = "";
              this.password = "";
              this.uId = response.uId;
            } else {
              //commit user to store
              this.$store.commit("user/set_userData", {
                uId: response.uId,
              });
              //save to localstorage
              window.localStorage.setItem(
                "userKey",
                JSON.stringify({ uId: response.uId })
              );
              //redirect to institute
              showStatus("Login Successful!", 1000, "success", this);
              this.$router.push("/activitySelectInstituteNSemester");
            }
          }
        }
      } catch (e) {
        console.error(`Error logging with username and password: ${e.name}`);
        if (e.name === "FirebaseError") {
          alert("Allow notification permission from site settings");
        }
      } finally {
        this.isSendingRequest = false;
      }
    },
    async loginWithUserNameAndPasswordforCourses(subject) {
      try {
        let fcmToken = "";
        this.isSendingRequest = true;
        this.userRepositoryInstance = new UserRepository(this);
        //get fcm token
        if (firebase.messaging.isSupported()) {
          try {
            await firebase.messaging().requestPermission();
            fcmToken = await firebase.messaging().getToken();
          } catch (e) {
            if (e.code !== "messaging/permission-default") {
              console.error(e);
            }
          }
        }
        //login
        const response =
          await this.userRepositoryInstance.loginWithUserNameAndPassword({
            userName: this.username.toLowerCase().trim(),
            password: this.password.trim(),
            registrationToken: fcmToken,
          });
        if (response.uId === undefined) {
          alert("Wrong password");
        } else {
          if (response.isPasswordReset !== 0) {
            this.resetPassword = true;
            this.username = "";
            this.password = "";
            this.uId = response.uId;
          } else {
            //commit user to store
            this.$store.commit("user/set_userData", {
              uId: response.uId,
            });
            //save to localstorage
            window.localStorage.setItem(
              "userKey",
              JSON.stringify({ uId: response.uId })
            );
            window.localStorage.setItem("token",JSON.stringify({ uId: response.token }));
            //redirect to institute
            showStatus("Login Successful!", 1000, "success", this);
              this.$router.push({
                name: "activitySelectInstituteNSemester",
                params: {
                  prop_subject: subject,
                },
              });
          }
        }
      } catch (e) {
        console.error(`Error logging with username and password: ${e.name}`);
        if (e.name === "FirebaseError") {
          alert("Allow notification permission from site settings");
        }
      } finally {
        this.isSendingRequest = false;
      }
    },
    async passwordUpdate() {
      try {
        this.isSendingRequest = true;
        const response = await this.userRepositoryInstance.passwordUpdate({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          uId: this.uId,
        });
        if (response.error) {
          throw new Error(response.message);
        }
        alert("Password updated successfully");
        this.resetPassword = false;
      } catch (e) {
        if (e.message === "Current password does not match") {
          alert("Current password does not match");
        } else {
          alert("An error occured");
          this.resetPassword = false;
        }
        console.error(`Error resetting password: ${e}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    togglePhoneLogin() {
      this.signInWithPhone = !this.signInWithPhone;
    },
    async sendOtp() {
      try {
        this.isSendingRequest = true;
        const firebase = auth.getFirebaseObject();
        firebase.auth().useDeviceLanguage();
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha",
          {
            size: "invisible",
          }
        );
        const phoneNumber = `+91${this.phoneNum}`;
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier);
        this.confirmationResultFunc = confirmationResult;
        this.verificationCode = confirmationResult.verificationId;
        this.phoneAuthStep = 1;
      } catch (error) {
        console.error(`Error sending otp: ${error}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async confirmOtp() {
      try {
        this.isSendingRequest = true;
        const firebase = auth.getFirebaseObject();
        const credential = firebase.auth.PhoneAuthProvider.credential(
          this.verificationCode,
          this.phoneOtp
        );
        await firebase.auth().signInWithCredential(credential);
        // after this, the firebase auth listener automatically
        // detects the user and redirects to setup activity
        // check in created method below
      } catch (error) {
        console.error(`Error confirming otp: ${error}`);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async onForgotPassword() {
      try {
        this.isSendingRequest = true;
        //call API
        const data = { userName: this.email };
        this.userRepositoryInstance = new UserRepository(this);
       const response = await this.userRepositoryInstance.forgotPassword(data);
       alert("Check your email");
        this.forgotPassword = false;
      } catch (e) {
        console.error(`Error in forgot password: ${e}`);
        alert("An error occured");
      } finally {
        this.isSendingRequest = false;
      }
      this.email = ''
    },
    handleOnChange(value) {
      this.phoneOtp = value;
    },
    getLoginHeaderImage() {
      if (process.env.VUE_APP_LOGINPAGE_LOGO) {
        return require(`../../Assets/Images/${process.env.VUE_APP_LOGINPAGE_LOGO}`);
      }
    },
  },

  created() {
    //******************** logic for portal navigation ********************//
    const { userName: userNameParam, isRedirectedFromAdmin: isRedirectedFromAdmin, uId: redirectedUId, uuId: redirectedUuId, adminUId: redirectedAdminUId } = this.$route.query;

    if (userNameParam && isRedirectedFromAdmin === 'true') {
      this.username = decodeURIComponent(userNameParam);
      this.isRedirectedFromAdmin = isRedirectedFromAdmin;
      this.redirectedUId = redirectedUId;
      this.redirectedUuId = redirectedUuId
      this.redirectedAdminUId = redirectedAdminUId
      this.loginWithUserNameAndPassword();
    }
    //******************** logic for portal navigation ********************//
    if(process.env.VUE_APP_WHITELABEL_NAME){
      this.sppuWelcomePageChanges = process.env.VUE_APP_WHITELABEL_NAME
    }

    if (window.innerWidth < 1200) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    const self = this;
    if (window.innerWidth < window.innerHeight) {
      this.$store.commit("liveData/set_isMobileView", true);
      this.$store.commit("liveData/set_selectedActivity", 2);
    }
    const user = window.localStorage.getItem("userKey");
    if (user) {
      const { uId } = JSON.parse(user);
      self.$store.commit("user/set_userData", {
        uId,
      });
      self.$router.push("/activitySetup");
      return;
    }
    if (auth.user()) {
      self.$router.push("/activitySetup");
    }
    if (this.$route.params.userName && this.$route.params.password) {
      this.username = this.$route.params.userName
      this.password = this.$route.params.password
      if (this.$route.query.subjectId){
        let subject = {
          courseYear:this.$route.query.courseYear,
          department: this.$route.query.department,
          division: this.$route.query.division,
          subjectId: this.$route.query.subjectId,
          subjectName: this.$route.query.subjectName,
          subjectType: this.$route.query.subjectType
        };
        this.loginWithUserNameAndPasswordforCourses(subject);
      } else {
        this.loginWithUserNameAndPassword();
      }
    }
  },
};
</script>
<style src="./activityWelcome.css">

</style>
