<template src="./activityCorporateDashboard.html"></template>

<script>
import VueApexCharts from "vue-apexcharts";
import LectureRepository from "../../../Repository/lectures";
import apiV3 from "../../../NetworkManager/apiV3";
import TopicFeedbackRepository from '../../../Repository/TopicFeedback'
import AssignmentUserRepository from "../../../Repository/AssignmentUser"
import UserRepository from '../../../Repository/User'
import { convertTime } from '../../../Services/Utils/DateTimeFormatter'

// import LectureRepository from '../../../Repository/lectures'

import { addMinutes, compareAsc, isFuture, isWithinInterval, parseISO } from 'date-fns'
import CourseRepository from "../../../Repository/Course";

export default {
  name: "activityCorporateDashboard",
  components: {
    apexcharts: VueApexCharts
  },
  data() {
    return {
      subjectNameArrayForAverage: [],
      isCorporate: false,
      isEdcInstitute: false,
      scrollInvoked: 0,
      todayDate: null,
      month: '',
      timetable: [],
      // isCorporate: true,
      subjectAverageArray: [],
      questionCount: 0,
      subjectFeedbackStarCount: 0,
      totalFeedbackFormCount: 0,
      submittedFeedbackFormCount: 0,
      assignmentPercentage: 0,
      assignmentAnalysis: {},
      attendanceAnalysis: {},
      absolute: true,
      overlay1: true,
      overlay2: true,
      overlay3: true,
      overlay4: true,
      overlay5: true,
      overlay6: true,
      subjectCategories: [],
      subjectSum: 0,
      subjectNameArray: [],
      subjectIdArray: [],
      quickTest: [],
      sum: 0,
      marks: 0,
      categories: [],
      studentCourse: [],
      allSubjectsAverage: 0,
      subjectAverage: 0,
      subjectGrandTotal: 0,
      totalConducted: 0,
      attendance: 0,
      totalPresent: 0,
      feedbackStatusPercentage: 0,
      totalSubsections: 0,
      subsectionCompletedCount: 0,
      selectedInstitute: "",
      userData: {},
      selectedSemester: {},
      isMobileView: false,
      progressBar: false,
      navList: ['Course Material', 'Assignment', 'Study Material'],
      items: [
        "Quick Exam Analysis",
        "Semester Exam Analysis",
        "Subject-wise analysis"
      ],
      listItem: [
        "Course 1",
        "Course 2",
        "Course 3",
        "Course 4",
        "Course 5",
        "Course 6",
        "Course 7",
        "Course 8",
        "Course 9"
      ],
      barSeries: [],
      barOptions: {
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "red",
            fontSize: "22px"
          }
        },
        colors: ["#050992"],
        chart: {
          type: "bar",
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25%",
            endingShape: "rounded",
            borderRadius: 10,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 30,
                  color: "#FA3333"
                },
                {
                  from: 30,
                  to: 60,
                  color: "#11BC7D"
                }
              ]
            }
          }
        },
        yaxis: {
          title: {
            text: "Performance (%)"
          }
        },
        xaxis: {
          title: {
            text: "Course"
          },
          tickPlacement: "between"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          // custom: function({ series, seriesIndex, dataPointIndex, w }) {
          //   return (
          //     '<div class="arrow_box">' +
          //     "<span>" +
          //     w.globals.labels[dataPointIndex] +
          //     ": " +
          //     series[seriesIndex][dataPointIndex] +
          //     "</span>" +
          //     "</div>"
          //   )
          // }
          enabled: false,
          PerformancePercentage: 0
        }
      }
    };
  },
  async created() {
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userData = this.$store.getters["user/get_userData"];
    console.log('this.userData', this.userData);
    // this.isMobileView = false
    this.isCorporate = this.$store.getters["liveData/get_isCorporateInstitute"];
    let name = "My Progress"
    if (this.isCorporate) {
      name = "Overview"
    }
    this.$store.commit("liveData/set_selectedActivityName", name);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }
    // this.isCorporate = true
    // const check = this.$store.getters["liveData/get_isCorporateInstitute"];
    // console.log('this.isCorporate', this.isCorporate);
    // console.log('check', check);
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.CourseRepositoryInstance = new CourseRepository(this);
    if (process.env.VUE_APP_WHITELABEL_NAME) {
      this.careerfirstChanges = process.env.VUE_APP_WHITELABEL_NAME
    }
    if (this.careerfirstChanges !== 'careerfirst') {
      this.attendanceAnalysisFunc()
      this.examPerformanceFunc()
      this.assignmentStatusFunc()
      this.feedbackAnalysisFunc()
    }
    this.fetchStudentData()
  },
  methods: {
    calculatePercentage(completed, total) {
      const percentage = Math.ceil((completed / total) * 100)

      if (completed === 0) {
        return 0
      }
      return percentage
    },
    onScroll() {
      this.scrollInvoked++
    },
    gotoSchedule() {
      this.$router.push({
        name: "activityMyTimetable"
      });
    },
    gotoListNav(item, index) {
      console.log('item1', item)
      //  let subjectIds = []
      //  subjectIds = Object.keys(this.subjectsMapping)
      console.log('this.subjectsMapping', this.subjectsMapping);
      const objForRoute = this.subjectsMapping[item];

      objForRoute.courseYear = this.selectedSemester.courseYear
      objForRoute.department = this.selectedSemester.department
      objForRoute.division = this.selectedSemester.division
      const arrayForSub = [objForRoute]
      console.log('objForRoute', objForRoute);
      if (index === 0) {

        this.$router.push({
          name: "activityCourseMaterialRevamped",
          params: {
            prop_subject: objForRoute,
            prop_subjects: arrayForSub
          }
        });
      }
      if (index === 1) {
        console.log('222');
        // this.$router.push("/activityStudyMaterial");
        this.$router.push({
          name: "activityAssignments",
          params: {
            prop_subject: objForRoute
          }
        });
      }
      if (index === 2) {
        this.$router.push({
          name: "activityStudyMaterial",
          params: {
            prop_subject: objForRoute
          }
        });
      }

    },
    async attendanceAnalysisFunc() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId,
        department: this.userData.department,
        courseYear: this.userData.courseYear,
        division: this.userData.division,
        batch: this.userData.batch
      };
      try {
        this.attendanceAnalysis =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForAStudent(
            objToPush
          );
        this.attendanceAnalysis.map((subject) => {
          this.totalPresent += parseInt(subject.totalAttended);
          this.totalConducted += parseInt(subject.totalConducted);
        });
        this.attendance = Math.round(
          (this.totalPresent / this.totalConducted) * 100
        );
      } catch (err) {
        this.attendanceAnalysis = [];
        console.log(err);
      }

      this.overlay1 = false
    },
    async examPerformanceFunc() {
      const response = await apiV3.getRequest("/exam/all", {
        category: "quick_exam",
        semId: this.userData.semId,
        studentId: this.userData.uId
      });
      await this.processQuickTests(response.exams);
      console.log('response', response);

      for (const test of this.quickTest) {
        if (test.state === 'conducted') {
          this.categories.push(test.name)
          if (isNaN(test.totalMarksObtained)) {
            this.marks += 0
            this.sum += 0
          } else {
            this.marks += parseInt(test.totalMarksObtained)
            this.sum += parseInt(test.totalOutOf)
          }
          if (this.subjectNameArray.indexOf(test.subjectName) === -1) {
            this.subjectNameArray.push(test.subjectName)
          }
        }
      }

      const data = []
      this.subjectNameArray.map((subjectName) => {
        this.subjectSum = 0
        for (const test of this.quickTest) {
          if (test.state === 'conducted' && test.subjectName === subjectName) {
            this.subjectCategories.push(test.name)
            if (isNaN(test.totalMarksObtained)) {
              this.subjectSum += 0
              this.subjectGrandTotal += 0
            } else {
              this.subjectSum += parseInt(test.totalMarksObtained)
              this.subjectGrandTotal += parseInt(test.totalOutOf)
            }
          }
        }

        const subjectAverage = (this.subjectSum / this.subjectGrandTotal) * 100
        data.push(subjectAverage.toFixed(2))
      })

      this.barOptions = {
        ...this.barOptions,
        xaxis: { categories: this.subjectNameArray }
      }

      this.barSeries = [{ name: "subject name", data: data }]

      const AVG = (this.marks / this.sum) * 100;
      if (isNaN(AVG)) {
        this.allSubjectsAverage = 0
      } else {
        this.allSubjectsAverage = parseInt(AVG)
      }
      this.overlay2 = false
      this.overlay3 = false
    },
    async assignmentStatusFunc() {
      const objToPush1 = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      };
      try {
        this.assignmentAnalysis =
          await this.assignmentUserRepositoryInstance.assignmentAnalysis(objToPush1);
      } catch (err) {
        this.assignmentAnalysis = [];
        console.log(err);
      }

      const avg = (this.assignmentAnalysis.totalSubmittedAssignmentCount / this.assignmentAnalysis.totalSubjectAssignmentCount) * 100
      if (isNaN(avg)) {
        this.assignmentPercentage = 0
      } else {
        this.assignmentPercentage = avg
      }
      this.overlay4 = false
    },
    async feedbackAnalysisFunc() {
      this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
      for (const [key] of Object.entries(this.subjectsMapping)) {
        this.subjectIdArray.push(`${key}`)
      }
      const res = await this.topicFeedbackRepositoryInstance.getTopicFeedbacksForStudent(
        {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          subjectIds: this.subjectIdArray
        }
      );
      this.submittedFeedbackFormCount = 0
      res.map((subject) => {
        Object.values(subject).forEach(element => {
          this.subjectFeedbackStarCount = 0
          this.subejctFeedbackAverage = 0
          this.totalFeedbackFormCount += parseInt(element.length)
          element.map((e) => {
            if (e.responses !== undefined) {
              for (const [key, value] of Object.entries(e.responses)) {
                if (key === '0') {
                  for (const [resKey] of Object.entries(value)) {
                    if (resKey === this.userData.uId) {
                      this.submittedFeedbackFormCount += 1
                    }
                  }
                }
                for (const [resKey, resValue] of Object.entries(value)) {
                  this.questionCount += 1
                  console.log('resKey', resKey);
                  this.subjectFeedbackStarCount += parseInt(resValue)
                }
                this.subejctFeedbackAverage = Math.round(this.subjectFeedbackStarCount / this.questionCount)
              }
            }
          })
          // console.log('this.subjectsMapping', this.subjectsMapping);
          for (const [key] of Object.entries(subject)) {
            Object.values(this.subjectsMapping).forEach((details) => {
              if (details.subjectId === key) {
                this.subjectAverageArray.push({ subjectName: details.subjectName, subjectAvg: this.subejctFeedbackAverage })
              }
            })
          }
        });
      })
      console.log('this.subjectAverageArray', this.subjectAverageArray);
      const avg = ((this.submittedFeedbackFormCount / this.totalFeedbackFormCount) * 100).toFixed(2)
      if (isNaN(avg)) {
        this.feedbackStatusPercentage = 0
      } else {
        this.feedbackStatusPercentage = avg
      }
      this.overlay5 = false
      this.overlay6 = false
    },
    async processQuickTests(exams) {
      const now = new Date()
      for (const test of exams) {
        let state = ''
        let hasSubmitted = false
        const examStartTime = parseISO(test.dateTimeUTC)

        if (isFuture(examStartTime)) {
          state = 'pending'
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }

        const endTime = addMinutes(examStartTime, test.durationInMins)
        if (compareAsc(endTime, now) === -1 || compareAsc(endTime, now) === 0) {
          state = 'conducted'
          hasSubmitted = false
          const response = await this.getExamAnswers(test._id)
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            hasSubmitted = true
            const { totalMarksObtained, totalOutOf } = response
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`
            test.totalMarksObtained = totalMarksObtained
            test.totalOutOf = totalOutOf
          }
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }

        const isOngoing = isWithinInterval(now, { start: examStartTime, end: endTime })
        if (isOngoing) {
          state = 'ongoing'
          hasSubmitted = false
          const response = await this.getExamAnswers(test._id)
          //array is an empty response from getExamAnswers
          if (!Array.isArray(response)) {
            const { totalMarksObtained, totalOutOf } = response
            test.totalMarks = `${totalMarksObtained}/${totalOutOf}`
            hasSubmitted = true
          }
          this.quickTest.push({ ...test, state, hasSubmitted })
          continue
        }
      }
    },
    async getExamAnswers(examId) {
      try {
        const response = await apiV3.getRequest("/exam/answers", {
          studentId: this.userData.uId,
          examId
        })
        return response
      } catch (e) {
        return []
      }
    },
    convertDateToUTC(date) {
      return new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds())
    },
    async fetchStudentData() {
      const sectionIds = []
      this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
      for (const [key] of Object.entries(this.subjectsMapping)) {
        this.subjectIdArray.push(`${key}`)
      }
      try {
        const objToPush = {
          instituteId: this.selectedSemester.instituteId,
          semId: this.selectedSemester.semId,
          department: this.selectedSemester.department,
          courseYear: this.selectedSemester.courseYear,
          courseIds: this.subjectIdArray,
          uId: this.selectedSemester.uId
        }
        const result = await this.CourseRepositoryInstance.getAllCoursesInfoOfSingleStudent(objToPush)
        console.log('result', result);
        // this.studentCourse = result.course
        // for (const [key, value] of Object.entries(this.subjectsMapping)) {
        //   this.studentCourse.map((obj) => {
        //     if (obj.courseId === key) {
        //       obj.subjectName = value.subjectName
        //     }
        //   })
        // }
        try {
          const objToPush = {
            instituteId: this.selectedSemester.instituteId,
            semId: this.selectedSemester.semId,
            department: this.selectedSemester.department,
            courseYear: this.selectedSemester.courseYear
          }
          this.courseDetails = await this.CourseRepositoryInstance.getAllCoursesInfoOfInstitute(objToPush)
          if (this.userData.subjects && this.courseDetails.course) {
            this.courseDetails.course = this.courseDetails.course.filter(course =>
              this.userData.subjects.includes(course.courseId)
            );
          }
          for (const [key, value] of Object.entries(this.subjectsMapping)) {
            this.courseDetails.course.map((obj) => {
              if (obj.courseId === key) {
                obj.subjectName = value.subjectName
              }
            })
          }
          this.courseDetails.course.map((course) => {
            const obj = {
              subjectName: course.subjectName,
              courseId: course.courseId,
              totalPercentage: 0,
              subsectionCompletedCountByStudent: 0,
              sectionByAdmin: course.sections
            }
            course['totalSubSectionCount'] = 0
            if (course.sections && course.sections.length > 0) {
              course.sections.map((data) => {
                course['totalSubSectionCount'] = course['totalSubSectionCount'] + data.subSections.length
                data.subSections.map((sub) => {
                  sectionIds.push(sub.subSectionId)
                })
              })
            }
            if (course.courseImage) {

              obj.imageLink = course.courseImage === undefined ? 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685518607762________How-to-create-an-online-course.jpg.webp?alt=media&token=43c1c2da-63fa-4c85-b0cd-652623564e43' : course.courseImage
            } else {
              const arr = ['https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529284450________Free-Online-Courses-with-Certificates-800x500.jpg?alt=media&token=1def7304-5a5c-4d7f-9006-d90f2b7cbeb8', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685518607762________How-to-create-an-online-course.jpg.webp?alt=media&token=43c1c2da-63fa-4c85-b0cd-652623564e43', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529391961________medium-illustration-woman-graduation-cap.webp?alt=media&token=fa28bf7a-310d-47b5-a906-d44d4bb395df', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529875237________online759.jpg?alt=media&token=bf7caba3-ebb1-4f5f-9cb3-3540b90ea4d2']
              const num = Math.floor(Math.random() * 4)
              obj.imageLink = arr[num]

            }
            obj.totalSubSectionCount = course.totalSubSectionCount
            this.studentCourse.push(obj)
          })
          result.course.map((stuCourse) => {
            stuCourse.subsectionCompletedCountByStudent = 0
            if (stuCourse.sections.length > 0) {
              stuCourse.sections.map((data) => {
                console.log('data.sectionId1', data.sectionId);
                data.subSections.map((info) => {
                  if (sectionIds.includes(info.subSectionId)) {
                    if (info.isSubsectionCompleted === true) {
                      stuCourse.subsectionCompletedCountByStudent += 1
                    }
                  }
                })

              })
            }
            this.studentCourse.map((data) => {
              // console.log('stuCourse.subsectionCompletedCountByStudent', stuCourse.subsectionCompletedCountByStudent);
              if (data.courseId === stuCourse.courseId) {
                data.courseYear = stuCourse.courseYear
                data.department = stuCourse.department
                data.instituteId = stuCourse.instituteId
                data.semId = stuCourse.semId
                data.uId = stuCourse.uId
                data.subsectionCompletedCountByStudent = stuCourse.subsectionCompletedCountByStudent
                if (data.subsectionCompletedCountByStudent === 0) {
                  data.totalPercentage = 0
                } else {
                  data.totalPercentage = Math.ceil((data.subsectionCompletedCountByStudent / data.totalSubSectionCount) * 100)
                }
              }
            })
          })
          const arr = ['https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529284450________Free-Online-Courses-with-Certificates-800x500.jpg?alt=media&token=1def7304-5a5c-4d7f-9006-d90f2b7cbeb8', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685518607762________How-to-create-an-online-course.jpg.webp?alt=media&token=43c1c2da-63fa-4c85-b0cd-652623564e43', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529391961________medium-illustration-woman-graduation-cap.webp?alt=media&token=fa28bf7a-310d-47b5-a906-d44d4bb395df', 'https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1685529875237________online759.jpg?alt=media&token=bf7caba3-ebb1-4f5f-9cb3-3540b90ea4d2']

          let allSubject = []
          let remainSub = []
          const studentIds = []
          allSubject = Object.keys(this.subjectsMapping)

            this.studentCourse.map((stu) => {
              studentIds.push(stu.courseId)
            })
          remainSub = allSubject.filter(function(obj) { return studentIds.indexOf(obj) === -1; });
          remainSub.map((data, i) => {
            const num = Math.ceil(Math.random() * 3) - 1
            const obj = {
              courseCredit: 0,
              courseStudentCredit: 0,
              subjectName: this.subjectsMapping[data].subjectName,
              courseId: this.subjectsMapping[data].subjectId,
              subsectionCompletedCountByStudent: 0,
              totalPercentage: 0,
              totalSubSectionCount: 0,
              imageLink: arr[num]
            }
            this.studentCourse.push(obj)
          })

        } catch (err) {
          console.log(err)
        }
      } catch (e) {
        this.studentCourse = []
        console.log('e', e);
      }
      try {
        const date = new Date()
        this.todayDate = date.getDate()
        this.month = date.toLocaleString('default', { month: 'long' });

        this.timetable2 = []
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          multiDivision: this.userData.division,
          batch: this.userData.batch,
          // today: this.today,
          dateOfLecturesNeeded: this.convertDateToUTC(new Date())
        }
        console.log('objToPush', objToPush);
        // const lectrerUids = []
        // const roomIds = []
        this.timetable = []
        this.timetable = await this.lectureRepositoryInstance.getLecturesForADayForAStudent(objToPush)
        // let nameOfFaculty;
        const uIdArray = [];
        this.timetable.map(async (data) => {
          uIdArray.push(data.uId)
          // data.facultyName = nameOfFaculty[0].fullName
          data.subjectName = this.subjectsMapping[data.subjectId].subjectName
          data.subjectType = this.subjectsMapping[data.subjectId].subjectType
          data.startEndTimeLocal = convertTime(data.startTime) + '-' + convertTime(data.endTime)
        })
        let lecturerNames = []
        if (uIdArray.length > 0) {

          lecturerNames = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: uIdArray })
        }
        console.log('lecturerNames', lecturerNames);

        lecturerNames.map((data) => {
          // console.log('data', data);
          this.timetable.map((info) => {
            if (info.uId === data.uId) {
              info.facultyName = data.fullName
            }

          })
        })
        console.log('this.timetable', this.timetable);
      } catch (error) {
        console.log(error);
        console.log('111')
      }
      this.syncCourseData()
    },
    async syncCourseData() {
      // console.log('this.studentCourse', this.studentCourse);
      this.studentCourse.map((course) => {
        course.courseCredit = 0
        course.courseStudentCredit = 0
        course.sections.map((section) => {
          section.totalCredit = 0
          section.totalStudentCredit = 0
          this.totalSubsections += section.subSections.length
          section.subSections.map((subsection) => {
            this.subsectionCompletedCount += subsection.isSubsectionCompleted === true ? 1 : 0
            section.totalCredit += subsection.subsectionCredit ? parseInt(subsection.subsectionCredit) : 0
            section.totalStudentCredit += subsection.subsectionStudentCredit ? parseInt(subsection.subsectionStudentCredit) : 0
          })
          course.courseCredit += section.totalCredit ? parseInt(section.totalCredit) : 0
          course.subsectionCompletedCount += section.totalCredit ? parseInt(section.totalCredit) : 0
          course.courseStudentCredit += section.totalStudentCredit ? parseInt(section.totalStudentCredit) : 0
        })
        course.PerformancePercentage = (Number(this.subsectionCompletedCount) / Number(this.totalSubsections) * 100)
        this.PerformancePercentage = isNaN(Number(course.PerformancePercentage)) ? 0 : Number(course.PerformancePercentage)
        this.progressBar = true
      })
    },
    gotoCourseMaterial(subject) {
      // console.log('subject', subject);
      // console.log('sas', this.selectedSemester);
      subject.courseYear = this.selectedSemester.courseYear
      subject.department = this.selectedSemester.department
      subject.division = this.selectedSemester.division
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseMaterialRevamped2",
          params: {
            prop_subject: subject,
            prop_subjects: this.subjects
          }
        });
      } else {
        console.log('subject', subject);
        console.log('this.subjects', this.subjects);
        this.$router.push({
          name: "activityCourseMaterialRevamped",
          params: {
            prop_subject: subject,
            prop_subjects: this.subjects
          }
        });
      }
    }
  }
};
</script>

<style src="./activityCorporateDashboard.css" scoped></style>
