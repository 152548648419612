<template src="./activityCourseMaterial.html"></template>

<script>
import SubjectsRepository from "../../Repository/Subjects";
import CourseRepository from "../../Repository/Course";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
// import auth from "../../Services/Firebase/auth";
// import UserRepository from "../../Repository/User";
// import firebase from "firebase";
// import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "activityCourseMaterial",
  props: ["prop_subject", "prop_courseDetails", "prop_subjects"],
  data() {
    return {
      isCorporateInstitute: false,
      isEdcInstitute: false,
      selectedInstitute: '',
      userAuthData: [],
      selected: [],
      e1: 1,
      dialog1: false,
      dialog2: false,
      isCourseCompleted: false,
      isMobileView: false,
      interval: {},
      value: 0,
      totalCreditsRecieved: 0,
      creditsInPercentage: 0,
      totalCredit: 0,
      skill: '',
      subject: '',
      selectedSemester: {},
      myCourse: {},
      sections: [],
      studentCourse: [],
      courseDetails: {},
      subsecPercent: 0,
      selectedItem: 1,
      subSecRating: 0,
      shortAns: '',
      selectedOption: null,
      isLoading: false,
      subsectinsCount: 0,
      subsectionCompletedCount: 0,
      progressPercentage: 0
    }
  },
  created() {
    if (this.prop_subject) {
      this.$store.commit("liveData/set_selectedCourse", {
        selectedCourse: this.prop_subject
      });
    }
    this.selectedSubject = this.$store.getters["liveData/get_selectedCourse"];
    this.$store.commit("liveData/set_selectedActivityName", this.selectedSubject.subjectName);
    this.courseName = this.selectedSubject.subjectName
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.isCorporateInstitute = this.$store.getters["liveData/get_isCorporateInstitute"];
    // this.isCorporateInstitute = true
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.CourseRepositoryInstance = new CourseRepository(this);
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    if (this.selectedInstitute === 'efe22e7d-5c51-4163-bb86-94119768ac79') {
      this.isEdcInstitute = true
    }
    this.fetchData();
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 10
    }, 1000)
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedSemester.instituteId,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          subjectId: this.selectedSubject.subjectId,
          courseName: this.selectedSubject.subjectName,
          isCoursePublished: true
        }
        this.myCourse = await this.CourseRepositoryInstance.getSingleCourse(objToPush);
        this.courseDetails = this.myCourse[0]
        this.fetchStudentData()
      } catch (err) {
        console.log(err)
      }
    },
    async fetchStudentData() {
      try {
        this.sections = []
        this.studentCourse = {}
        const objToPush = {
          instituteId: this.selectedSemester.instituteId,
          semId: this.selectedSemester.semId,
          department: this.selectedSemester.department,
          courseYear: this.selectedSemester.courseYear,
          courseId: this.selectedSubject.subjectId,
          uId: this.selectedSemester.uId
        }
        const result = await this.CourseRepositoryInstance.getSingleStudentCourse(objToPush)
        if (result.course.length > 0) {
          this.studentCourse = result.course[0]
        } else {
          this.studentCourse = { isFeedbackGiven: false }
        }
      } catch (e) {
        this.sections = []
        this.studentCourse = {}
      }
      this.syncCourseData()
    },
    syncCourseData() {
      this.sectionCompletedCount = 0
      console.log('this.courseDetails.sections', this.courseDetails.sections);
      this.courseDetails.sections.forEach((obj) => {
        this.subsectinsCount += parseInt(obj.subSections.length)
        obj.isSectionCompleted = false
        obj.subsectionCompletedCount = 0
        obj.subsecPercent = 0
        obj.subSections.forEach((subsection, index) => {
          this.totalCredit += parseInt(subsection.subsectionCredit) ? parseInt(subsection.subsectionCredit) : 0
          subsection.isSubsectionCompleted = false
          subsection.assignmentDescription = ''
          subsection.assignmentLink = ''
          subsection.assignmentFileUrl = ''
          subsection.isChecked = false
          subsection.subsectionStudentCredit = 0
          subsection.studentAnswers = []
          subsection.studentMarks = 0
          subsection.studentTimeTaken = 0
          subsection.listenTime = 0
          subsection.audLength = 1
          subsection.watchTime = 0
          subsection.vidLength = 1
          this.subSection = subsection
          if (subsection.isSubsectionPublished === undefined) {
            subsection.isSubsectionPublished = true
          }
        })
        if (obj.isSectionPublished === undefined) {
          obj.isSectionPublished = true
        }
        if (this.studentCourse.sections) {
          this.studentCourse.sections.forEach((section) => {
            // console.log('obj', obj);
            // console.log('section', section);
            console.log('obj._id', obj._id, section.sectionId);
            if (obj._id === section.sectionId) {
              obj.isSectionCompleted = section.isSectionCompleted
              obj.creditsRecieved = section.creditsRecieved
              this.totalCreditsRecieved += parseInt(obj.creditsRecieved) ? parseInt(obj.creditsRecieved) : 0
              const tempSubsections = []
              obj.subSections.forEach(itm => {
                const stud = section.subSections.find(stud => itm.subSectionId === stud.subSectionId) ? section.subSections.find(stud => itm.subSectionId === stud.subSectionId) : {}
                // stud.studentAnswers[0].answers.map((e) => {
                //   if (e.imageForSolution) {

                //   }
                // })
                tempSubsections.push({
                  ...itm,
                  isSubsectionCompleted: stud.isSubsectionCompleted ? stud.isSubsectionCompleted : false,
                  isChecked: stud.isChecked ? stud.isChecked : false,
                  assignmentDescription: stud.assignmentDescription ? stud.assignmentDescription : '',
                  assignmentLink: stud.assignmentLink ? stud.assignmentLink : '',
                  assignmentFeedback: stud.assignmentFeedback ? stud.assignmentFeedback : '',
                  assignmentFileUrl: stud.assignmentFileUrl ? stud.assignmentFileUrl.split('/')[stud.assignmentFileUrl.split('/').length - 1] : '',
                  checkedAssignmentFileUrl: stud.checkedAssignmentFileUrl ? stud.checkedAssignmentFileUrl : '',
                  subsectionStudentCredit: stud.subsectionStudentCredit ? stud.subsectionStudentCredit : 0,
                  studentAnswers: stud.studentAnswers ? stud.studentAnswers : [],
                  studentMarks: stud.studentMarks ? stud.studentMarks : 0,
                  studentTimeTaken: stud.studentTimeTaken ? stud.studentTimeTaken : 0,
                  feedbackArr: stud.feedbackArr ? stud.feedbackArr : itm.feedbackArr
                })
                obj.subsectionCompletedCount += stud.isSubsectionCompleted === true ? 1 : 0
              });
              obj.subsecPercent = ((obj.subsectionCompletedCount / obj.subSections.length) * 100).toFixed(2)
              obj.subSections = tempSubsections
            }
          })
        }
        obj.isSectionCompleted = obj.subsectionCompletedCount === obj.subSections.length
        this.sectionCompletedCount += obj.isSectionCompleted === true ? 1 : 0
        this.sections.push(obj)
        this.subsectionCompletedCount += obj.subsectionCompletedCount
        this.progressPercentage = (this.subsectionCompletedCount / this.subsectinsCount) * 100
      })
      this.isCourseCompleted = this.sectionCompletedCount === this.sections.length
      // this.creditsInPercentage = (this.totalCreditsRecieved / this.totalCredit) * 100
    },
    getImgUrl() {
      const image = this.courseDetails.courseImage
      return image
    },
    async gotoCourseSection(section, index) {
      section.courseYear = this.selectedSemester.courseYear
      section.department = this.selectedSemester.department
      section.division = this.selectedSemester.division
      section.courseId = this.courseDetails.courseId
      this.$store.commit("liveData/set_selectedSection", {
        selectedSection: section
      });
      this.$store.commit("liveData/set_courseSections", {
        courseSections: this.sections
      });
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseSection2",
          params: {
            prop_course: section,
            prop_courseDetails: this.courseDetails
          }
        });
      } else {
        this.$router.push({
          name: "activityCourseSection",
          params: {
            prop_course: section,
            prop_courseDetails: this.courseDetails
          }
        });
      }
      // const analytics = getAnalytics(auth);
      // logEvent(analytics, "gotoCourseSection", {
      //   content_type: "card",
      //   item_id: "gotoCourseSection"
      // });
    },
    goToCertificate() {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseCertificate2",
          params: {
            prop_course: this.courseDetails
          }
        });
      } else {
        this.$router.push({
          name: "activityCourseCertificate",
          params: {
            prop_course: this.courseDetails
          }
        });
      }
    },
    gotoactivityCourseFeedback(courseDetails) {
      if (this.courseDetails.courseFeedback === true) {
        if (this.isMobileView) {
          this.$router.push({
            name: "activityCourseFeedback2",
            params: {
              prop_course: this.courseDetails,
              selectedSubject: this.selectedSubject,
              prop_courseDetails: this.courseDetails
            }
          });
        } else {
          this.$router.push({
            name: "activityCourseFeedback",
            params: {
              prop_course: this.courseDetails,
              selectedSubject: this.selectedSubject,
              prop_courseDetails: this.courseDetails
            }
          });
        }
      } else {
        if (this.courseDetails.courseCertification === true) {
          this.goToCertificate()
        } else {
          alert("You have successfully completed course")
        }
      }
    },
    goBack() {
      if (this.isCorporateInstitute === true) {
        this.$router.push({
            name: "activityCorporateDashboard"
          });
      } else {

        if (this.isMobileView) {
          this.$router.push({
            name: "activityMySubjects",
            params: {
              prop_subjects: this.prop_subjects
            }
          });
        } else {
          this.$router.push({
            name: "activityMySubjects",
            params: {
              prop_subjects: this.prop_subjects
            }
          });
        }
      }
    }
  }
}

</script>

<style scoped src="./activityCourseMaterial.css"></style>
