import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Semester'

class Semester {
  constructor (context) {
    this.context = context
  }

  async getDivisionsOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDivisionsOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.divisions
  }

  async getShiftOfADivisionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShiftOfADivisionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shiftId
  }

  async getNameOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNameOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.nameOfSemester
  }

  async getSemestersBySemId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getSemestersBySemId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesters
  }

  async getNamesOfSemesters (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNamesOfSemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.namesOfSemesters
  }
}

export default Semester
