<template src="./activityAssignmentResult.html"></template>
<script>
// import SubjectsRepository from "../../Repository/Subjects";

export default {
    name: "activityAssignmentResult",
    props: ["prop_courseDetails", "prop_student", "prop_QuizeCourse", "prop_indexSelectedSubSection", "prop_indexOfSelectedSection"],
    data() {
        return {
            isMobileView: false,
            isLoading: false,
            course: {},
            courseSections: [],
            subSections: [],
            studentAns: '',
            secondCount: 0,
            counter: 0,
            total: 0,
            obtainedMarks: 0,
            attemptNo: '',
            correct: '',
            wrong: '',
            count: 0,
            timeTaken: 0,
            T: 0,
            time: 0,
            attemptNames: [],
            studentMarks: [],
            timerCount: this.prop_course.durationOfquiz * 60000,
            indexSelectedSubSection: this.prop_indexSelectedSubSection,
            indexOfSelectedSection: this.prop_indexOfSelectedSection
        }
    },
    created() {
        console.log('this.prop_student', this.prop_student);
        this.$store.commit("liveData/set_selectedActivityName", this.prop_student.courseName);
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.courseSections = this.$store.getters["liveData/get_courseSections"]
        this.course = this.prop_student;
        console.log('this.course', this.course);
        this.prop_course.studentAnswers.map((obj, i) => {
            this.attemptNames.push({ name: `Attempt No ${i + 1}`, number: i })
        })
        this.courseName = this.prop_course.courseName
    },
    methods: {
        downloadFile(file) {
            window.location.href = file;
        },
        calculateAttempt(i) {
            if (this.course.studentAnswers[i - 1]) {
                this.getTimeTaken(i - 1)
                this.getStudentMarks(i - 1)
                this.tempMarks(i - 1)
                this.getData(i - 1)
                this.correctAns(i - 1)
            } else {
                alert('Attempt Not Given')
                this.studentMarks = []
                this.obtainedMarks = 0
                this.count = 0
                this.time = 0
            }
        },
        getTimeTaken(i) {
            this.timeTaken = this.course.studentAnswers[i].timeTaken
            var minutes = Math.floor(this.timeTaken / 60000);
            var seconds = ((this.timeTaken % 60000) / 1000).toFixed(0);
            this.time = minutes + ":" + (seconds < 10 ? '0' : '') + seconds
        },
        tempMarks(i) {
            this.course.studentAnswers[i].answers.map((obj) => {
                this.total = obj.marks
            })
        },
        getData(i) {
            this.studentMarks = this.course.studentAnswers[i].answers
        },
        goBack() {
            if (this.isMobileView) {
                this.$router.push({
                    name: "activityCourseSection2",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails
                    }
                });
            } else {
                this.$router.push({
                    name: "activityCourseSection",
                    params: {
                        prop_course: this.prop_subjectInfo,
                        prop_indexOfSelectedSection: this.indexOfSelectedSection,
                        prop_indexSelectedSubSection: this.indexSelectedSubSection,
                        prop_courseDetails: this.prop_courseDetails
                    }
                });
            }
        },
        getStudentMarks(i) {
            this.obtainedMarks = this.course.studentAnswers[i].studentMarks
            console.log('this.obtainedMarks', this.obtainedMarks);
        },
        correctAns(i) {
            this.count = 0
            this.course.studentAnswers[i].answers.map((obj) => {
                console.log('objnilesh', obj);
                if (obj.studentTempAnswers === obj.answer) {
                    this.count += 1
                    console.log('this.count', this.count);
                }
            })
        }
    }
}
</script>
<style scoped src="./activityAssignmentResult.css">

</style>
