import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/AssignmentUser'

class AssignmentUser {
  constructor (context) {
    this.context = context
  }

  async createAssignmentUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createAssignmentUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignmentUser
  }

  async getSubmittedAssignmentsOfAnUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubmittedAssignmentsOfAnUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assignmentUsers
  }

  async assignmentAnalysis (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.assignmentAnalysis, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default AssignmentUser
