const state = {
  loading: false,
  selectedActivity: 2,
  selectedActivityName: '',
  statusMessage: 'Success!',
  statusDuration: 2000,
  statusType: 'success',
  selectedCourse: {},
  selectedSection: {},
  snackbar: false,
  editingSemester: {},
  courseSections: {},
  isMobileView: false,
  isStudyMaterialDownloadable: false,
  selectedExam: {},
  attendanceInfoByQr: {},
  isCorporateInstitute: false,
  brandingName: ''
}

const getters = { // getters for state
  get_loading: state => state.loading,
  get_selectedCourse: state => state.selectedCourse,
  get_selectedSection: state => state.selectedSection,
  get_selectedActivity: state => state.selectedActivity,
  get_selectedActivityName: state => state.selectedActivityName,
  get_statusMessage: state => state.statusMessage,
  get_statusDuration: state => state.statusDuration,
  get_statusType: state => state.statusType,
  get_editingSemester: state => state.editingSemester,
  get_isMobileView: state => state.isMobileView,
  get_selectedExam: state => state.selectedExam,
  get_courseSections: state => state.courseSections,
  get_isStudyMaterialDownloadable: state => state.isStudyMaterialDownloadable,
  get_attendanceInfoByQr: state => state.attendanceInfoByQr,
  get_isCorporateInstitute: state => state.isCorporateInstitute,
  get_brandingName: state => state.brandingName
}

const mutations = { // mutations for state
  set_loading: (state, value) => {
    state.loading = value
  },
  set_selectedCourse: (state, value) => {
    state.selectedCourse = value.selectedCourse
  },
  set_courseSections: (state, value) => {
    state.courseSections = value.courseSections
  },
  set_isStudyMaterialDownloadable: (state, value) => {
    state.isStudyMaterialDownloadable = value
  },
  set_selectedActivity: (state, value) => {
    state.selectedActivity = value
  },
  set_selectedActivityName: (state, value) => {
    state.selectedActivityName = value
  },
  set_statusMessage: (state, value) => {
    state.statusMessage = value
  },
  set_statusDuration: (state, value) => {
    state.statusDuration = value
  },
  set_statusType: (state, value) => {
    state.statusType = value
  },
  set_snackbar: (state, value) => {
    state.snackbar = value
  },
  set_editingSemester: (state, value) => {
    state.editingSemester = value
  },
  set_isMobileView: (state, value) => {
    state.isMobileView = value
  },
  set_selectedExam: (state, value) => {
    state.selectedExam = value
  },
  set_selectedSection: (state, value) => {
    state.selectedSection = value.selectedSection
    console.log('state.selectedSection', value);
  },
  set_attendanceInfoByQr: (state, value) => {
    state.attendanceInfoByQr = value
  },
  set_isCorporateInstitute: (state, value) => {
    state.isCorporateInstitute = value
  },
  set_brandingName: (state, value) => {
    state.brandingName = value
  },
  reset(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = { // actions for state
  set_loading: ({ commit, state }, value) => {
    commit('set_loading', value)
    return state.message
  },
  set_courseSections: ({ commit, state }, value) => {
    commit('set_courseSections', value)
    return state.message
  },
  set_selectedActivity: ({ commit, state }, value) => {
    commit('set_selectedActivity', value)
    return state.message
  },
  set_isStudyMaterialDownloadable: ({ commit, state }, value) => {
    commit('set_isStudyMaterialDownloadable', value)
    return state.message
  },
  set_selectedCourse: ({ commit, state }, value) => {
    commit('set_selectedCourse', value)
    return state.message
  },
  set_selectedSection: ({ commit, state }, value) => {
    commit('set_selectedSection', value)
    return state.message
  },
  set_attendanceInfoByQr: ({ commit, state }, value) => {
    commit('set_attendanceInfoByQr', value)
    return state.message
  },
  set_isCorporateInstitute: ({ commit, state }, value) => {
    commit('set_isCorporateInstitute', value)
    return state.message
  },
  set_brandingName: ({ commit, state }, value) => {
    commit('set_brandingName', value)
    return state.message
  }
}

export default { // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState() {
  return {
    loading: false,
    selectedActivity: 2,
    selectedActivityName: '',
    statusMessage: 'Success!',
    statusDuration: 2000,
    statusType: 'success',
    snackbar: false,
    editingSemester: {},
    selectedCourse: {},
    selectedSection: {},
    courseSections: {},
    isMobileView: false,
    isStudyMaterialDownloadable: false,
    selectedExam: {},
    attendanceInfoByQr: {},
    isCorporateInstitute: false,
    brandingName: ''
  }
}
