<template src='./activitySetup.html'>
</template>
<script>
import auth from '../../Services/Firebase/auth'
import UserRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
import firebase from 'firebase'
// import 'firebase/messaging'
export default {
  name: 'activitySetup',
  data () {
    return {
      user: null
    }
  },

  created () {
    this.user = this.$store.getters['user/get_user']
    this.userRepositoryInstance = new UserRepository(this)
    const authToken = this.$store.getters['user/get_authToken']
    console.log(this.user)
    var userToken = ''
    try {
      if (firebase.messaging.isSupported()) {
        firebase
          .messaging()
          .requestPermission()
          .then(() => {
            console.log('Notification permission granted')
            return firebase
              .messaging()
              .getToken()
              .then((token) => {
                window.console.log(token)
                userToken = token
              })
              .catch((err) => {
                console.log('unable to get token', err)
                userToken = ''
              })
          }
          ).catch((err) => {
            console.log('unable to get token', err)
            userToken = ''
          })
          .then(() => {
            if (this.user) {
              const objToPush = {
                registrationToken: userToken,
                authToken: authToken,
                uuId: this.user.uid,
                email: this.user.email,
                phone: this.user.phoneNumber ? this.user.phoneNumber.substring(3) : null
              }
              console.log(objToPush)
              this.userRepositoryInstance.login(objToPush).then(res => {
                showStatus('Login Successful!', 1000, 'success', this)
                console.log(res)
                this.$router.push('/activitySelectInstituteNSemester')
              }).catch(err => {
                console.log(err)
                auth.logout(this)
              })
            } else {
              this.$router.push('/activityWelcome')
            }
          })
      } else {
        if (this.user) {
          const objToPush = {
            registrationToken: userToken,
            authToken: authToken,
            uuId: this.user.uid,
            email: this.user.email,
            phone: this.user.phoneNumber ? this.user.phoneNumber.substring(3) : null
          }
          console.log(objToPush)
          this.userRepositoryInstance.login(objToPush).then(res => {
            showStatus('Login Successful!', 1000, 'success', this)
            console.log(res)
            this.$router.push('/activitySelectInstituteNSemester')
          }).catch(err => {
            console.log(err)
            auth.logout(this)
          })
        } else {
          this.$router.push('/activityWelcome')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  // created () {
  //   this.user = this.$store.getters['user/get_user']
  //   this.userRepositoryInstance = new UserRepository(this)
  //   const authToken = this.$store.getters['user/get_authToken']
  //   console.log(this.user)
  //   if (this.user) {
  //     const objToPush = {
  //       registrationToken: '',
  //       authToken: authToken,
  //       uuId: this.user.uid,
  //       email: this.user.email
  //     }
  //     this.userRepositoryInstance.login(objToPush).then(res => {
  //       showStatus('Login Successful!', 1000, 'success', this)
  //       console.log(res)
  //       this.$router.push('/activitySelectInstituteNSemester')
  //     }).catch(err => {
  //       console.log(err)
  //       auth.logout(this)
  //     })
  //   } else {
  //     this.$router.push('/activityWelcome')
  //   }
  // }
}
</script>
<style scoped src='./activitySetup.css'>
</style>
